import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import Auth from "@aws-amplify/auth";
import Axios from "axios";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  const { setAuth } = useAuth();
  const { setUser } = useUser();

  const navigate = useNavigate();

  const userLoginBody = {
    email: email,
    time: Date.now(),
  };

  const userLogin = async () => {
    setErrorMessage(null);
    if (email.length === 0 || password.length === 0) {
      setErrorMessage("Please fill both fields");
    } else {
      try {
        setProcessing(true);
        await Auth.signIn(email, password);
        Axios.put("https://api.whaletracer.com/user/login", userLoginBody);
        let cognitoUser = await Auth.currentAuthenticatedUser();
        const userEmail = cognitoUser.attributes.email;
        localStorage.setItem("email", userEmail);
        Axios.get(`https://api.whaletracer.com/user/getUser/${userEmail}`).then(
          (res) => {
            setUser(res.data[0]);
            localStorage.setItem("user_id", res.data[0].user_id);
            localStorage.setItem("stripe_id", res.data[0].stripe_id);
            localStorage.setItem("sub_id", res.data[0].subscription_id);
            localStorage.setItem("membership", res.data[0].membership);
          }
        );

        setAuth(true);
        setProcessing(false);
        navigate("/");
      } catch (error) {
        setErrorMessage(
          error.message === "Incorrect username or password."
            ? "Incorrect Email or Password."
            : error.message
        );
        setProcessing(false);
      }
    }
  };

  return (
    <Fade bottom>
      <div className="min-h-[90vh] font-sans">
        <div className="mb-12 mt-12 2xl:py-20 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
            <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-white">
              Sign in to your account
            </h2>
            <p className="mt-2 text-white">
              Don't have an account?{" "}
              <Link
                className="text-[#6DCDF5] font-bold cursor-pointer"
                to="/register"
              >
                Register
              </Link>
            </p>
          </div>

          <div className="mt-8 sm:mx-auto w-92 mx-auto sm:w-full sm:max-w-md">
            <div className="bg-[#3C3A58] py-8 px-4 rounded-lg shadow sm:rounded-lg sm:px-10">
              {errorMessage && (
                <p className="text-sm text-red-400 text-center mb-2">
                  {errorMessage}
                </p>
              )}
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-[#6DCDF5]"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-[#6DCDF5]"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                    />
                  </div>
                </div>

                <div>
                  <button
                    onClick={userLogin}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-[#6DCDF5]"
                  >
                    {processing ? "Logging in..." : "Login"}
                  </button>
                </div>
              </div>
              <Link to="/forgot-password">
                <p className="mt-4 text-[#6DCDF5] font-bold text-center cursor-pointer">
                  Forgot password?
                </p>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fade>
  );
}
