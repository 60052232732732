import React from "react";
import { Link } from "react-router-dom";
import {
  timeToGo,
  exchangeLink,
  referelLink,
} from "../../services/GeneralFunctions";

import LaunchIcon from "@mui/icons-material/Launch";
import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";
import millify from "millify";

const launchButton = {
  color: "#8edafa",
  fontWeight: "900",
  height: "5px",
};

const twitterHref = (
  baseAsset,
  quoteAsset,
  tradeValue,
  qty,
  exchange,
  side
) => {
  return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (CEX) %0A %0A${qty} $${baseAsset} has been ${
    side ? "sold" : "purchased"
  } for $${tradeValue} using $${quoteAsset} on ${exchange}. %0A %0ACheck Out Whale Trades At: WhaleTracer.com %0A %0A %23${baseAsset} %23${quoteAsset}  %23${exchange.toUpperCase()} %23WHALETRACER`;
};

const BiggestTradeCard = ({ data, heading, img, sold }) => {
  const detailLink = `/coin-details/${data.base_asset_trade}`;

  return (
    <div className="bg-[#292644] p-4 rounded-md border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-103 w-92 md:mx-0 mx-auto">
      <div className="">
        <div className="flex flex-row items-center">
          <img
            src={img}
            alt="trending-fire"
            className="w-8 md:w-12 object-contain "
          />
          <h3 className="text-lg font-extrabold ml-2"> {heading}</h3>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between leading-4">
          <div className="">
            <Link to={detailLink} className="w-6 md:w-8 object-contain">
              <div className="flex items-center">
                <img
                  src={data.base_asset_icon}
                  className="w-6 md:w-8 object-contain hover:scale-125 hover:ease-out hover:duration-300 "
                  alt="alternate"
                  onError={data.base_asset_icon}
                />

                <p className="ml-2  text-white hover:text-[#41BE85] font-black hover:scale-125 hover:ease-out hover:duration-300 hover:underline">
                  {data.base_asset_trade}
                </p>
              </div>
            </Link>
            <div className="flex flex-row items-center mb-2 mt-2">
              {" "}
              Exchange:{" "}
              <a
                href={exchangeLink(data.exchange_name)}
                target="_blank"
                className="no-underline text-[#8edafa] font-bold"
                rel="noreferrer"
              >
                <img
                  src={data.exchange_icon_url}
                  alt="exchange-logo"
                  className="w-4 ml-1 object-contain hover:scale-125 hover:ease-out hover:duration-300 "
                />
              </a>
            </div>
            <a
              href={referelLink(
                data.exchange_name,
                data.base_asset_trade,
                data.quote_asset_trade
              )}
              className="no-underline text-white"
              target="_blank"
              rel="noreferrer"
            >
              <div className="mb-2 flex items-center">
                <p>Pair:</p>
                <div className="hover:scale-110 hover:ease-out hover:duration-300">
                  <Button
                    variant="text"
                    endIcon={<LaunchIcon />}
                    style={launchButton}
                  >
                    {data.base_asset_trade}-{data.quote_asset_trade}
                  </Button>{" "}
                </div>
              </div>
            </a>
            <div className="mb-2">
              {sold ? "Sold" : "Bought"}:{" "}
              <span style={{ fontWeight: "500" }}>{data.qty}</span>{" "}
            </div>
            <div className="mb-2">
              Price:{" "}
              <span style={{ fontWeight: "500" }}>
                {data.price_in_quote_asset} {data.quote_asset_trade}
              </span>
            </div>
            <div className="mb-2">
              Trade Value:{" "}
              {!sold && (
                <span className="text-[#41BE85] font-extrabold">
                  ${millify(Math.round(data.trade_value * 1000) / 1000)}
                </span>
              )}
              {sold && (
                <span className="text-[#ED5872] font-extrabold">
                  ${millify(Math.round(data.trade_value * 1000) / 1000)}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-slate-200 text-center mx-auto ">
              {timeToGo(data.trade_time)}
            </p>
            <a
              href={twitterHref(
                data.base_asset_trade,
                data.quote_asset_trade,
                Math.round(data.trade_value * 1000) / 1000,
                data.qty,
                data.exchange_name,
                data.is_buyer_maker
              )}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                startIcon={<TwitterIcon />}
                style={{ marginTop: "2px" }}
              >
                Share
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiggestTradeCard;
