import React from "react";
import Background from "../../assets/background.png";
import "./Nft.css";

const Nft = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        position: "fixed",
      }}
    >
      <div className="content-container">
        <h1 className="subtext-nft">
          <h1 className="heading-nft"> STAY TUNED! </h1>
          WE ARE <br /> BUILDING SOMETHING <br />
          REALLY COOL!
        </h1>
      </div>
    </div>
  );
};

export default Nft;
