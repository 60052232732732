import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AboutImg from "../../assets/about-image.png";
import Binance from "../../assets/binance-white.png";
import Coinbase from "../../assets/coinbase-white.png";
import Kucoin from "../../assets/kucoin-white.png";
import Etherscan from "../../assets/etherscan-white.png";
import FTX from "../../assets/ftx-white.png";
import Polygon from "../../assets/polygon-white.png";
import Footer from "../../components/Footer/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="mt-8 mb-8 text-white max-w-wt 2xl:max-w-ft mx-auto font-sans">
        <div className="flex flex-col items-center justify-between md:flex-row mb-8">
          <div className="">
            <h1 className="text-2xl font-bold leading-8 text-white md:text-5xl md:font-extrabold text-center md:text-left">
              About WhaleTracer
            </h1>

            <p className="mb-8 mt-4 text-md text-slate-400 max-w-3xl mx-auto dark:text-slate-400  text-center md:text-left">
              {" "}
              WhaleTracer is a world’s first most advanced crypto assets trades
              & transactions tracking analytics platform in the cryptocurrency
              space. Our vision is to equip users with the knowledge of
              cryptocurrency unusual activity via trade analytics and
              information collected from popular exchanges. Founded by Mihail
              Kulechov in 2021, WhaleTracer aims to become the most trusted
              source of crypto movement analytics and records for our users,
              institutions and media presenting the whale activity in CEX, DEX,
              NFT, DeFi & Domains.
            </p>
          </div>
          <img
            src={AboutImg}
            alt="btc"
            className="w-72 object-contain mx-auto"
          />
        </div>
        <div className="grid gap-4 grid-cols-2 mx-auto md:grid-cols-6 mb-12">
          <img
            src={Binance}
            alt="btc"
            className="w-40 object-contain  md:h-40 md:w-40"
          />
          <img
            src={Etherscan}
            alt="btc"
            className="w-40 object-contain  md:h-40 md:w-40"
          />
          <img
            src={FTX}
            alt="btc"
            className="w-40 object-contain  md:h-40 md:w-40"
          />
          <img
            src={Coinbase}
            alt="btc"
            className="w-40 object-contain  md:h-40 md:w-40"
          />
          <img
            src={Polygon}
            alt="btc"
            className="w-40  object-contain md:h-40 md:w-40"
          />
          <img
            src={Kucoin}
            alt="btc"
            className="w-40 object-contain  md:h-40 md:w-40"
          />
        </div>
        <div className="">
          <h1 className="text-2xl font-bold leading-8 text-white md:text-3xl md:font-extrabold text-center md:text-left">
            BUILDING THE FUTURE OF ANALYTICS
          </h1>
          <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 text-center md:text-left">
            We at WhaleTracer are working on making the data presentation and
            consumption for our consumers easy and interactive. We want our
            users to have a single source of truth and information where the
            whale movement in the crypto space can be observed and analysed
            easily and they have better understanding are able to make smarter
            decisions. Our team works consists of talented, hardworking and
            passionate people who believe in the future of blockchain and
            freedom of information. We are always interested to meet new people
            and get in touch with like minded crypto and blockchain enthusiast.
            If you think you would love to work along side such people get in
            touch with us at career@whaletracer.com.
          </p>
          <div className="text-center md:text-left">
            <Link to="/contact">
              <button
                type="button"
                className="inline-flex text-sm items-center px-5 py-2 border border-transparent  font-medium rounded-full shadow-sm text-black bg-[#B0EEFD]"
              >
                Talk to us
              </button>
            </Link>

            <a
              href="https://discord.gg/YbGnBgyBQ3"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#8edafa",
                fontWeight: "500",
                cursor: "pointer",
              }}
              rel="noreferrer"
            >
              <button
                type="button"
                className="inline-flex text-sm items-center ml-4 px-5 py-2 border border-[#B0EEFD]  font-medium rounded-full shadow-sm text-white"
              >
                Join our Discord
              </button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
