import { useState, useEffect } from "react";
import Axios from "axios";
import { timeToGoSec } from "../../services/GeneralFunctions";

const chainIcon = (val) => {
  if (val === "uniswap_v3" || val === "uniswap") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/UniswapIcon.png";
  }
  if (val === "sushiswap") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/SushiSwap-Icon.png";
  }
  if (val === "ddex") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/DDEX-Icon.png";
  } else {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/WT-DEX-Icon.png";
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const categoryStyle = (type) => {
  if (type === "Whale") {
    return { color: "#41BE85", fontWeight: "900" };
  }
  if (type === "medium") {
    return { color: "#B0EEFD", fontWeight: "900" };
  }
  if (type === "noob") {
    return { color: "white", fontWeight: "900" };
  }
  if (type === "casual") {
    return { color: "#627EEA", fontWeight: "900" };
  }
  if (type === "bot") {
    return { color: "#EB4068", fontWeight: "900" };
  }
  if (type === null) {
    return { color: "white", fontWeight: "900" };
  }
};

export default function DexTrending() {
  const [topDEXTrades, setTopDEXTrades] = useState([]);

  useEffect(() => {
    Axios.get("https://api.whaletracer.com/cex-trades/top-ten-dex").then(
      (response) => {
        setTopDEXTrades(response.data);
      }
    );
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-[#292644] p-4 border-8 border-[#403e66] rounded-lg">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-white text-center">
            CRYPTO TRENDING ON DEX
          </h1>
          <p className="mt-2 text-sm text-gray-300 text-center">
            A list of all the DEX coins that are trending right now.
          </p>
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 hidden md:block">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className=" text-white">
                    <tr className="bg-[#292644] text-white">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold  sm:pl-6"
                      >
                        DEX
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Bought
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Sold
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        TXN Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Wallet Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-0.3 divide-[#B0EEFD]/60 bg-[#292644] p-12">
                    {topDEXTrades.slice(0, 10).map((trade) => (
                      <tr key={trade.symbol}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={chainIcon(trade.chain_name)}
                                alt=""
                              />
                            </div>
                            <div className="ml-2">
                              <div className="text-[#B0EEFD] font-bold text-lg">
                                {trade.chain_name}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#41BE85] font-extrabold">
                          ${numberWithCommas(trade.token_out)}{" "}
                          {trade.symbol_out}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          ${numberWithCommas(trade.token_in)} {trade.symbol_in}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          ${numberWithCommas(Math.round(trade.txn_value))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          <span style={categoryStyle(trade.category)}>
                            {trade.category.toUpperCase()}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          <p className=""> {timeToGoSec(trade.trade_time)}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className=" text-white">
                    <tr className="bg-[#292644] text-white">
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold  sm:pl-6"
                      >
                        DEX
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Bought
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Sold
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Wallet Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-0.3 divide-[#B0EEFD]/60 bg-[#292644] p-12">
                    {topDEXTrades.slice(0, 10).map((trade) => (
                      <tr key={trade.symbol}>
                        <td className="py-4 text-sm">
                          <div className="flex items-center">
                            <div className="h-8 w-8 flex-shrink-0">
                              <img
                                className="h-8 w-8 rounded-full"
                                src={chainIcon(trade.chain_name)}
                                alt=""
                              />
                            </div>
                            <div className="ml-2">
                              <div className="text-[#B0EEFD] font-bold text-lg">
                                {trade.chain_name}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#41BE85] font-extrabold">
                          {trade.token_out} {trade.symbol_out}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          {trade.token_in} {trade.symbol_in}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          <span style={categoryStyle(trade.category)}>
                            {trade.category.toUpperCase()}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
