import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

export default function PageNotFound() {
  return (
    <>
      <div className="min-h-full pt-0 sm:pt-8 pb-12 flex flex-col font-sans">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                className="object-contain w-36 md:w-36"
                src="https://i.stack.imgur.com/7Ga9h.png"
                alt="Workflow"
              />
            </a>
          </div>
          <div className="py-8">
            <div className="text-center">
              <p className="font-bold text-5xl text-[#8EDAFA]">404</p>
              <h1 className="mt-2 text-4xl font-bold text-white tracking-tight sm:text-5xl sm:tracking-tight">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-300">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-[#8EDAFA] hover:text-indigo-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
