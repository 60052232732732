import LogoCloud from "../../../components/LogoCloud/LogoCloud";

export function Hero() {
  return (
    <div className="max-w-wt 2xl:max-w-ft mx-auto">
      <h1 className="mx-auto max-w-4xl font-display text-5xl text-center font-black tracking-tight text-white sm:text-6xl mt-20">
        CRYPTO{" "}
        <span className="relative whitespace-nowrap text-[#52B3D0]">
          <span className="relative">WHALE TRADES</span>
        </span>{" "}
        TRACKING MADE EASY
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg tracking-tight text-slate-300">
        Uncover the Whale Activity like Fisherman's NAVIGATOR does. See what
        VC's aka Whales are planning next and find the next Solana or Avalanche
        that's bought under a 1$ and sold over 100$.
      </p>

      <div className="mt-2 lg:mt-2">
        <LogoCloud />
      </div>
    </div>
  );
}
