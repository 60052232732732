import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { timeToGoSec } from "../../services/GeneralFunctions";
import millify from "millify";
import axios from "axios";
import Logo from "../../assets/smallLogo.png";
import DexTableView from "../../components/DexTradesTable/DexTradesView";
import Footer from "../../components/Footer/Footer";

const twitterHref = (txnValue, tokenOut, tokenIn, chain, outSym, inSym) => {
  return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (DEX) 
      %0ATxn Value $${millify(txnValue)} swaped on ${chain}
      %0AToken Out: $${tokenOut} $${outSym}
      %0AToken In: $${tokenIn} $${inSym}
      %0ACheck Out More: whaletracer.com
      %0A %23${outSym} %23${inSym}  %23${chain.toUpperCase()} %23WHALETRACER`;
};

const WalletDetails = () => {
  const [trades, setTrades] = useState([]);
  const [totalTrades, setTotalTrades] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const params = useParams();
  var wallet = JSON.stringify(params.id);
  wallet = wallet.replace(/"/g, "").toLowerCase();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios
          .get(
            `https://api.dev.dex.guru/v1/chain/1/wallets/${wallet}/transactions?api-key=DBO9wMFUWS6DBCq-hkRpOIVOs9wsZi2Gnotd-xmnjYk`
          )
          .then((res) => {
            setTotalTrades(res.data.total);
            setTrades(res.data.data);
          });
      } catch (err) {
        console.log("Error fetching data", err); // handle errors here if needed
      }
    }

    fetchData();
    setDataLoading(false);
  }, []);

  if (dataLoading || trades.length === 0) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-sm">
          Loading trades data...
        </p>
      </div>
    );
  }
  return (
    <>
      <div className="text-white max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] font-sans">
        <div className="mt-8">
          <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white">
            WALLET DETAILS
          </h1>
          <p className="mb-4 mt-2 text-sm text-slate-300 text-center md:text-left">
            {" "}
            All trades from the wallet:{" "}
            <span className="text-[#B0EEFD] font-bold">{wallet}</span>
          </p>
        </div>
        <div className="border-t border-[#B0EEFD] mt-2"></div>
        <div className="flex flex-col text-white mt-4">
          {totalTrades === 0 ? (
            <p> No trades for this wallet</p>
          ) : (
            trades.map((trade) => (
              <DexTableView
                chain={trade.amm}
                time={trade.timestamp}
                token_out={
                  Math.round(trade.tokens_out[0].amount_out * 100) / 100
                }
                token_in={Math.round(trade.tokens_in[0].amount_in * 100) / 100}
                trade_id={trade.to}
                symbol_out={trade.tokens_out[0].symbol}
                symbol_in={trade.tokens_in[0].symbol}
                value={Math.round(trade.amount_usd * 100) / 100}
                hash={trade.transaction_address}
                wallet={trade.wallet_address}
                category={trade.wallet_category}
                timeToGo={timeToGoSec}
                href={twitterHref}
                detailedView={true}
                view="small"
              />
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WalletDetails;
