import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Fade from "react-reveal/Fade";
import clsx from "clsx";

import Auth from "@aws-amplify/auth";
import Axios from "axios";
import { CheckIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@+#$%]).{8,24}$/;

const UPPERCASE_CHECK = /(?=.*[A-Z])/;
const LOWERCASE_CHECK = /(?=.*[a-z])/;
const NUMBER_CHECK = /\d/;

const SPECIAL_CHECK = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

const activeButton =
  "mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]";
const inActiveButton =
  "mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black/70 bg-[#B0EEFD] pointer-events-none opacity-70";

const featuresone = [
  "Longer time frame",
  "Filter by Exchanges",
  "Filter by Trade Size",
  "Wallets by Trading Activity",
];
const featurestwo = [
  "Exclude BTC/ETH",
  "Show Wallets",
  "Filter by trade types",
  "Alerts on Email",
];

export default function Register() {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasCapital, setHasCapital] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [hasLower, setHasLower] = useState(false);
  const [checkLength, setCheckLength] = useState(false);

  const [registration, setRegistration] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);
  useEffect(() => {
    let count = 0;
    for (var i = 0, len = name.length; i < len; i++) {
      if (name[i] !== " ") count++;
    }
    setValidName(count > 2);
  }, [name]);
  useEffect(() => {
    const result = PWD_REGEX.test(password);
    const checkNumbers = NUMBER_CHECK.test(password);
    const checkCapital = UPPERCASE_CHECK.test(password);
    const checkLower = LOWERCASE_CHECK.test(password);
    const checkSpecial = SPECIAL_CHECK.test(password);
    const checkLength = password.length > 8 && password.length < 23;
    setCheckLength(checkLength);
    setHasNumber(checkNumbers);
    setHasCapital(checkCapital);
    setHasSpecial(checkSpecial);
    setHasLower(checkLower);
    setValidPwd(result);
  }, [password]);

  const userBody = {
    fullname: name.trim(),
    email: email,
    password: password,
    last_active: Date.now(),
    membership: "free",
  };

  const subscribeBody = {
    email: email,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subscribeUser = async () => {
    console.log("Function triggered");
    await Axios.post(
      "https://api.whaletracer.com/email/subscribe",
      subscribeBody
    );
  };

  const userSignup = async () => {
    setErrorMessage(null);
    try {
      setProcessing(true);
      await Auth.signUp({
        username: email,
        password: password,
        attribute: {
          email: email,
        },
      });
      Axios.post(`https://api.whaletracer.com/user/addUser`, userBody).then(
        (res) => {
          subscribeUser();
        }
      );
      setRegistration(false);
      setProcessing(false);
    } catch (error) {
      setErrorMessage(error.message);
      setProcessing(false);
    }
  };

  return (
    <Fade bottom>
      <div className="min-h-[90vh] font-sans">
        <div className="mb-12 mt-12 2xl:py-20 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
            <h2 className="mt-6 text-center text-2xl tracking-tight font-bold text-white">
              CREATE FREE ACCOUNT
            </h2>
            <p className="mt-2 text-white">
              Already registered?{" "}
              <Link
                className="text-[#6DCDF5] font-bold cursor-pointer"
                to="/login"
              >
                Log in
              </Link>{" "}
              to your account.
            </p>
          </div>
          {registration ? (
            <div className="mt-8 sm:mx-auto w-92 mx-auto  sm:w-full sm:max-w-md">
              <div className="bg-[#3C3A58] py-8 px-4 shadow rounded-lg sm:rounded-lg sm:px-10">
                {errorMessage && (
                  <p className="text-sm text-red-400 text-center mb-2">
                    {errorMessage}
                  </p>
                )}

                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="name"
                        autoComplete="name"
                        required
                        onChange={(e) => setName(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validName && name !== "" && (
                      <p className="mt-2 text-sm text-red-400">
                        Name must be at least three characters
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validEmail && email !== "" && (
                      <p className="mt-2 text-sm text-red-400">
                        Add a valid Email
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Password
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                      <div className="flex items-center mt-2  justify-end">
                        <input
                          id="remember-me"
                          name="remember-me"
                          checked={showPassword}
                          type="checkbox"
                          onClick={() => setShowPassword(!showPassword)}
                          className="h-4 w-4 text-[#B0EEFD] focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-xs text-white"
                        >
                          Show Password
                        </label>
                      </div>
                    </div>
                    <div className="leading-4 mt-2">
                      {!hasCapital && password !== "" && (
                        <div className="flex items-center ">
                          <XIcon className="w-4 text-red-400" />
                          <p className="text-sm text-red-400 ml-2">
                            Uppercase Letter
                          </p>
                        </div>
                      )}
                      {hasCapital && password !== "" && (
                        <div className="flex items-center ">
                          <CheckIcon className="w-4 text-green-400" />
                          <p className="text-sm text-green-400 ml-2">
                            Uppercase Letter
                          </p>
                        </div>
                      )}
                      {!hasLower && password !== "" && (
                        <div className="flex items-center ">
                          <XIcon className="w-4 text-red-400" />
                          <p className="text-sm text-red-400 ml-2">
                            Lowercase Letter
                          </p>
                        </div>
                      )}
                      {hasLower && password !== "" && (
                        <div className="flex items-center ">
                          <CheckIcon className="w-4 text-green-400" />
                          <p className="text-sm text-green-400 ml-2">
                            Lowercase Letter
                          </p>
                        </div>
                      )}
                      {!hasNumber && password !== "" && (
                        <div className="flex items-center ">
                          <XIcon className="w-4 text-red-400" />
                          <p className="text-sm text-red-400 ml-2">Number</p>
                        </div>
                      )}
                      {hasNumber && password !== "" && (
                        <div className="flex items-center ">
                          <CheckIcon className="w-4 text-green-400" />
                          <p className="text-sm text-green-400 ml-2">Number</p>
                        </div>
                      )}

                      {!hasSpecial && password !== "" && (
                        <div className="flex items-center ">
                          <XIcon className="w-4 text-red-400" />
                          <p className="text-sm text-red-400 ml-2">
                            Special Character
                          </p>
                        </div>
                      )}
                      {hasSpecial && password !== "" && (
                        <div className="flex items-center ">
                          <CheckIcon className="w-4 text-green-400" />
                          <p className="text-sm text-green-400 ml-2">
                            Special Character
                          </p>
                        </div>
                      )}
                      {!checkLength && password !== "" && (
                        <div className="flex items-center ">
                          <XIcon className="w-4 text-red-400" />
                          <p className="text-sm text-red-400 ml-2">
                            Between 8 and 23 characters{" "}
                          </p>
                        </div>
                      )}
                      {checkLength && password !== "" && (
                        <div className="flex items-center ">
                          <CheckIcon className="w-4 text-green-400" />
                          <p className="text-sm text-green-400 ml-2">
                            Between 8 and 23 characters
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="text-xs text-slate-400 dark:text-slate-400 md:text-left">
                    {" "}
                    Password should be between 8 and 23 characters long with at
                    least one lowercase letter, one uppercase letter, a number
                    and a special character
                  </p>
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      checked={agreed}
                      type="checkbox"
                      onClick={() => setAgreed(!agreed)}
                      className="h-4 w-4 text-[#B0EEFD] focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-xs text-white"
                    >
                      Agree to WhaleTracer{" "}
                      <Link
                        to="/privacy-policy"
                        className="text-xs text-white font-bold md:text-left"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>{" "}
                      &
                      <Link
                        to="/terms-of-use"
                        className="text-xs text-white font-bold md:text-left"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Terms of Use{" "}
                      </Link>
                    </label>
                  </div>
                  <div>
                    <button
                      onClick={
                        validName && validEmail && validPwd && agreed
                          ? userSignup
                          : null
                      }
                      className={
                        validName && validEmail && validPwd && agreed
                          ? activeButton
                          : inActiveButton
                      }
                    >
                      {processing ? "Creating your account..." : "Register"}
                    </button>
                  </div>
                </div>
                <div className="border-b border-white my-4"></div>
                <h1 className="text-white text-md font-bold mt-4">
                  Become a Member to Unlock All Features
                </h1>
                <div className="flex justify-center">
                  <ul className={clsx("-my-2 text-sm mt-4 mb-2")}>
                    {featuresone.map((feature) => (
                      <li key={feature} className="flex py-1 items-center">
                        <CheckIcon className="h-5 w-5 flex-none bg-cyan-500 text-white" />
                        <span className="ml-2 text-white text-sm">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul className={clsx("-my-2 text-sm mt-4 mb-2")}>
                    {featurestwo.map((feature) => (
                      <li key={feature} className="flex py-1 items-center">
                        <CheckIcon className="h-5 w-5 flex-none bg-cyan-500 text-white" />
                        <span className="ml-2 text-white text-sm">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-8 w-92 mx-auto sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-[#3C3A58] py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <p
                      as="h3"
                      className="text-lg leading-6 font-medium text-white"
                    >
                      Verify your Email
                    </p>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        A verification link has been sent to your email. Please
                        verify your email using that link.
                      </p>
                      <span className="text-slate-300 text-xs text-center">
                        Please check your spam folder if it's not received in
                        Inbox.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link to="/login">
                    <button
                      type="button"
                      className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                    >
                      Go back to Login
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </div>
    </Fade>
  );
}
