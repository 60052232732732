import { Disclosure } from "@headlessui/react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

const faqs = [
  {
    id: 1,
    question: "HOW CRYPTO WHALE TRACKER WORKS?",
    answer:
      "Whale tracker registers all $50.000+ trades from most known exchanges like Coinbase, Binance, ByBit & OKX. So you can hunt all crypto whales on the centralised exchanges.",
  },
  {
    id: 2,
    question: "HOW THIS TRACKER HELPS YOU?",
    answer:
      "On certain market conditions you can track wether whales are buying or selling. This might help you to evaluate whales activity on centralised exchanges.",
  },
  {
    id: 3,
    question: "WHAT FILTERS SHOULD I USE?",
    answer:
      "You can exclude BTC/ETH and see what tokens are getting bought or sold. This will uncover the whale activity on anything else but Bitcoin and Ethereum. This feature might help to uncover potentially hidden gems or VC's activity.",
  },
  {
    id: 4,
    question: "WHAT HAPPENS IF I CLICK ON CERTAIN COIN/TOKEN?",
    answer:
      "It takes you on the detail page where you will see visual graphs from the past week of crypto whale activity. And if some project gets your attention you can 'Follow' or 'Unfollow' them as well.",
  },
  {
    id: 5,
    question:
      "CAN I SET ALERT IF SOMEONE IS BUYING OR SELLING ON CEX MY FAVOURITE PROJECTS?",
    answer:
      "Yes we are working on this feature and soon you will be able to track and get alert by email or telegram if someone is heavily selling or buying.",
  },
];

export default function FAQ() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl divide-y divide-white px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
          Frequently asked questions
        </h2>
        <dl className="mt-2 space-y-8 divide-y divide-white">
          <div className="w-full">
            <div className="">
              <div className="divide-y-2 divide-white">
                <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6 ">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                              <span className="font-medium text-white">
                                {faq.question}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusCircleIcon className="h-6 w-6 transform" />
                                ) : (
                                  <PlusCircleIcon className="h-6 w-6 transform" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base text-white">{faq.answer}</p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
}
