import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "22rem",
  bgcolor: "#2A375C",
  border: "2px solid #2A375C",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};

const FiltersPopup = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.categoryDisplay}
        onClose={props.toggleFilterDisplay}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className=""
      >
        <Fade in={props.categoryDisplay}>
          <Box sx={style}>
            <div>
              <div className="flex flex-row justify-between items-center">
                <p
                  className="text-white text-md font-extrabold cursor-pointer"
                  onClick={props.switchPopUp}
                >
                  Back
                </p>
              </div>
            </div>
            <div className="mt-8 ">
              <div className="mb-4">
                <p className="text-[#B0EEFD] font-extrabold capitalize">
                  WHALE
                </p>
                <p className="text-slate-200 text-sm">
                  {" "}
                  Whale has over $500,000+ trading volume in the last 30 days
                </p>
              </div>
              <div className="mb-4">
                <p className="text-[#B0EEFD] font-extrabold capitalize">
                  SHARK
                </p>
                <p className="text-slate-200 text-sm">
                  {" "}
                  Shark has $100,000-$500,000 of a trading volume in the last 30
                  days
                </p>
              </div>
              <div className="mb-4">
                <p className="text-[#B0EEFD] font-extrabold capitalize">
                  DOLPHIN
                </p>
                <p className="text-slate-200 text-sm">
                  {" "}
                  Dolphin is casual trader with $10,000-$100,000 of a trading
                  volume in the last 30 days
                </p>
              </div>
              <div className="mb-4">
                <p className="text-[#B0EEFD] font-extrabold capitalize">BOT</p>
                <p className="text-slate-200 text-sm">
                  {" "}
                  Trader with 1000+ TXs in last 30 days. Probably bot.
                </p>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FiltersPopup;
