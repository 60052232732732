import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "22rem",
  bgcolor: "#2A375C",
  border: "2px solid #2A375C",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

const buttonStyle = {
  backgroundColor: "#B0EEFD",
  borderRadius: "10px",
  color: "black",
  textTransform: "none",
  marginTop: "10px",
  "&:hover": {
    backgroundColor: "#B0EEFD",
  },
};

const FiltersPopup = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.filterDisplay}
        onClose={props.toggleFilterDisplay}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className=""
      >
        <Fade in={props.filterDisplay}>
          <Box sx={style}>
            <div>
              <div className="flex flex-row justify-between items-center">
                <p className="text-white text-md font-extrabold">FILTERS</p>
                <p className="close-button" onClick={props.toggleFilterDisplay}>
                  Close
                </p>
              </div>
            </div>
            <div className="trade-types">
              <div className="flex justify-between items-center">
                <p className="mb-4 text-[#B0EEFD]">Wallet Types</p>
                <IconButton
                  aria-label="info"
                  size="small"
                  onClick={props.switchPopUp}
                >
                  <InfoIcon fontSize="inherit" sx={{ color: "#B0EEFD" }} />
                </IconButton>
              </div>

              <div className="">
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">All</p>
                  <Checkbox
                    checked={props.category === null}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                    onClick={() => props.toggleCategory(null)}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Whale</p>
                  <Checkbox
                    checked={props.category === "Whale"}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                    onClick={() => props.toggleCategory("Whale")}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Shark</p>
                  <Checkbox
                    checked={props.category === "medium"}
                    onClick={() => props.toggleCategory("medium")}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Dolphin</p>
                  <Checkbox
                    checked={props.category === "casual"}
                    onClick={() => props.toggleCategory("casual")}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Shrimp</p>
                  <Checkbox
                    checked={props.category === "noob"}
                    onClick={() => props.toggleCategory("noob")}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Bot</p>
                  <Checkbox
                    checked={props.category === "bot"}
                    onClick={() => props.toggleCategory("bot")}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="trade-types">
              <div className="trade-types-heading-container">
                <p className="mb-4 text-[#B0EEFD]">TXN Value</p>
              </div>
              <div className="trade-types-container">
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">$50,000 and above</p>
                  <Checkbox
                    checked={props.txnValue === 1 ? true : false}
                    onClick={() => props.setTXNValue(1)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="text-sm text-slate-200">$100,000 and above</p>
                  <Checkbox
                    checked={props.txnValue === 2 ? true : false}
                    onClick={() => props.setTXNValue(2)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="text-sm text-slate-200">$300,000 and above</p>
                  <Checkbox
                    checked={props.txnValue === 6 ? true : false}
                    onClick={() => props.setTXNValue(6)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>

                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">
                    $500,000 and above
                  </p>
                  <Checkbox
                    checked={props.txnValue === 10 ? true : false}
                    onClick={() => props.setTXNValue(10)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">
                    $1 Million and above
                  </p>
                  <Checkbox
                    checked={props.txnValue === 20 ? true : false}
                    onClick={() => props.setTXNValue(20)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="apply-button">
              <Button
                variant="contained"
                sx={buttonStyle}
                onClick={props.toggleApply}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FiltersPopup;
