import React from "react";
import { Link } from "react-router-dom";

const MostTraded = ({ topTrades }) => {
  return (
    <div className="bg-[#292644] p-4 rounded-md border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-103 w-92  md:mx-0 mx-auto">
      <div className="mb-4">
        <div className="flex flex-row items-center">
          <img
            src="https://cdn-icons-png.flaticon.com/512/477/477420.png"
            alt="trending-fire"
            className="w-8 md:w-12 object-contain"
          />
          <h3 className="text-lg font-extrabold ml-2"> MOST TRADED IN 24H</h3>
        </div>
      </div>

      <table className="text-lg">
        <tr className="text-md">
          <th className="">Coin</th>
          <th className="">Trades %</th>
          <th className="">No. of Trades</th>
        </tr>
        {topTrades.slice(0, 3).map((trade, key) => (
          <tr className="text-center text-md ">
            <Link
              to={`/coin-details/${trade.base_asset_trade}`}
              className="no-underline text-white"
            >
              <td className="flex items-center">
                <img
                  src={trade.base_asset_icon}
                  alt="ceximage"
                  className="w-8 h-10 object-contain hover:scale-125 hover:ease-out hover:duration-300"
                />
                <p className="ml-2 font-extrabold text-sm hover:text-[#41BE85] hover:scale-125 hover:ease-out hover:duration-300 hover:underline cursor-pointer">
                  {trade.base_asset_trade}
                </p>
              </td>
            </Link>
            <td className="text-sm">
              <span className="text-[#41BE85] font-extrabold">
                {(Math.round((trade.bought / trade.total_trades) * 100) *
                  1000) /
                  1000}
                %
              </span>
              /
              <span className="text-[#EB4068] font-extrabold">
                {(Math.round((trade.sold / trade.total_trades) * 100) * 1000) /
                  1000}
                %
              </span>
            </td>
            <td className="font-extrabold text-sm">{trade.total_trades}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default MostTraded;
