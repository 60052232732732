import { createContext, useState } from "react";

const PricingContext = createContext({});

export const PricingProvider = ({ children }) => {
  const [price, setPrice] = useState({
    price: null,
    extraCharges: null,
    price_id: null,
  });

  return (
    <PricingContext.Provider value={{ price, setPrice }}>
      {children}
    </PricingContext.Provider>
  );
};

export default PricingContext;
