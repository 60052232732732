import React from "react";
import { Hero } from "./sections/Hero";
import { PrimaryFeatures } from "./sections/PrimaryFeatures";
import { SecondaryFeatures } from "./sections/SecondaryFeatures";
import { CallToAction } from "./sections/CTA";
import { Faqs } from "../Pricing/Faq";
import Footer from "../../components/Footer/Footer";
import Fade from "react-reveal/Fade";

const HowItWorks = () => {
  return (
    <Fade bottom>
      <div className="font-sans">
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <Faqs />
        <Footer />
      </div>
    </Fade>
  );
};

export default HowItWorks;
