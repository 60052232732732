/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/outline";
import useUpgrade from "../../hooks/useUpgrade";
import clsx from "clsx";

export default function UpgradePopup() {
  const { open, setOpen } = useUpgrade();

  function CheckIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="cyan"
        class="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
          clip-rule="evenodd"
        />
      </svg>
    );
  }

  const closePopup = () => {
    setOpen(false);
  };

  const featuresone = [
    "Longer time frame",
    "Filter by Exchanges",
    "Filter by Trade Size",
    "Wallets by Trading Activity",
  ];
  const featurestwo = [
    "Exclude BTC/ETH",
    "Show Wallets",
    "Filter by trade types",
    "Alerts on Email",
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closePopup}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 top-60 sm:inset-0 right-0 left-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full  text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-[#403E66] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-[#8EDAFA]">
                    <LockClosedIcon
                      className="h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-white"
                    >
                      THIS FEATURE IS LOCKED
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        Become a member for{" "}
                        <span className="font-black text-white">FREE</span> to
                        unlock all features
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <ul className={clsx("-my-2 text-sm mt-4 mb-2 mr-2")}>
                    {featuresone.map((feature) => (
                      <li key={feature} className="flex py-1 items-center">
                        <CheckIcon className="h-6 w-6 flex-none bg-white" />
                        <span className="ml-2 text-white text-xs">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul className={clsx("-my-2 text-sm mt-4 mb-2")}>
                    {featurestwo.map((feature) => (
                      <li key={feature} className="flex py-1 items-center">
                        <CheckIcon className="h-6 w-6 flex-none bg-white" />
                        <span className="ml-2 text-white text-xs">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-5 sm:mt-6 text-center">
                  <Link
                    to="/register"
                    className="text-base font-medium text-[#8EDAFA] "
                  >
                    <button
                      type="button"
                      className="inline-flex items-center px-6 py-2 text-md  shadow-sm font-medium rounded-md text-white bg-blue-600 hover:shadow-sm hover:text-white"
                      onClick={() => setOpen(false)}
                    >
                      {" "}
                      Become a Member
                    </button>
                  </Link>
                </div>
                <p className="text-white text-center text-xs mt-2">
                  {" "}
                  Registration & Membership is{" "}
                  <span className="font-black">100% FREE</span>
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
