const features = [
  {
    name: "Real-Time Trades Data",
    description:
      "Get real-time whale trades data from most popular CEX where trade value is above $50,000 & DEX where Tnx value is above $100,000.",
    icon: "https://i.stack.imgur.com/kJ7Qb.png",
  },
  {
    name: "Daily Email Alerts",
    description:
      "We share daily stats with you via email alerts so you can have daily update on the go, where ever you are you are always updated with the latest.",
    icon: "https://i.stack.imgur.com/oxqsw.png",
  },
  {
    name: "Advance Filter Trades Data",
    description:
      "We offer our users the ability to further refine and filter the whale trades data to their specific requirements and find the valuable information they need.",
    icon: "https://i.stack.imgur.com/p8epD.png",
  },
];

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for building a portfolio"
      className="py-20 sm:py-20 max-w-wt 2xl:max-w-ft mx-auto"
    >
      <div>
        <ul className="mx-auto mt-2 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <li
              key={feature.name}
              className="rounded-2xl border border-[#52B3D0] p-8"
            >
              <img
                src={feature.icon}
                className="object-contain w-12"
                alt="icons"
              />
              <h3 className="mt-6 text-lg font-bold text-white">
                {feature.name}
              </h3>
              <p className="mt-2 text-slate-300">{feature.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
