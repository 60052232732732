export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const timeToGo = (initTime) => {
  var d = new Date();
  var nowTs = Math.floor(d.getTime());
  var seconds = (nowTs - initTime) / 1000;
  if (seconds > 24 * 3600 * 7) {
    return "a week ago";
  }
  if (seconds > 24 * 3600 * 6) {
    return "6 days ago";
  }
  if (seconds > 24 * 3600 * 5) {
    return "5 days ago";
  }
  if (seconds > 24 * 3600 * 4) {
    return "4 days ago";
  }
  if (seconds > 24 * 3600 * 3) {
    return "3 days ago";
  }
  if (seconds > 24 * 3600 * 2) {
    return "2 days ago";
  }
  if (seconds > 24 * 3600) {
    return "Yesterday";
  }

  if (seconds > 7200) {
    return Math.floor(seconds / 3600) + " hours ago";
  }
  if (seconds > 3600) {
    return "1 hour ago";
  }
  if (seconds > 119) {
    return Math.floor(seconds / 60) + " mins ago";
  }
  if (seconds < 119) {
    return "just now";
  }
};

export const timeToGoSec = (initTime) => {
  var d = new Date();
  var nowTs = Math.floor(d.getTime()) / 1000;
  var seconds = nowTs - initTime;

  // a day
  if (seconds > 24 * 3600 * 7) {
    return "a week ago";
  }
  if (seconds > 24 * 3600 * 6) {
    return "six days ago";
  }
  if (seconds > 24 * 3600 * 5) {
    return "five days ago";
  }
  if (seconds > 24 * 3600 * 4) {
    return "four days ago";
  }
  if (seconds > 24 * 3600 * 3) {
    return "three days ago";
  }
  if (seconds > 24 * 3600 * 2) {
    return "two days ago";
  }
  if (seconds > 24 * 3600) {
    return "yesterday";
  }
  if (seconds > 7200) {
    return Math.floor(seconds / 3600) + " hours ago";
  }
  if (seconds > 3600) {
    return "1 hour ago";
  }
  if (seconds > 119) {
    return Math.floor(seconds / 60) + " mins ago";
  }
  if (seconds < 119) {
    return "just now";
  }
};

export const secondsTimeFilter = (time) => {
  var d = new Date();
  var nowTs = Math.floor(d.getTime());
  var timeLimit = 0;
  if (time === "24h") {
    timeLimit = nowTs - 86400 * 1000;
  }
  if (time === "12h") {
    timeLimit = nowTs - 43200 * 1000;
  }
  if (time === "6h") {
    timeLimit = nowTs - 21600 * 1000;
  }
  if (time === "1h") {
    timeLimit = nowTs - 3600 * 1000;
  }

  return timeLimit;
};

export const exchangeLink = (exchange) => {
  if (exchange === "Binance") {
    return "https://accounts.binance.com/en/register?ref=16771072";
  }
  if (exchange === "Kucoin") {
    return "https://www.kucoin.com/ucenter/signup?rcode=E5frNg";
  }
  if (exchange === "FTX") {
    return "https://ftx.com/profile#a=99138803";
  }
  if ((exchange = "Coinbase")) {
    return "https://www.coinbase.com/join/kulech_h";
  }
};

export const referelLink = (exchange, base, quote) => {
  if (exchange === "Binance") {
    return `http://www.binance.com/en/trade/${base}_${quote}?ref=16771072&type=spot/`;
  }
  if (exchange === "Kucoin") {
    return `https://www.kucoin.com/trade/${base}-${quote}?rcode=E5frNg`;
  }
  if (exchange === "FTX") {
    return `https://ftx.com/trade/${base}/${quote}/#a=99138803`;
  }
  if (exchange === "Coinbase") {
    return "https://www.coinbase.com/join/kulech_h";
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberToString = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
