import { createContext, useState } from "react";

const DisclaimerContext = createContext({});

export const DisclaimerProvider = ({ children }) => {
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
  return (
    <DisclaimerContext.Provider
      value={{ isShowDisclaimer, setIsShowDisclaimer }}
    >
      {children}
    </DisclaimerContext.Provider>
  );
};

export default DisclaimerContext;
