import React from "react";
import { Link } from "react-router-dom";

const FavWalletCard = (props) => {
  const route = `/wallet/${props.wallet}`;
  return (
    <div
      key={props.id}
      className="flex flex-col overflow-hidden rounded-lg shadow-lg"
    >
      <div className="bg-[#292644] p-4 rounded-md border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-103 w-92 mx-auto lg:mx-0">
        <div className="flex flex-row items-center justify-between">
          <p className="text-3xl font-semibold text-white">{props.title}</p>
          <time dateTime={props.time} className="text-xs text-slate-300">
            Added {props.time}
          </time>
        </div>
        <div className="mt-4">
          <p>
            Wallet Type:{" "}
            <span
              className={
                props.type === "Whale"
                  ? "font-bold text-[#41BE85]"
                  : "font-bold text-[#EB4068]"
              }
            >
              {props.type.charAt(0).toUpperCase() + props.type.slice(1)}
            </span>
          </p>
        </div>
        <div>
          <p>
            Wallet Address:{" "}
            <span className="font-bold">
              {props.wallet.slice(0, 5) +
                "..." +
                props.wallet.slice(
                  props.wallet.length - 5,
                  props.wallet.length
                )}
            </span>
          </p>
        </div>
        <Link to={route}>
          <button
            type="button"
            className="w-full mt-4 rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
          >
            Review Wallet Trades
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FavWalletCard;
