import { CircleBackground } from "../../components/CircleBackground/CircleBackground";
import { Container } from "./Container";
import { useContext } from "react";
import SubscriptionContext from "../../context/Subscription";

export function CallToAction() {
  const { setIsShowSubscription } = useContext(SubscriptionContext);

  return (
    <section
      id="get-free-shares-today"
      className="relative overflow-hidden bg-gray-900 py-20 sm:py-28"
    >
      <div className="absolute top-1/2 left-20 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" className="animate-spin-slow" />
      </div>
      <Container className="relative">
        <div className="mx-auto text-center max-w-md sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
            GET YOUR FIRST FREE ALERTS TODAY
          </h2>
          <p className="mt-4 text-lg text-gray-300">
            It takes just 1 click to sign up for our email alert subscription.
            Be the first to know about what Whales are planning next. To sign up
            for our email subscription. Be the first to know about what Crypto
            Whales are doing with their money.
          </p>
          <button
            onClick={() => setIsShowSubscription(true)}
            type="button"
            className="mt-8 inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#52B3D0]"
          >
            Subscribe
          </button>
          <br />
          <span className="text-xs text-slate-300 text-center mx-auto w-40">
            You will get once a day automated Whale Activity updates.
          </span>
        </div>
      </Container>
    </section>
  );
}

// .animate-spin-slower{-webkit-animation:spin 6s linear infinite;animation:spin 6s linear infinite}
