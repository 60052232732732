import React from "react";
import "./TradesCard.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";
import millify from "millify";
import LaunchIcon from "@mui/icons-material/Launch";
import { exchangeLink, referelLink } from "../../services/GeneralFunctions";
import { Event } from "../Tracking/Tracking";

import { Link } from "react-router-dom";

const launchButton = {
  color: "#8edafa",
  fontWeight: "900",
  height: "5px",
};

const twitterHref = (
  baseAsset,
  quoteAsset,
  tradeValue,
  qty,
  exchange,
  side
) => {
  return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (CEX) %0A %0A${qty} $${baseAsset} has been ${
    side ? "sold" : "purchased"
  } for $${tradeValue} using $${quoteAsset} on ${exchange}. %0A %0ACheck Out Whale Trades At: WhaleTracer.com %0A %0A %23${baseAsset} %23${quoteAsset}  %23${exchange.toUpperCase()} %23WHALETRACER`;
};

const TradesCard = (props) => {
  const coinDetail = `/coin-details/${props.baseAsset}`;
  const normalStyling =
    "bg-[#292644] p-4 rounded-md border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-103 w-92 mx-auto lg:mx-0";
  const whaleStyling =
    "bg-gradient-to-r from-[#b0eefd] to-[#13659b] p-4 rounded-md border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-103 w-92 mx-auto lg:mx-0";
  return (
    <div className={normalStyling}>
      <div className=" text-left">
        {props.cost > 200000 && (
          <span className="inline-flex items-center px-3 py-1 rounded text-xs font-medium bg-[#B0EEFD]  text-gray-800">
            {" "}
            Whale Trade
          </span>
        )}
        {props.cost > 100000 && props.cost < 200000 && (
          <span
            className={
              props.isBuyer
                ? "inline-flex items-center px-3 py-1 rounded text-xs font-medium bg-[#ED5872] text-white"
                : "inline-flex items-center px-3 py-1 rounded text-xs font-medium bg-[#41BE85] text-white"
            }
          >
            Big Trade
          </span>
        )}
      </div>

      <div className="mt-2">
        <div className="flex justify-between leading-4">
          <div className="">
            <Link to={coinDetail} className="no-underline text-white">
              <div
                className="flex items-center"
                onClick={() =>
                  Event(
                    "CEX Detail",
                    "CEX detail page was clicked",
                    "CEX detail page link"
                  )
                }
              >
                <img
                  src={props.image}
                  className="w-6 md:w-8 object-contain hover:scale-125 hover:ease-out hover:duration-300 "
                  alt="alternate"
                  onError={props.image}
                />

                <p className="ml-2 text-white hover:text-[#41BE85] font-black hover:scale-125 hover:ease-out hover:duration-300 hover:underline">
                  {props.baseAsset}
                </p>
              </div>
            </Link>
            <div className="flex flex-row items-center mb-2 mt-2">
              {" "}
              Exchange:{" "}
              <a
                href={exchangeLink(props.exchange)}
                target="_blank"
                className="no-underline text-[#8edafa] font-bold"
                rel="noreferrer"
                onClick={() =>
                  Event(
                    "Affiliate Exchange",
                    "Exchange link was clicked",
                    "Trade exchange affiliate link"
                  )
                }
              >
                <img
                  src={props.exchangeLogo}
                  alt="exchange-logo"
                  className="w-4 ml-1 object-contain hover:scale-125 hover:ease-out hover:duration-300 hover:underline"
                />
              </a>
            </div>
            <a
              href={referelLink(
                props.exchange,
                props.baseAsset,
                props.quoteAsset
              )}
              className="no-underline text-white"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                Event(
                  "Affiliate Pair",
                  "Pair link was clicked",
                  "Trade pair affiliate link"
                )
              }
            >
              <div className="mb-2 flex items-center">
                <p>Pair:</p>
                <div className="hover:scale-110 hover:ease-out hover:duration-300">
                  <Button
                    variant="text"
                    endIcon={<LaunchIcon />}
                    style={launchButton}
                  >
                    {props.baseAsset}-{props.quoteAsset}
                  </Button>{" "}
                </div>
              </div>
            </a>
            <div className="mb-2">
              {props.isBuyer ? "Sold" : "Bought"}:{" "}
              <span className="font-bold">{props.quantity}</span>{" "}
            </div>
            <div className="mb-2">
              Price: <span style={{ fontWeight: "500" }}>{props.price}</span>
            </div>
            <div className="mb-2">
              Trade Value:{" "}
              {!props.isBuyer && (
                <span className="text-[#41BE85] font-extrabold">
                  ${millify(Math.round(props.cost * 1000) / 1000)}
                </span>
              )}
              {props.isBuyer && (
                <span className="text-[#ED5872] font-extrabold">
                  ${millify(Math.round(props.cost * 1000) / 1000)}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <p className="text-sm text-slate-200 text-center mx-auto mb-2">
              {props.time}
            </p>
            <a
              href={twitterHref(
                props.baseAsset,
                props.quoteAsset,
                Math.round(props.cost * 1000) / 1000,
                props.quantity,
                props.exchange,
                props.isBuyer
              )}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" startIcon={<TwitterIcon />}>
                Share
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradesCard;
