import React, { useState, useEffect, useRef } from "react";
import TradesCard from "../../components/TradesCard/TradesCard";
import { TabTitle, timeToGo } from "../../services/GeneralFunctions";
import { Link } from "react-router-dom";
import Logo from "../../assets/smallLogo.png";
import Filters from "../../components/Filters/Filters";
import Fade from "react-reveal/Fade";
import TableView from "../../components/TableView/TableView";
import Footer from "../../components/Footer/Footer";
import FiltersPopup from "../../components/FiltersPopup/FiltersPopup";
import MostTraded from "../../components/MostTraded/MostTraded";
import BiggestTradeCard from "../../components/BiggestTradeCard/BiggestTradeCard";
import { ChevronDownIcon } from "@heroicons/react/solid";
import useAuth from "../../hooks/useAuth";
import FAQ from "./FAQ";

import { initGA, PageView } from "../../components/Tracking/Tracking";

import axios from "axios";

const Alerts = () => {
  TabTitle("WhaleTracer - CEX Trades");
  const [dataLoading, setDataLoading] = useState(true);
  const [filter, setFilter] = useState(6);
  const [filterOpen, setFilterOpen] = useState(false);
  const [dataDisplay, setDataDisplay] = useState(true);
  const [trades, setTrades] = useState([]);
  const [biggestSell, setBiggestSell] = useState([]);
  const [biggestBuy, setBiggestBuy] = useState([]);
  const [topTrades, setTopTrades] = useState([]);
  const [exchangeFilter, setExchangeFilter] = useState("");
  const [cardsQty, setCardsQty] = useState(30);

  const [sold, setSold] = useState(null);
  const [bought, setBought] = useState(false);

  const [excludeBTC, setExcludeBTC] = useState(null);
  const [excludeETH, setExcludeETH] = useState(null);

  const [tradeValue, setTradeValue] = useState(1);

  const [applyFilter, setApplyFilter] = useState(false);

  const { auth } = useAuth();

  let containerRef = useRef();

  const toggleSold = () => {
    setSold(!sold);
    setBought(false);
  };
  const toggleBought = () => {
    setBought(!bought);
    setSold(false);
  };
  const toggleAll = () => {
    setSold(null);
    setBought(null);
  };

  const toggleApply = () => {
    setApplyFilter(!applyFilter);
    setFilterOpen(!filterOpen);
  };

  const excludeBTCToggle = () => {
    excludeBTC === null ? setExcludeBTC("BTC") : setExcludeBTC(null);
  };
  const excludeETHToggle = () => {
    excludeETH === null ? setExcludeETH("ETH") : setExcludeETH(null);
  };

  const changeTradeValue = (val) => {
    setTradeValue(val);
  };

  const setFilterValue = (filterValue) => {
    setFilter(filterValue);
  };
  const setExchangeFilterValue = (filterValue) => {
    setExchangeFilter(filterValue);
  };

  const toggleFilterPopup = () => {
    setFilterOpen(!filterOpen);
  };

  const setDataDisplayValue = (displayValue) => {
    setDataDisplay(dataDisplay);
  };

  const toggleDisplay = () => {
    setDataDisplay(!dataDisplay);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    initGA("UA-237366808-1");
    PageView();
    axios
      .get(
        `https://api.whaletracer.com/trades/${sold}/${tradeValue}/${excludeBTC}/${excludeETH}/${filter}`
      )
      .then((response) => {
        setTrades(response.data);
      });
    axios
      .get("https://api.whaletracer.com/cex-trades/biggest-sell")
      .then((response) => setBiggestSell(response.data[0]));
    axios
      .get("https://api.whaletracer.com/cex-trades/biggest-buy")
      .then((response) => setBiggestBuy(response.data[0]));
    axios
      .get(`https://api.whaletracer.com/cex-trades/toptennew/1`)
      .then((response) => {
        setTopTrades(response.data);
      });

    setDataLoading(false);
  }, [filter, applyFilter]);

  let filteredTrades = trades.filter((trade) =>
    trade.exchange_name.includes(exchangeFilter)
  );

  const twitterHref = (
    baseAsset,
    quoteAsset,
    tradeValue,
    qty,
    exchange,
    side
  ) => {
    return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (CEX) %0A %0A${qty} $${baseAsset} has been ${
      side ? "sold" : "purchased"
    } for $${tradeValue} using $${quoteAsset} on ${exchange}. %0A %0ACheck Out Whale Trades At: WhaleTracer.com %0A %0A %23${baseAsset} %23${quoteAsset}  %23${exchange.toUpperCase()} %23WHALETRACER`;
  };

  const loadTrades = () => setCardsQty((prevQuantity) => (prevQuantity += 30));

  if (dataLoading || biggestBuy.length === 0 || biggestSell.length === 0) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-lg">
          Loading trades data...
        </p>
      </div>
    );
  }

  return (
    <Fade bottom>
      <div className="text-white max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] font-sans">
        <FiltersPopup
          handleOpen={toggleFilterPopup}
          open={filterOpen}
          toggleSold={toggleSold}
          toggleBought={toggleBought}
          toggleAll={toggleAll}
          toggleExcludeBTC={excludeBTCToggle}
          toggleExcludeETH={excludeETHToggle}
          changeTradeValue={changeTradeValue}
          sold={sold}
          bought={bought}
          excludeBTC={excludeBTC}
          excludeETH={excludeETH}
          tradeValue={tradeValue}
          toggleApply={toggleApply}
          setFilterValue={setFilterValue}
          filter={filter}
        />
        <div className="text-white max-w-wt 2xl:max-w-ft mx-auto mt-12">
          <div className="">
            <div className="mt-8 mb-8">
              <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white">
                WHALE TRACKER ON CENTRALISED EXCHANGES
              </h1>
              <p className="mb-4 md:mb-4 md:mt-0 text-md md:text-xl text-slate-300 text-center md:text-left">
                {" "}
                We are tracking CEX trades above $50,000+ from popular
                exchanges.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8">
              <MostTraded topTrades={topTrades} />
              <BiggestTradeCard
                data={biggestBuy}
                heading="BIGGEST BUY OF THE DAY"
                img="https://i.stack.imgur.com/muKvK.png"
                sold={false}
              />
              <BiggestTradeCard
                data={biggestSell}
                heading="BIGGEST SELL OF THE DAY"
                img="https://i.stack.imgur.com/XxdOZ.png"
                sold={true}
              />
            </div>
          </div>
        </div>
        {!auth && (
          <div className="text-center mt-12">
            {" "}
            <Link to="/register">
              <button className="bg-[#6DCDF5] mt-2 px-4 py-2 rounded text-xs font-bold text-black">
                Become a Member
              </button>
            </Link>
            <p className="text-md mt-2 text-slate-300 dark:text-slate-300 text-center md:text-center ">
              Unlock all filters with{" "}
              <span className="font-black">100% FREE</span> member account{" "}
            </p>{" "}
          </div>
        )}
        <div className="mx-auto mt-20">
          <div className="md:mx-auto">
            <div>
              <h1 className="font-black text-2xl md:text-4xl text-center md:text-left text-white">
                {exchangeFilter === "" ? (
                  "ALL"
                ) : (
                  <span className="text-[#8EDAFA] font-black uppercase">
                    {exchangeFilter}
                  </span>
                )}{" "}
                CEX BIG TRADES ACTIVITY
              </h1>
              <p className="mb-4 md:mb-4 md:mt-0 text-sm md:text-md text-slate-300 text-center md:text-left max-w-3xl">
                {" "}
                We are tracking $50,000+ single trades from the Centralized
                Exchange.
              </p>
            </div>
            <div className="md:block">
              <Filters
                filter={filter}
                setFilter={setFilterValue}
                dataDisplay={dataDisplay}
                setDisplay={setDataDisplayValue}
                toggleDisplay={toggleDisplay}
                exchange={setExchangeFilterValue}
                exchangeFilter={exchangeFilter}
                toggleFilter={toggleFilterPopup}
              />
            </div>
          </div>
          <div className="">
            {dataDisplay ? (
              <div>
                {auth && (
                  <div
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
                    id="tradeCards"
                  >
                    {filteredTrades.slice(0, cardsQty).map((trade) => (
                      <TradesCard
                        symbol={trade.base_asset_trade}
                        pair={trade.pair}
                        image={trade.base_asset_icon}
                        price={trade.price_in_quote_asset}
                        quantity={trade.qty}
                        quoteAsset={trade.quote_asset_trade}
                        baseAsset={trade.base_asset_trade}
                        time={timeToGo(trade.trade_time)}
                        isBuyer={trade.is_buyer_maker}
                        cost={trade.trade_value}
                        exchange={trade.exchange_name}
                        exchangeLogo={trade.exchange_icon_url}
                        interval={filter}
                        id={trade.id}
                        href={twitterHref}
                      />
                    ))}
                  </div>
                )}
                {!auth && (
                  <div
                    ref={containerRef}
                    className="relative grid h-30rem] max-h-[150vh] overflow-hidden"
                  >
                    <div
                      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
                      id="tradeCards"
                    >
                      {filteredTrades.slice(0, 12).map((trade) => (
                        <TradesCard
                          symbol={trade.base_asset_trade}
                          pair={trade.pair}
                          image={trade.base_asset_icon}
                          price={trade.price_in_quote_asset}
                          quantity={trade.qty}
                          quoteAsset={trade.quote_asset_trade}
                          baseAsset={trade.base_asset_trade}
                          time={timeToGo(trade.trade_time)}
                          isBuyer={trade.is_buyer_maker}
                          cost={trade.trade_value}
                          exchange={trade.exchange_name}
                          exchangeLogo={trade.exchange_icon_url}
                          interval={filter}
                          id={trade.id}
                          href={twitterHref}
                        />
                      ))}
                    </div>
                    <div className="pointer-events-none absolute inset-x-0 bottom-0 h-60 bg-gradient-to-t from-[#141724]" />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {auth && (
                  <div className="mt-8">
                    {filteredTrades.slice(0, cardsQty).map((trade) => (
                      <TableView
                        symbol={trade.base_asset_trade}
                        pair={trade.pair}
                        image={trade.base_asset_icon}
                        price={trade.price_in_quote_asset}
                        quantity={trade.qty}
                        multiplier={""}
                        quoteAsset={trade.quote_asset_trade}
                        baseAsset={trade.base_asset_trade}
                        time={timeToGo(trade.trade_time)}
                        isBuyer={trade.is_buyer_maker}
                        cost={trade.trade_value}
                        exchange={trade.exchange_name}
                        exchangeLogo={trade.exchange_icon_url}
                        interval={filter}
                        id={trade.id}
                        href={twitterHref}
                      />
                    ))}
                  </div>
                )}
                {!auth && (
                  <div
                    ref={containerRef}
                    className="relative grid h-[50rem] max-h-[150vh] overflow-hidden"
                  >
                    {" "}
                    <div className="mt-8">
                      {filteredTrades.slice(0, 12).map((trade) => (
                        <TableView
                          symbol={trade.base_asset_trade}
                          pair={trade.pair}
                          image={trade.base_asset_icon}
                          price={trade.price_in_quote_asset}
                          quantity={trade.qty}
                          multiplier={""}
                          quoteAsset={trade.quote_asset_trade}
                          baseAsset={trade.base_asset_trade}
                          time={timeToGo(trade.trade_time)}
                          isBuyer={trade.is_buyer_maker}
                          cost={trade.trade_value}
                          exchange={trade.exchange_name}
                          exchangeLogo={trade.exchange_icon_url}
                          interval={filter}
                          id={trade.id}
                          href={twitterHref}
                        />
                      ))}
                    </div>{" "}
                    <div className="pointer-events-none absolute inset-x-0 md:bottom-0 2xl:bottom-0 h-60 bg-gradient-to-t from-[#141724]" />
                  </div>
                )}
              </div>
            )}
          </div>{" "}
        </div>
        {auth && (
          <div className="text-center mt-20">
            {cardsQty >= filteredTrades.length ? (
              <p className="text-lg text-slate-300 dark:text-slate-300 text-center md:text-center ">
                Sorry, there are no more trades to show. Keep checking for whale
                acitivity.{" "}
              </p>
            ) : (
              <button
                type="button"
                className="inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                onClick={loadTrades}
              >
                View More
                <ChevronDownIcon
                  className="ml-2 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        )}
        {!auth && (
          <div className="text-center mt-20">
            {" "}
            <Link to="/register">
              <button className="bg-[#6DCDF5] mt-2 px-4 py-2 rounded text-xs font-bold text-black">
                Become a Member
              </button>
            </Link>
            <p className="text-md mt-2 text-slate-300 dark:text-slate-300 text-center md:text-center ">
              Unlock all filters with{" "}
              <span className="font-black">100% FREE</span> member account{" "}
            </p>{" "}
          </div>
        )}
        <FAQ />
      </div>

      <Footer />
    </Fade>
  );
};

export default Alerts;
