import React, { useEffect } from "react";
import "./Terms.css";
import Footer from "../../components/Footer/Footer";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="mt-8 mb-8 text-white max-w-wt 2xl:max-w-ft mx-auto font-sans">
        {" "}
        <h1 className="text-4xl font-bold leading-8 text-white md:text-5xl md:font-extrabold text-center md:text-left">
          TERMS OF USE
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          These terms and conditions of use (“Terms”) apply to your use of the
          WhaleTracer whaletracer.com website (the “Site”), provided by Whale
          Tracer and its affiliates (“WhaleTracer”). WhaleTracerdoes not take
          any responsibility for the accuracy of the information present on this
          Site. WhaleTracer reserves the right to change any and/or all
          information (including Terms). If WhaleTracer does make any such
          changes, the revised documents will be posted on this Site and the
          publication date will be updated. However, WhaleTracer will not
          provide any other type of notice to you directly. We sincerely
          encourage you to periodically check all documents relevant to your
          situation in order to stay informed about their status. For more
          information about our online privacy and cookie practices please read
          our Privacy Policy.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          DISCLAIMER OF WARRANTY
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          All materials contained herein are provided “AS IS” without warranty.
          WhaleTracer disclaims warranties of any kind, including all conditions
          of merchantability, whether expressly implied or statutory, fitness
          for a particular purpose, title and non-infringement as well as
          warranties concerning availability, accuracy or content of data or
          other information. WhaleTracer does not warrant that the Site or the
          functions, features, or content contained therein will be timely,
          secure, uninterrupted or error free, or that defects will be
          corrected. WhaleTracer shall not be responsible for any malfunctions,
          errors, crashes or other adverse events that may occur in connection
          with your use of the Site. Any use of the materials of this Site is at
          your own discretion and risk and you are solely responsible for any
          damage to your computer system, including but not limited to loss of
          data. If you are dissatisfied with the Site or any content or function
          thereon, you should simply discontinue accessing the Site.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          LIMITATION OF LIABILITY
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          Under no circumstances shall WhaleTracer be liable for any kind of
          damages indirect or consequential, including loss of profits or
          revenues and/or costs of replacement goods or damages resulting from
          the use of the information or materials present, even if WhaleTracer
          was informed in advance of the possibility of such damages.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          USER CONDUCT
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          While using the Site, you shall not: <br /> “Upload, post or transmit
          to, or otherwise publish through, the Site any material that violates
          any provision of these Terms; “Restrict or inhibit any other user from
          using and enjoying the Site; “Conduct yourself or encourage others to
          conduct themselves in a manner that would constitute a criminal
          offense or give rise to civil liability; “Impersonate or misrepresent
          your affiliation with any other person or entity; “Upload, post,
          publish, transmit, reproduce, distribute or in any way exploit any
          information or other material obtained through the Site for commercial
          purposes (other than as expressly permitted by the provider of such
          information or other material); “Engage in spamming or flooding; or
          “Attempt to gain unauthorized access to other computer systems through
          the Site.” WhaleTracer retains the right, but does not assume any
          obligation, to monitor the Site and to disclose any information as
          WhaleTracer deems necessary or appropriate to satisfy any law,
          regulation or other governmental request, to comply with any subpoena
          or other legal process, to operate the Site properly, to protect
          ourselves or our users or for other reasons as WhaleTracer determines
          in our sole discretion. WhaleTracer reserves the right to remove or
          refuse to post any information or materials, in whole or in part, that
          we determine,are unacceptable, undesirable, inappropriate or in
          violation of these Terms. WhaleTracer may also suspend or terminate
          your use of the Site at any time.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          SOFTWARE DOWNLOADS
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          Any software that is made available to download from this Site is the
          copyrighted work of WhaleTracerand/or its suppliers. Such software
          shall be used in accordance with the respective terms of the End User
          License Agreement (EULA) that accompanies the software. Any use of the
          Software not in accordance with the respective EULA is expressly
          prohibited.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          USE OF CONTENT
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          All the information, communications, software, scripting, photos,
          text, video, graphics, music, sounds, images and other materials found
          on this Site (collectively referred to as “Content”) may be used for
          informational and non-commercial or personal use only, provided you do
          not modify the materials and that you retain all copyright and other
          proprietary notices contained in the materials. You may not use the
          content of the Site for commercial purposes without the prior
          expressed written consent of WhaleTracer The unauthorized copying,
          displaying, or other use of any Content from this Site is a violation
          of the domestic and/or international legislation and may be pursued to
          the full extent of federal and state law. Permission to link to Whale
          Tracer from any site is not required, but prior notification to Whale
          Tracer would be appreciated.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          COPYRIGHT AND TRADEMARKS
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          All product and brand names are trademarks or registered trademarks of
          their respective holders. The trademark of WhaleTraceris provided for
          your information and by making it available WhalWhaleTracers not grant
          you a license to use it. The copyright in all material provided on
          this Site is held by WhaleTracer or by the original creator of the
          material. Neither title nor intellectual property rights are
          transferred to you, but remain the full and complete rights and title
          of WhaleTracer or the original creator.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          LINKS TO THIRD-PARTY SITES
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          This Site may provide links to other third-party sites. WhaleTracer
          makes no representations whatsoever about any other site which you may
          access through this Site. You acknowledge and agree that WhaleTracer
          is not responsible for the content of any linked site or any link
          contained in a linked site. References on this Site to any names,
          marks, products or services of any third parties or hypertext links to
          third party sites or information are provided solely for your
          convenience and do not constitute an endorsement by or association
          with WhaleTracer of such sites or the content, products, advertising
          or other materials presented on such sites. You acknowledge and agree
          that WhaleTracer is not responsible or liable, directly or indirectly,
          for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or
          services available on such linked sites.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          APPLICABLE LAWS
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          This site is operated by WhaleTracer from within Estonia. WhaleTracer
          makes no representation that the materials of the Site are available
          for use in other locations, and access to them from territories where
          their contents are illegal is prohibited. Those who choose to access
          this site from other locations do so at their own risk and are
          responsible for compliance with any applicable local laws. User may
          not use or transfer the materials of the Site in violation of Estonia
          export or other law and regulations. Any claim relating to the Terms
          shall be governed by the Laws of the Estonia.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          QUESTIONS
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          Please contact usif you have any questions about WhaleTracer privacy
          policy or other legal guidelines.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
