import React, { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import DisclaimerContext from "../../context/Disclaimer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "22rem",
  bgcolor: "#403E66",
  border: "2px solid #403E66",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function Disclaimer({
  handleDisclaimerClick,
  isDisclaimerClick,
}) {
  const { isShowDisclaimer, setIsShowDisclaimer } =
    useContext(DisclaimerContext);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShowDisclaimer}
        onClose={() => setIsShowDisclaimer(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isShowDisclaimer}>
          <Box sx={style}>
            <div className="login-heading">
              <div className="flex flex-row justify-between items-center">
                <p className="text-white text-md font-extrabold">DISCLAIMER</p>
                <p
                  className="close-button"
                  onClick={() => setIsShowDisclaimer(false)}
                >
                  Close
                </p>
              </div>
              <p className="text-sm text-slate-400 dark:text-slate-400 md:text-left mt-2">
                All content provided herein our website, hyperlinked sites &
                social media accounts and other platforms (“Site”) is for your
                information purpose only, procured from third party sources. We
                make no warranties of any kind in relation to our content is
                100% accurate. No part of the content should be considered as
                advice, legal advice or any kind of financial advice. Any use of
                our content is solely at your own risk. You should always make
                your own research, review, analyse and verify our content before
                relying on them. Trading is a highly risky activity that can
                lead to major losses, please therefore consult your financial
                advisor before making any decisions.
              </p>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
