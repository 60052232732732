import React, { useEffect } from "react";
import "./Privacy.css";
import Footer from "../../components/Footer/Footer";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {" "}
      <div className="mt-8 mb-8 text-white max-w-wt 2xl:max-w-ft mx-auto font-sans">
        <h1 className="text-4xl font-bold leading-8 text-white md:text-5xl md:font-extrabold text-center md:text-left">
          ONLINE PRIVACY POLICY
        </h1>

        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          1. WHY ARE YOU READING THIS?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          WhaleTracer LLC, an international software development company,
          together with its affiliates and subsidiaries (collectively
          “Intellectsoft” or “we”), has prepared this Privacy Policy (“Policy”)
          to outline its online data protection practices. This Policy explains
          why and what information about you will be collected by WhaleTracer
          when you visit its website at whaletracer.com, and relative
          subdomains, and when you contact WhaleTracer by sending an email,
          filling in the contact form or otherwise, or when you apply for a job
          posted on our Website. It also describes how the information about you
          will be used, how you, as a data subject, can control your personal
          data and how to get in touch with us if you need. This Policy also
          explains our use of cookies. Please read this Policy carefully before
          browsing this Website. We will process your data only as described in
          this Policy. Our Websites are not directed to children under the age
          of 16, and we do not knowingly collect personal data from children of
          this age group. This Policy is an integral part of the Terms of Use of
          this Website. We might update this Policy in the future, so please
          make sure to read it from time to time. Should you have any questions
          or comments as for this Policy, please contact us at
          info@whaletracer.com
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          2. WHAT DOES WHALE TRACER HAVE TO DO WITH YOUR DATA?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          In light of the EU General Data Protection Regulation (“GDPR”), Whale
          Tracer is a controller and a processor of the personal data submitted
          through this Website and collected in other ways. Being a controller
          means that we are responsible for deciding how we hold and use
          personal information about you.
          <br /> WhaleTracer uses a third-party for recruitment processing
          services. By submitting an online application for any vacancy placed
          on our Website you expressly submit your personal data, contact
          information, CV and any other information you choose, and agree that
          it will be collected and processed by WhaleTracer. In such a case
          WhaleTracer stays the data controller and the processor of your data.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          3. WHAT DATA ABOUT YOU DO WE COLLECT?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          We only collect information about you that is necessary for us to
          allow you to use the Website or contact us, and only to the extent
          specifically provided in this Policy. <br />
          <span className="small-heading">
            {" "}
            <br /> Information we obtain from you{" "}
          </span>{" "}
          <br />
          When you contact us via email or via the contact form, you provide us
          with your name, phone number, e-mail address, communication content
          and data associated with the communication. Examples of personal data
          that you provide to us when applying for a vacancy are full name,
          e-mail, phone number, location, any other information you choose to
          provide in your CV or together with it. You might also choose to
          provide us with any other personal data, for example the personal data
          of a candidate for work whom you want to refer. From time to time,
          WhaleTracer also receives personal information about individuals from
          third parties around the world. This may happen if you publish your CV
          on the Internet on some job boards, giving them the consent to use and
          transmit your data, if someone recommended you for potential
          employment and in other similar cases. We encourage you to review the
          privacy policies of any third-party websites before providing your
          information to them. <br />
          <span className="small-heading">
            {" "}
            <br />
            Information we obtain from your use of our Websites When you visit
            our
          </span>{" "}
          <br />
          Websites we may collect the following information about you:
          information about your device(s), in particular, hardware model,
          version of the operating system; information about your visits to and
          use of this Website, including your IP address, geographical location,
          browser type and version, referral source, page views and Website
          navigation paths; For more information on the cookies being used on
          the Website and how you can opt-out, please visit the section 7of this
          Policy.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          4. WHY DO WE PROCESS YOUR PERSONAL DATA?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400  dark:text-slate-400 md:text-left">
          We will only process your personal data where we have a legal basis to
          do so. The legal basis will depend on the reasons we have collected
          and need to use your personal data for. We may process your personal
          data because you have consented to us using your personal data we need
          to comply with a legal obligation it is in our legitimate business
          interests More specifically, personal data submitted to us through
          this website and personal data we collected from you or third parties
          or from public sources will be used for the purposes based on the
          legal grounds as follows: <br />
          <br />
          <span className="small-heading">
            Communications, based on WhaleTracer legitimate interest, art. 6
            para. 1 f GDPR:
          </span>{" "}
          <br />
          if you have contacted us via this website or otherwise, we may process
          your personal data to be able to answer your questions, to organize
          the meeting, call, zoom or other communication. Please note that this
          communication may be recorded and stored by WhaleTracer representative
          in WhaleTracer corporate system. <br /> <br />
          <span className="small-heading">
            Marketing and public relations, based on your consent,art. 6 para. 1
            a GDPR:{" "}
          </span>{" "}
          <br />
          if you are a visitor of our website, we may, by way of cookies,
          process your data to carry out marketing research and to analyze the
          characteristics of this website visitors, to assess the impact of our
          marketing communication and to adjust it to the detected tendencies,
          to plan our future marketing activities, to prepare analytics and
          profiling for business intelligence, to target our advertising. if you
          are our subscriber, you receive or would like to receive our marketing
          materials, we may process the personal data you provided to us to
          spread promotional materials, to make announcements about additions
          and changes concerning our services, to send you e-mail notifications,
          to send you our email newsletters. <br /> <br />
          <span className="small-heading">Recruitment </span> <br />
          <span className="small-heading">
            {" "}
            a) based on your consent, 6 para. 1 a GDPR:{" "}
          </span>{" "}
          <br /> if you are a potential employee, we may process your personal
          data to assist you with ongoing or future employment or cooperation
          with Intellectsoft, to enable you to submit your resume, apply for
          jobs online or express your interest in relocation. <br />{" "}
          <span className="small-heading">
            b) based on WhaleTracer legitimate interest, 6 para. 1 f GDPR:{" "}
          </span>{" "}
          <br /> in specific cases, and only upon the client’s request, we may
          show your CV to a potential client for business engagement or to
          current client to confirm the high level of skills of our members of
          staff providing the services. Also, to fulfill its compliance
          obligations, our client may need to check your biography more closely
          before providing you with the access to its protected data and
          facilities. Therefore, we may ask you to provide more specific
          information, such as tax number, work permission, citizenship,
          criminal background and other additional and specific background
          checks required by particular clients to provide you with an access to
          clients’ systems and premises. <br />
          <br />{" "}
          <span className="small-heading">
            Business relations, based on WhaleTracer legitimate interest, art. 6
            para. 1 f GDPR:{" "}
          </span>{" "}
          <br /> if you are a representative of our current or potential client,
          vendor or business partner, we may process your personal data to
          organize the approval, processing and signing of contracts, acts,
          invoices and other contractual documentation; to promote our new
          products, works and services; to confirm the high level of our works
          and services; to invite you to the meetings and events or organize
          them; to comply with economic sanctions and export control list
          screening, industry standards, regulators’ requirements and other
          requirements related to anti-corruption, fraud prevention and
          anti-money laundering. <br />
          <br />{" "}
          <span className="small-heading">
            Corporate and investor relations, based on WhaleTracercompliance
            obligations, art. 6 para. 1 c GDPR:{" "}
          </span>{" "}
          if you are a current or potential director or top manager of
          Intellectsoft, we may process your personal data to organize your
          participation in corporate meetings, events, business trips, to pay
          your remuneration, to cover related expenses, to maintain our
          corporate records, to make obligatory public disclosures and reports,
          to execute auditor’s requests, to present the company and its
          management during the sale process and to perform other legal
          obligations. We also may share this information within Intellectsoft,
          provide some of this data to banks, notary, payroll agencies and legal
          advisors with the purpose of opening of a corporate bank account or
          other accounts; if you are a current or potential affiliate of
          Intellectsoft, we may process your personal data to organize your
          participation in corporate meetings and events, to provide you with
          the meetings’ materials and our reports, to support you in execution
          of your rights, to make obligatory public disclosures and reports, to
          execute auditor’s requests and to perform other legal obligations,
          including anti-money laundering requirements and disclosure of
          beneficiaries. <br />
          <br />{" "}
          <span className="small-heading">
            {" "}
            Security, based on WhaleTracerlegitimate interest, art. 6 para. 1 f
            GDPR:{" "}
          </span>{" "}
          <br />
          if you are a visitor of this website, we may process your personal
          data to keep this website secure, prevent fraud, protect rights and
          interests of WhaleTracer and third parties, protect Intellectual
          Property rights; if you are a visitor of our office, we may process
          your personal data to monitor physical access and to ensure security
          in our office, as well as to prevent, detect and investigate any
          crime, misconduct, incident or accident.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          5. HOW CAN WE USE YOUR PERSONAL DATA?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          We may process your data by way of collecting, recording, organizing,
          storing, using, structuring, adapting or altering, retrieving,
          consulting, aligning or combining, erasing or destructing. Also, as
          WhaleTracer is an international group of companies, we may disclose
          your data by transmission, dissemination or otherwise making
          available. To the extent permitted by applicable data protection laws,
          your personal data may be transferred between various locations of
          WhaleTracer and thus processed in European Economic Area and outside
          of it, depending on the purpose of processing. Such transfer and
          processing are possible only as reasonably necessary for the purposes
          set out in this Policy and within the scope of legitimate interest of
          Intellectsoft. We may disclose your personal data to some of our
          clients for business engagement or to confirm the high level of skills
          of our team members to auditors, agencies, supervisory authorities or
          to other external service providers on “need to know basis” to perform
          our contractual obligations. We may also disclose your personal data:{" "}
          <br /> a) to the extent that we are required to do so by law; <br />{" "}
          b) in connection with any ongoing or prospective legal proceedings;{" "}
          <br /> c) in order to establish, exercise or defend our legal rights,
          including providing information to others for the purposes of fraud
          prevention and reducing credit risk.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          6. HOW CAN YOU CONTROL YOUR DATA?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          You, as a Data Subject, have the right to: <br /> a) Request
          information about whether we hold personal information about you, and,
          if so, what that information is and why we are holding or using it.
          <br /> b) Request accessto your personal information. This enables you
          to receive a copy of the personal information we hold about you and to
          check that we are lawfully processing it.
          <br /> c) Request correctionof the personal information that we hold
          about you. This enables you to have any incomplete or inaccurate
          information we hold about you corrected.
          <br /> d) Request erasureof your personal information. This enables
          you to ask us to delete or remove personal information where there is
          no good reason for us continuing to process it. You also have the
          right to ask us to delete or remove your personal information where
          you have exercised your right to object to processing (see below).
          <br /> e) Object to processingof your personal information where we
          are relying on a legitimate interest (or those of a third party) and
          there is something about your particular situation which makes you
          want to object to processing on this ground. You also have the right
          to object where we are processing your personal information for direct
          marketing purposes.
          <br /> f) Object to automated decision-making,including profiling,
          that is not to be subject of any automated decision-making by us using
          your personal information or profiling of you.
          <br /> g) Request the restrictionof processing of your personal
          information. This enables you to ask us to suspend the processing of
          personal information about you, for example if you want us to
          establish its accuracy or the reason for processing it.
          <br /> h) Request transferof your personal information in an
          electronic and structured form to you or to another party. This
          enables you to take your data from us in an electronically useable
          format and to be able to transfer your data to another party in an
          electronically useable format.
          <br /> i) Withdraw consent.You have the right to withdraw your consent
          to the collection, processing and transfer of your personal
          information for a specific purpose at any time. Once we have received
          notification that you have withdrawn your consent, we will no longer
          process your information for the purpose or purposes you originally
          agreed to, unless we have another legitimate basis for doing so in
          law. The withdrawal of consent will not affect the lawfulness of
          processing based on consent prior its withdrawal. If you want to
          exercise any of these rights, please contact us at info@WhaleTracer.
          The request response time is one month. We may need to request
          specific information from you to help us confirm your identity and
          ensure your right to access the information or to exercise any of your
          other rights. This is another security measure to ensure that personal
          information is not disclosed to any person who has no right to receive
          it. Please exercise your rights wisely and note that abuse of rights
          may entail your liability.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          7. HOW IS YOUR DATA RETAINED?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          We make sure to use appropriate technical and physical security
          measures to protect your personal data which is transmitted, stored or
          otherwise processed by us, from accidental or unlawful destruction,
          loss, alteration, unauthorized disclosure of, or access. <br /> Your
          personal data is securely stored by WhaleTracer on its servers, and on
          the servers of the cloud-based database management services that Whale
          Tracer engages, such as Workable. <br /> We will not retain your data
          for longer than it is necessary to fulfil the purpose it is being
          processed for. To determine the appropriate retention period, we
          consider the amount, nature and sensitivity of the personal data, the
          purposes for which we process it and whether we can achieve those
          purposes through other means. We must also consider periods for which
          we might need to retain personal data in order to meet our legal
          obligations or to deal with complaints, queries and to protect our
          legal rights in the event of a claim being made. When we no longer
          need your personal data, we will securely delete or destroy it. We
          will retain information and documents, including electronic documents,
          containing personal data: <br /> a) to the extent that we are required
          to do so by law <br /> b) if the information is relevant to any
          ongoing or prospective legal proceedings, and <br /> c) in order to
          establish, exercise or defend our legal rights, including providing
          information to others for the purposes of fraud prevention and
          reducing credit risk.
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          8. WHAT ARE COOKIES AND HOW DO WE USE THEM?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          When you visit the WhaleTracerwebsites, WhaleTracer may place cookies
          and similar analytical codes (collectively, “Cookies”) on your device,
          browser or the webpage you are viewing, in order to personalize your
          experience, understand usage patterns and provide, improve, and secure
          the WhaleTracerCookies are simple computer files made of text. Cookies
          do not typically contain any information that personally identifies
          someone, but personal data that we store about you may be linked to
          the information obtained from cookies. <br /> Our websites use
          different types of Cookies for different reasons, as follows: <br />{" "}
          <span className="small-heading">Functional cookies </span> – these
          Cookies are essential to enable you to move around the WhaleTracer
          websites and use their features. These Cookies make sure you can view
          the websites and use them in a proper way. They also give you access
          to secured parts of the Whale TWhaleTraceres of functional cookies:
          SID, PHPSESSID, wp-settings-X, wp-settings-time-X, etc. <br />{" "}
          <span className="small-heading">
            {" "}
            Analytical cookies and other cookies{" "}
          </span>{" "}
          – these Cookies help us improve all our websites, collect anonymous
          information about how visitors use our websites, collect information
          about the most visited pages and tell us whether and how many error
          messages were displayed. Examples of analytical cookies: 1_P JAR, _ga,
          _gid, etc.
          <br /> <span className="small-heading">Third-party cookies </span> –
          these Cookies help third parties to help track and manage the
          effectiveness of, for example, their websites, ads, number of
          visitors. More information about these Cookies may be available on the
          relevant third party’s website. Examples of third-party cookies:
          bcookie, sb, fr, spin, wd, XS, etc. <br /> Based on how long they are
          valid, the Cookies on the WhaleTracer websites may be either
          persistent cookies or session cookies: a persistent cookie will be
          stored by a web browser and will remain valid until its set expiry
          date, unless deleted by the user before the expiry date; a session
          cookie, on the other hand, will expire at the end of the user session,
          when the web browser is closed. If you want to receive more
          information about the cookies we use, please contact us. <br /> You
          can adjust your browser settings to delete some of our cookies or
          cookies set by third parties. You may also adjust your browser
          settings to prevent websites from setting cookies or third-party
          cookies altogether. If you prevent us from setting specific cookies,
          you may find that some functions are not available or that certain
          parts of the website will not load. Find out how to adjust the
          settings for different browsers: • Chrome • Firefox • Internet
          Explorer / Edge • Safari
        </p>
        <h1 className="text-lg font-bold leading-8 text-white md:text-xl md:font-extrabold md:text-left mt-8">
          9. DO YOU HAVE ANY QUESTIONS?
        </h1>
        <p className="mb-8 mt-4 text-md text-slate-400   dark:text-slate-400 md:text-left">
          If you would like to ask something regarding this Privacy Policy,
          please contact us at info@whaletracer.com any time.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
