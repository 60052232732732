import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import useAuth from "../../hooks/useAuth";
import useUpgrade from "../../hooks/useUpgrade";

const SubNav = (props) => {
  const { auth } = useAuth();
  const { setOpen } = useUpgrade();

  const useAnalytics = () => {
    const link = useLocation();
    const activePage = link.pathname;
    return activePage;
  };
  const navigation = [
    {
      name: "Watchlist (CEX)",
      href: "/dashboard/watchlist",
      current: useAnalytics() === "/dashboard/watchlist",
    },
    {
      name: "Follow Whale Wallets (DEX)",
      href: "/dashboard/wallets",
      current: useAnalytics() === "/dashboard/wallets",
    },
    {
      name: "Profile",
      href: "/profile",
      current: useAnalytics() === "/profile",
    },
  ];
  const guestNavigation = [
    {
      name: "Watchlist (CEX)",
      href: "/login",
      current: useAnalytics() === "/dashboard/watchlist",
    },
    {
      name: "Follow Whale Wallets (DEX)",
      href: "/login",
      current: useAnalytics() === "/dashboard/wallets",
    },
  ];
  const active = "text-[#B0EEFD] hover:font-bold cursor-pointer";
  const inActive = "hover:text-[#B0EEFD] hover:font-bold cursor-pointer";
  return (
    <>
      {auth ? (
        <div className="flex flex-row justify-center bg-[#131725] text-white gap-x-8  font-bold py-4 text-xs sm:text-sm">
          {navigation.map((element) => (
            <Link to={element.href}>
              <p className={element.current ? active : inActive}>
                {element.name}
              </p>
            </Link>
          ))}
        </div>
      ) : (
        <div className="flex flex-row justify-center bg-[#131725] text-white gap-x-8  font-bold py-4">
          {guestNavigation.map((element) => (
            <p
              className="hover:text-[#B0EEFD] hover:font-bold cursor-pointer text-xs sm:text-sm"
              onClick={() => setOpen(true)}
            >
              {element.name}
            </p>
          ))}
        </div>
      )}

      <div className="border-t border-gray-600"></div>
    </>
  );
};

export default SubNav;
