import ReactGA from "react-ga";

export const initGA = (trackingID) => {
  console.log("GA is initialized!");
  ReactGA.initialize(trackingID);
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log("Page view is logged!");
};

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
  console.log(`${action} is logged!`);
};
