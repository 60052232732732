import { createContext, useState } from "react";
import Axios from "axios";
import useUser from "../hooks/useUser";

const CardContext = createContext({});

export const CardProvider = ({ children }) => {
  const [cards, setCards] = useState(null);

  return (
    <CardContext.Provider value={{ cards, setCards }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardContext;
