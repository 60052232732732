import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import EmailIcon from "../../assets/email-icon.png";
import Success from "../../assets/checked.png";
import Axios from "axios";
import { Event } from "../Tracking/Tracking";

const styleWeb = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "#403E66",
  border: "2px solid #403E66",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styleMob = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "22rem",
  bgcolor: "#403E66",
  border: "2px solid #403E66",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validEmailStyle =
  "placeholder:italic placeholder:text-slate-400 text-slate-700 block bg-white w-72 mx-auto mt-4 border border-slate-300 rounded-md py-[11px] pl-4 pr-3 shadow-sm focus:outline-none text-sm ";
const inValidEmailStyle =
  "placeholder:italic placeholder:text-slate-400 text-slate-700 block bg-white w-72 mx-auto mt-4 border-2 border-red-600 rounded-md py-[11px] pl-4 pr-3 shadow-sm focus:outline-none text-sm ";

export default function Email({ showSubscribe, handleSubscribeClick }) {
  const [email, setEmail] = useState("");
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const [validEmail, setValidEmail] = useState(false);

  const subscribeBody = {
    email: email,
  };

  const closePopup = () => {
    handleSubscribeClick();
    setSubscribeSuccess(false);
  };

  const subscribeUser = () => {
    Event("Subscribe Button", "Subscribe Button was clicked", "Subscribe link");
    Axios.post(
      "https://api.whaletracer.com/email/subscribe",
      subscribeBody
    ).then((res) => {
      console.log(res);
      setSubscribeSuccess(true);
      setTimeout(closePopup, 2000);
    });

    setEmail("");
  };
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  return (
    <div className="">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showSubscribe}
        onClose={() => {
          handleSubscribeClick();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="hidden md:block"
      >
        <Fade in={showSubscribe}>
          <Box sx={styleWeb}>
            {subscribeSuccess ? (
              <div>
                <div className="flex flex-row justify-end items-center ">
                  <p className="close-button" onClick={closePopup}>
                    Close
                  </p>
                </div>
                <div>
                  <img
                    src={Success}
                    alt="success"
                    className="w-24 object-contain mx-auto mb-4 mt-8"
                  />
                  <p className="text-md font-extrabold text-center mb-8">
                    Subscribed!
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row justify-end items-center">
                  <p className="close-button" onClick={handleSubscribeClick}>
                    Close
                  </p>
                </div>
                <div>
                  <img
                    src={EmailIcon}
                    alt="email-icon"
                    className="w-28 object-contain mx-auto"
                  />
                  <h1 className="text-2xl font-extrabold text-center">
                    SUBSCRIBE TO WHALE TRADE ALERTS!
                  </h1>
                  <p className="mb-4 text-sm  text-slate-400 max-w-3xl dark:text-slate-400 text-center">
                    {" "}
                    Receive daily whale trade alerts via email just in one step.
                    WhaleTracer shares daily updates of Most Traded Coin,
                    biggest buy & sell of the day and other whale data.
                  </p>
                </div>
                <div className="text-center mt-8 mb-8">
                  <input
                    className={
                      validEmail || email.length === 0
                        ? validEmailStyle
                        : inValidEmailStyle
                    }
                    placeholder="Enter your email..."
                    type="text"
                    name="search"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                  <button
                    type="button"
                    className="bg-[#B0EEFD] px-3 py-2 mt-4  w-72 rounded text-black hover:text-black"
                    onClick={validEmail ? subscribeUser : null}
                  >
                    Subscribe
                  </button>
                  <br />
                  {!validEmail && email.length > 0 && (
                    <span className="text-red-500 text-xs">
                      Please enter a valid email
                    </span>
                  )}
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showSubscribe}
        onClose={handleSubscribeClick}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="md:hidden"
      >
        <Fade in={showSubscribe}>
          <Box sx={styleMob}>
            {subscribeSuccess ? (
              <div>
                <div className="flex flex-row justify-end items-center ">
                  <p className="close-button" onClick={closePopup}>
                    Close
                  </p>
                </div>
                <div>
                  <img
                    src={Success}
                    alt="success"
                    className="w-24 object-contain mx-auto mb-4 mt-8"
                  />
                  <p className="text-md font-extrabold text-center mb-8">
                    Subscribed!
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row justify-end items-center">
                  <p className="close-button" onClick={handleSubscribeClick}>
                    Close
                  </p>
                </div>
                <div>
                  <img
                    src={EmailIcon}
                    alt="email-icon"
                    className="w-28 object-contain mx-auto"
                  />
                  <h1 className="text-2xl font-extrabold text-center">
                    SUBSCRIBE TO WHALE TRADE ALERTS!
                  </h1>
                  <p className="mb-4 text-sm  text-slate-400 max-w-3xl dark:text-slate-400 text-center">
                    {" "}
                    Receive daily whale trade alerts via email just in one step.
                    WhaleTracer shares daily updates of Most Traded Coin,
                    biggest buy & sell of the day and other whale data.
                  </p>
                </div>
                <div className="text-center mt-8 mb-8">
                  <input
                    className={
                      validEmail || email.length === 0
                        ? validEmailStyle
                        : inValidEmailStyle
                    }
                    placeholder="Enter your email..."
                    type="text"
                    name="search"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                  <button
                    type="button"
                    className="bg-[#B0EEFD] px-3 py-2 mt-4  w-72 rounded text-white hover:text-white"
                    onClick={validEmail ? subscribeUser : null}
                  >
                    Subscribe
                  </button>
                  {!validEmail && email.length > 0 && (
                    <span className="text-red-500 text-xs">
                      Please enter a valid email
                    </span>
                  )}
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
