import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Axios from "axios";
import { publicRoutes, proRoutes } from "./routes";
import Auth from "@aws-amplify/auth";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Layout from "./components/Layout/Layout";
import Navigation from "./components/Navigation/Navigation";
import SubNav from "./components/Navigation/SubNav";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import Email from "./components/EmailPopup/Email";
import SubscriptionContext from "./context/Subscription";
import useAuth from "./hooks/useAuth";
import useUser from "./hooks/useUser";
import UpgradePopup from "./components/UpgradePopup/UpgradePopup";

const App = () => {
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);

  const { auth, setAuth } = useAuth();
  const { user, setUser } = useUser();
  useEffect(() => {
    async function retainSession() {
      try {
        await Auth.currentSession();
        let email = await Auth.currentAuthenticatedUser();
        email = email.attributes.email;
        localStorage.setItem(email, "email");
        setAuth(true);
        localStorage.setItem("isAuth", auth);
        Axios.get(`https://api.whaletracer.com/user/getUser/${email}`).then(
          (res) => {
            setUser(res.data[0]);
          }
        );
        localStorage.setItem("membership", user.membership);
      } catch (error) {
        console.log(error);
      }
    }
    retainSession();
  }, []);

  const { isShowSubscription, setIsShowSubscription } =
    useContext(SubscriptionContext);

  const handleSubscribeClick = () => {
    setIsShowSubscription((isShowSubscription) => !isShowSubscription);
  };

  const handleDisclaimerClick = () => {
    setIsShowDisclaimer((isShowDisclaimer) => !isShowDisclaimer);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      window.location.reload(false);

      setAuth(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navigation
        handleLogout={handleLogout}
        isLoggedIn={auth}
        handleSubscribeClick={handleSubscribeClick}
      />
      <SubNav />

      <Disclaimer
        handleDisclaimerClick={handleDisclaimerClick}
        isShowDisclaimer={isShowDisclaimer}
      />
      <UpgradePopup />
      <Email
        handleSubscribeClick={handleSubscribeClick}
        showSubscribe={isShowSubscription}
      />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          {publicRoutes.map((route, idx) => (
            <>
              {route.path === "/login" && (
                <Route
                  exact
                  path="/login"
                  element={auth ? <Navigate to="/" /> : <route.component />}
                  key={idx}
                />
              )}
              {route.path === "/forgot-password" && (
                <Route
                  exact
                  path="/forgot-password"
                  element={auth ? <Navigate to="/" /> : <route.component />}
                  key={idx}
                />
              )}
              {route.path === "/register" && (
                <Route
                  exact
                  path="/register"
                  element={auth ? <Navigate to="/" /> : <route.component />}
                  key={idx}
                />
              )}

              {route.path === "/dashboard/wallets" && (
                <Route
                  exact
                  path="/dashboard/wallets"
                  element={
                    auth || localStorage.getItem("isAuth") ? (
                      <route.component />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                  key={idx}
                />
              )}
              {route.path === "/dashboard/watchlist" && (
                <Route
                  exact
                  path="/dashboard/watchlist"
                  element={
                    auth || localStorage.getItem("isAuth") ? (
                      <route.component />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                  key={idx}
                />
              )}
              {route.path === "/dashboard/editwatchlist" && (
                <Route
                  exact
                  path="/dashboard/editwatchlist"
                  element={
                    auth || localStorage.getItem("isAuth") ? (
                      <route.component />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                  key={idx}
                />
              )}
              {route.path === "/profile" && (
                <Route
                  exact
                  path="/profile"
                  element={
                    auth || localStorage.getItem("isAuth") ? (
                      <route.component />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                  key={idx}
                />
              )}
              {route.path === "/favourites" && (
                <Route
                  exact
                  path="/favourites"
                  element={
                    auth || localStorage.getItem("isAuth") ? (
                      <route.component />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                  key={idx}
                />
              )}
              <Route
                exact
                path={route.path}
                element={<route.component />}
                key={idx}
              />
            </>
          ))}
          <Route element={<RequireAuth allowedRoles="pro" />}>
            {proRoutes.map((route, idx) => (
              <Route
                exact
                path={route.path}
                element={<route.component />}
                key={idx}
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
