import React, { useState, useEffect } from "react";
import { timeToGoSec } from "../../services/GeneralFunctions";

import Axios from "axios";
import Logo from "../../assets/smallLogo.png";
import "chart.js/auto";
import DexCard from "../../components/DexCard/DexCard";
import YoutubeEmbed from "../../components/YoutubeFrame/Frame";
import { Link } from "react-router-dom";
import HomeChart from "../../components/HomeChart/HomeChart";
import Fade from "react-reveal/Fade";
import Footer from "../../components/Footer/Footer";
import LogoCloud from "../../components/LogoCloud/LogoCloud";
import CexTrendingTable from "../../components/CexTrendingTable/CexTrending";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { ChevronRightIcon } from "@heroicons/react/solid";

//IMAGES IMPORTS
import Etherscan from "../../assets/etherscan-white.png";
import Avalanche from "../../assets/avalanche-white.png";
import Polygon from "../../assets/polygon-white.png";

import useAuth from "../../hooks/useAuth";

const Home = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [cex, setCex] = useState(null);
  const [dex, setDex] = useState(null);
  const [btc, setBtc] = useState(null);
  const [eth, setEth] = useState(null);
  const [others, setOthers] = useState(null);
  const [trades, setTrades] = useState([]);

  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [subscribeError, setSubscribeError] = useState(false);

  const { auth } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Axios.get("https://api.whaletracer.com/cex-trades/total-trades-btc").then(
      (response) => {
        setBtc(parseInt(response.data.count));
      }
    );
    Axios.get("https://api.whaletracer.com/cex-trades/total-trades-eth").then(
      (response) => {
        setEth(parseInt(response.data.count));
      }
    );
    Axios.get(
      "https://api.whaletracer.com/cex-trades/total-trades-others"
    ).then((response) => {
      setOthers(parseInt(response.data.count));
    });
    Axios.get("https://api.whaletracer.com/cex-trades/total-trades-cex").then(
      (response) => {
        setCex(response.data.count);
      }
    );
    Axios.get("https://api.whaletracer.com/cex-trades/total-trades-dex").then(
      (response) => {
        setDex(response.data.count);
      }
    );
    Axios.get(`https://api.whaletracer.com/dex-trades/100`).then((res) => {
      setTrades(res.data);
    });

    setDataLoading(false);
  }, []);

  const dataSet = [
    { label: "BTC", value: btc },
    { label: "ETH", value: eth },
    { label: "Others", value: others },
  ];

  const chartData = {
    labels: dataSet.map((e) => e.label),
    datasets: [
      {
        label: "Top 3 Traded CEX",
        data: [btc, eth, others],
        backgroundColor: ["#4535A1", "#627EEA", "#9CBBC3"],
        borderColor: ["#4535A1", "#627EEA", "#9CBBC3"],
        hoverBackgroundColor: ["#4535A1", "#627EEA", "#9CBBC3"],
        hoverBorderColor: ["#4535A1", "#627EEA", "#9CBBC3"],
        hoverOffset: 1,
      },
    ],
  };

  if (dataLoading || btc === null || eth === null || others === null) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-lg">
          We are processing the data of recent whale trades. <br /> In case it
          takes more time, please refresh.
        </p>
      </div>
    );
  }

  return (
    <Fade bottom>
      <div className="md:mt-24 mt-14 font-sans">
        <div className="max-w-wt 2xl:max-w-ft sm:mx-auto text-center justify-center p-0">
          <div className="mx-auto">
            <h1 className="font-black text-4xl md:text-5xl text-center text-white">
              TRACK WHALE ACTIVITY ON CEX/DEX
            </h1>
            <p className="mt-2 mb-4 md:mb-0 md:mt-6 text-md md:text-xl text-slate-300 text-center max-w-3xl mx-auto">
              {" "}
              WhaleTracer tracks single trades $50,000+ on Centralized Exchanges
              (CEX) and $100,000+ single trades on Decentralized Exchanges
              (DEX).
            </p>
          </div>
          <LogoCloud />
          {auth ? null : (
            <div className="mb-12">
              <Link to="/register">
                <button
                  type="button"
                  className="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-[#B0EEFD]"
                >
                  Become A Member
                </button>
              </Link>
              <p className="mt-2 text-slate-300">
                Unlock all filters with{" "}
                <span className="font-black">100% FREE</span> member account
              </p>
            </div>
          )}

          <div className="mx-auto md:block text-left w-90 md:w-full mb-8 md:mb-0 mt-4">
            <CexTrendingTable />
            <div className="text-center mt-8">
              <Link
                to="/trending"
                style={{ textDecoration: "none", color: "white" }}
              >
                <button
                  type="button"
                  className="inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                >
                  View More
                  <ChevronRightIcon
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-16 md:mt-24 max-w-wt 2xl:max-w-ft  mx-auto">
          <div className="">
            <h1 className="font-black text-4xl md:text-5xl text-center text-white">
              DEX Trades On Blockchain
            </h1>

            <p className="mt-2 mb-4 md:mb-0 md:mt-6 text-md md:text-xl text-slate-300 text-center max-w-3xl mx-auto">
              {" "}
              WhaleTracer DEX tracks all the big swaps that are made by Whales,
              Sharks, Dolphins, Shrimps or Bots. You can filter how you like and
              try to keep up with the Whale game. You may also find in-depth
              information about a selected trade such as its Tnx Value, Hash, &
              wallet address.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mb-16 mt-12">
            <img
              src={Etherscan}
              alt="btc"
              className="w-48 object-contain md:md:w-60 mx-auto"
            />
            <img
              src={Polygon}
              alt="btc"
              className="w-48 object-contain md:md:w-60  mx-auto"
            />
            <img
              src={Avalanche}
              alt="btc"
              className="w-48 object-contain md:md:w-60  mx-auto"
            />
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8">
            {trades.slice(0, 3).map((trade, key) => (
              <DexCard
                id={trade.id}
                hash={trade.txn_hash}
                out={trade.token_out}
                out_name={trade.symbol_out}
                in_name={trade.symbol_in}
                in={trade.token_in}
                timeToGo={timeToGoSec}
                time={trade.trade_time}
                value={trade.txn_value}
                wallet={trade.wallet}
                chain={trade.chain_name}
                category={trade.category}
              />
            ))}
          </div>

          <div className="text-center mt-8">
            <Link to="/dex-activity" style={{ textDecoration: "none" }}>
              <button
                type="button"
                className="inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
              >
                DEX Trades
                <ChevronRightIcon
                  className="ml-2 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </Link>
          </div>
        </div>
        {!auth && (
          <div className="w-screen bg-[#002B5B] p-12 mt-16">
            <div className="flex flex-col-reverse justify-center text-center max-w-wt 2xl:max-w-ft  mx-auto md:flex-row md:items-center">
              <div>
                <h2 className="font-black text-2xl md:text-4xl text-center text-white">
                  Get Email Updates: Sign up for FREE
                </h2>
                <p className="mt-2 mb-4 md:mb-0 md:mt-6 text-md md:text-xl text-slate-300  max-w-3xl mx-auto">
                  Get crypto analysis, news and updates right to your inbox!
                  Sign up for free so you don’t miss a single newsletter.
                </p>
                <div className="md:mt-4">
                  <div className="mx-auto mt-4 md:mx-0 md:mt-0">
                    <a href="/register">
                      <button
                        type="button"
                        className="bg-[#B0EEFD] px-3 py-[10px] md:mt-2 rounded text-black hover:shadow-lg w-72 md:w-100"
                      >
                        Sign Up for 100% Free
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Snackbar
          open={subscribeSuccess}
          autoHideDuration={2000}
          onClose={() => setSubscribeSuccess(false)}
        >
          <Alert
            onClose={() => setSubscribeSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            You have successfully subscribed!
          </Alert>
        </Snackbar>
        <Snackbar
          open={subscribeError}
          autoHideDuration={6000}
          onClose={() => setSubscribeError(false)}
        >
          <Alert
            onClose={() => setSubscribeError(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Subscription failed!
          </Alert>
        </Snackbar>

        <div className="flex flex-col items-center max-w-wt 2xl:max-w-ft  mx-auto md:flex-row  md:mt-20 mt-8">
          <div className="text-center mt-10 md:text-left w-5/6">
            <h1 className="font-black text-4xl md:text-5xl  text-white">
              RECENT BIG TRADES ON CEX/DEX
            </h1>

            <p className="mt-2 mb-4 md:mb-0 md:mt-6 text-md md:text-xl text-slate-300   mx-auto">
              {" "}
              Discover the Whale trends in CEX/DEX market via our analytics. We
              track mostly traded currencies by whales over popular exchanges
              and present you with a refined list of top trending and in demand
              currencies for high volume trades.
            </p>
            <div className="flex flex-col gap-y-8 md:gap-x-12 md:flex-row mb-8 mt-12">
              <div className="text-white leading-2">
                <h2 className="text-white font-black text-8xl">{cex}</h2>
                <p className="text-white text-lg">CEX TRADES IN 24H</p>
              </div>
              <div className="text-white leading-2">
                <h2 className="text-white font-black text-8xl">{dex}</h2>
                <p className="text-white text-lg">DEX TRADES IN 24H</p>
              </div>
              <div className="text-white leading-2">
                <h2 className="text-white font-black text-8xl">{btc}</h2>
                <p className="text-white text-lg">BTC TRADES IN 24H</p>
              </div>
            </div>
            <Link to="/cex-activity" style={{ textDecoration: "none" }}>
              <button
                type="button"
                className="inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
              >
                CEX Trades
                <ChevronRightIcon
                  className="ml-2 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </Link>
          </div>
          <div className="mt-4 md:w-96 md:h-96">
            <HomeChart chartData={chartData} />
          </div>
        </div>

        <div className="mt-24 max-w-wt 2xl:max-w-ft mx-auto">
          <div className="text-center mb-8">
            <h1 className="font-black text-2xl md:text-3xl  text-white">
              RECENT REVIEWS ON WHALETRACER & HOW-TO GUIDES{" "}
            </h1>
          </div>

          <div className="grid grid-cols-1 gap-6  ml-6 sm:ml-0 sm:grid-cols-2 lg:grid-cols-3 mt-8 mb-8">
            <YoutubeEmbed
              embedId="SSo_EIwHSd4"
              title="What is Blockchain?"
              subtext="Academy"
            />
            <YoutubeEmbed
              embedId="rYQgy8QDEBI"
              title="What is Crypto?"
              subtext="Education"
            />
            <YoutubeEmbed
              embedId="cdZZpaB2kDM"
              title="Elon Musk Ted Talk?"
              subtext="What's New"
            />
          </div>
        </div>

        <Footer />
      </div>
    </Fade>
  );
};

export default Home;
