/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Axios from "axios";

export default function ChangeName(props) {
  const [name, setName] = useState("");
  const [processing, setProcessing] = useState(false);

  const closePopUp = () => {
    props.toggle();
    setName("");
  };
  const changeNameBody = {
    id: props.id,
    name: name,
  };

  const changeName = async () => {
    setProcessing(true);
    await Axios.put(
      `https://api.whaletracer.com/user/changeName`,
      changeNameBody
    )
      .then((res) => {
        setProcessing(false);
        props.toggle();
      })
      .catch((err) => setProcessing(false));
  };

  return (
    <Transition.Root show={props.showChangeName} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closePopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-[#403E66] rounded-lg px-8 pt-6 pb-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <p
                  className="text-xs text-slate-300 text-right cursor-pointer"
                  onClick={closePopUp}
                >
                  Close
                </p>
                <div>
                  <h2 className="mt-6 text-center text-xl font-bold text-white">
                    Change your Name
                  </h2>
                </div>

                <div className="sm:mx-auto sm:w-full ">
                  <div className="bg-[#403E66] py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-white"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            value={name}
                            name="email"
                            type="email"
                            autoComplete="email"
                            onChange={(e) => setName(e.target.value)}
                            required
                            placeholder="Enter Your New Name..."
                            className="appearance-none block w-60 sm:w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={changeName}
                          className={
                            processing
                              ? "group relative w-full opacity-70 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                              : "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                          }
                        >
                          {processing ? "Changing the name..." : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
