import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import DisclaimerContext from "../../context/Disclaimer";

const footerNavigation = {
  products: [
    { name: "CEX Activity", href: "/cex-activity" },
    { name: "DEX Activity", href: "/dex-activity" },
    { name: "Trending", href: "/trending" },
  ],
  company: [
    { name: "About Us", href: "/about" },
    { name: "Careers", href: "#", subtext: "Coming soon!" },
    { name: "What's New", href: "#", subtext: "Coming soon!" },
  ],
  support: [
    { name: "Contact", href: "/contact" },
    { name: "Support", href: "#" },
    { name: "FAQs", href: "/pricing" },
    { name: "Sitemap", href: "/sitemap.xml" },
  ],
  legal: [
    { name: "Disclaimer", href: "#", click: true },
    { name: "Privacy Policy", href: "/privacy-policy" },
    { name: "Terms of Use", href: "/terms-of-use" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/whaletracer/",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/whaletracer/",
      icon: (props) => (
        <svg
          fill="currentColor"
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/whaletracer",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "Discord",
      href: "https://discord.gg/YbGnBgyBQ3",
      icon: (props) => (
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="discord"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path
            fill="currentColor"
            d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"
          ></path>
        </svg>
      ),
    },
  ],
};

const Footer = () => {
  const { setIsShowDisclaimer } = useContext(DisclaimerContext);

  return (
    <footer
      className="bg-gradient-to-r from-[#181f38] to-[#131726] text-white"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img src={Logo} alt="logo" className="w-36 " />

            <p className="text-warm-gray-400 text-base">
              South Media OÜ <br />
              Peterburi tee 22-12 <br />
              11411 Tallinn Estonia
            </p>
            <div className="flex space-x-6">
              {footerNavigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-warm-gray-400 hover:text-warm-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <div>
              <p className="text-[#8edafa] font-bold text-sm mb-2">
                DEVELOPED BY
              </p>
              <a
                href="https://www.linkedin.com/in/mihail-kulechov/"
                target="_blank"
                className="no-underline  font-bold"
                rel="noreferrer"
              >
                <div className="flex items-end">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-4"
                  >
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                  </svg>
                  <p className="text-sm font-medium ml-2 hover:underline hover:text-[#8edafa] hover:scale-105 hover:ease-out hover:duration-300 hover:font-black">
                    Mihail Kulechov{" "}
                  </p>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/in/alimahmood21/"
                target="_blank"
                className="no-underline  font-bold"
                rel="noreferrer"
              >
                <div className="flex items-end mt-2">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-4"
                  >
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                  </svg>
                  <p className="text-sm font-medium ml-2 hover:underline hover:text-[#8edafa] hover:scale-105 hover:ease-out hover:duration-300 hover:font-black">
                    Ali Mahmood{" "}
                  </p>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/in/husnain-mehmood/"
                target="_blank"
                className="no-underline font-bold"
                rel="noreferrer"
              >
                <div className="flex items-end mt-2">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-4"
                  >
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                  </svg>
                  <p className="text-sm font-medium ml-2 hover:underline hover:text-[#8edafa] hover:scale-105 hover:ease-out hover:duration-300 hover:font-black">
                    Husnain Mahmood{" "}
                  </p>
                </div>
              </a>
              <a
                href="mailto:contactalimahmood@gmail.com"
                target="_blank"
                className="no-underline font-bold"
                rel="noreferrer"
              >
                <div className="flex items-end mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-4"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>

                  <p className="text-sm font-medium ml-2 hover:underline hover:text-[#8edafa] hover:scale-105 hover:ease-out hover:duration-300 hover:font-black">
                    Email us{" "}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="font-bold text-xl text-[#8edafa]">Products</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.products.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-warm-gray-400 hover:text-warm-gray-300"
                      >
                        {item.name}
                      </Link>
                      {item?.subtext && (
                        <span className="text-xs text-[#8edafa]">
                          {" "}
                          {item?.subtext}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="font-bold text-xl text-[#8edafa]">Company</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-warm-gray-400 hover:text-warm-gray-300"
                      >
                        {item.name}
                      </Link>
                      {item?.subtext && (
                        <span className="text-xs text-[#8edafa]">
                          {" "}
                          {item?.subtext}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="font-bold text-xl text-[#8edafa]">Support</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) =>
                    item.name === "Sitemap" ? (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="text-base text-warm-gray-400 hover:text-warm-gray-300"
                        >
                          {item.name}
                        </a>
                      </li>
                    ) : (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-base text-warm-gray-400 hover:text-warm-gray-300"
                        >
                          {item.name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="font-bold text-xl text-[#8edafa]">Legal</h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      {item?.click ? (
                        <p
                          to={item.href}
                          className="text-base text-warm-gray-400 hover:text-warm-gray-300 cursor-pointer"
                          onClick={() => setIsShowDisclaimer(true)}
                        >
                          {item.name}
                        </p>
                      ) : (
                        <Link
                          to={item.href}
                          className="text-base text-warm-gray-400 hover:text-warm-gray-300"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-warm-gray-700 pt-8">
          <p className="text-base text-warm-gray-400 xl:text-center">
            &copy; 2022 WhaleTracer. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
