import React, { useState, useEffect } from "react";
import { TabTitle } from "../../services/GeneralFunctions";
import Footer from "../../components/Footer/Footer";
import Fade from "react-reveal/Fade";
import Axios from "axios";
import TrendingCard from "../../components/TrendingCard/TrendingCard";
import useAuth from "../../hooks/useAuth";
import useUpgrade from "../../hooks/useUpgrade";
import DexTrending from "../../components/DexTrendingTable/DexTrending";

const Trending = () => {
  TabTitle("WhaleTracer - Trending");

  const [centralized, setCentralized] = useState(true);
  const [topTrades, setTopTrades] = useState([]);
  const [filter, setFilter] = useState(1);

  const activeButtonStyle =
    "inline-flex  items-center px-4 mr-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]";
  const inactiveButtonStyle =
    "inline-flex  items-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD]";
  const disabledButton =
    "inline-flex  items-center px-4 mr-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD] opacity-50";

  useEffect(() => {
    Axios.get(
      `https://api.whaletracer.com/cex-trades/toptennew/${filter}`
    ).then((response) => {
      setTopTrades(response.data);
    });
  }, [filter]);

  let key = 0;

  const { setOpen } = useUpgrade();
  const { auth } = useAuth();

  return (
    <Fade bottom>
      <div className="mt-12 mb-12 font-sans">
        <div className="max-w-wt 2xl:max-w-ft mx-auto">
          <div className="mx-auto">
            <div className="mx-auto">
              <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white">
                {centralized ? "CEX" : "DEX"} TRENDING
              </h1>
              <p className="mb-4 md:mb-4 md:mt-0 text-sm md:text-md text-slate-300 text-center md:text-left max-w-3xl">
                {" "}
                Check out the Most Traded Coins above $50,000 over popular
                Crypto Exchanges in last 24 hours
              </p>
            </div>
          </div>
          <div className="flex flex-col text-center md:flex-row md:justify-between justify-center mb-4 md:mx-auto items-center">
            <div>
              <button
                type="button"
                className={
                  centralized ? activeButtonStyle : inactiveButtonStyle
                }
                onClick={() => setCentralized(true)}
              >
                CEX Trending
              </button>
              <button
                type="button"
                className={
                  centralized ? inactiveButtonStyle : activeButtonStyle
                }
                onClick={() => setCentralized(false)}
              >
                DEX Trending
              </button>
            </div>
            {centralized ? (
              <div className="mt-4 md:mt-0">
                <button
                  type="button"
                  className={
                    filter === 1 ? activeButtonStyle : inactiveButtonStyle
                  }
                  onClick={() => setFilter(1)}
                >
                  24H
                </button>
                {auth ? (
                  <>
                    <button
                      type="button"
                      className={
                        filter === 7 ? activeButtonStyle : inactiveButtonStyle
                      }
                      onClick={() => setFilter(7)}
                    >
                      7D
                    </button>

                    <button
                      type="button"
                      className={
                        filter === 30 ? activeButtonStyle : inactiveButtonStyle
                      }
                      onClick={() => setFilter(30)}
                    >
                      30D
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={disabledButton}
                      onClick={() => setOpen(true)}
                    >
                      7D
                    </button>

                    <button
                      type="button"
                      className={disabledButton}
                      onClick={() => setOpen(true)}
                    >
                      30D
                    </button>
                  </>
                )}
              </div>
            ) : null}
          </div>
          {centralized ? (
            <div className="grid grid-cols-md lg:grid-cols-md 2xl:grid-cols-xl gap-x-6 mt-8">
              {topTrades.map((trade) => {
                key++;
                return (
                  <TrendingCard
                    key={key}
                    symbol={trade.base_asset_trade}
                    logo={trade.base_asset_icon}
                    total={trade.total_trades}
                    sold={trade.sold}
                    bought={trade.bought}
                    soldValue={Math.round(trade.sold_trade_value)}
                    boughtValue={Math.round(trade.bought_trade_value)}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
        {!centralized ? (
          <div className="mx-auto md:block max-w-wt 2xl:max-w-ft mb-8 md:mb-0 mt-8">
            <DexTrending />
          </div>
        ) : null}
      </div>

      <Footer />
    </Fade>
  );
};

export default Trending;
