import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "./FiltersPopup.css";

import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  color: "white",
  transform: "translate(-50%, -50%)",
  width: "22rem",
  bgcolor: "#2A375C",
  border: "2px solid #2A375C",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

const buttonStyle = {
  backgroundColor: "#B0EEFD",
  borderRadius: "10px",
  color: "black",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#B0EEFD",
  },
};

const FiltersPopup = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="hidden md:block"
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div>
              <div className="flex flex-row justify-between items-center">
                <p className="text-white text-md font-extrabold">FILTERS</p>
                <p className="close-button" onClick={props.handleOpen}>
                  Close
                </p>
              </div>
            </div>
            <div className="trade-types">
              <p className="trade-types-heading">Trade Types</p>
              <div className="mt-4">
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">All Trades</p>
                  <Checkbox
                    checked={!props.sold && !props.bought}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                    onClick={props.toggleAll}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Sold</p>
                  <Checkbox
                    checked={props.sold}
                    onClick={props.toggleSold}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="trade-types-entry-content">Bought</p>
                  <Checkbox
                    checked={props.bought}
                    onClick={props.toggleBought}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="mt-4">
                <p className="trade-types-heading">Exclude BTC/ETH</p>
              </div>
              <div className="trade-types-container">
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">Exclude BTC</p>
                  <Checkbox
                    checked={props.excludeBTC === null ? false : true}
                    onClick={props.toggleExcludeBTC}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">Exclude ETH</p>
                  <Checkbox
                    checked={props.excludeETH === null ? false : true}
                    onClick={props.toggleExcludeETH}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="trade-types">
              <div className="trade-types-heading-container">
                <p className="trade-types-heading">Trade Value</p>
              </div>
              <div className="mt-4">
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">$50,000 and above</p>
                  <Checkbox
                    checked={props.tradeValue === 1 ? true : false}
                    onClick={() => props.changeTradeValue(1)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="text-sm text-slate-200">$100,000 and above</p>
                  <Checkbox
                    checked={props.tradeValue === 2 ? true : false}
                    onClick={() => props.changeTradeValue(2)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="text-sm text-slate-200">$300,000 and above</p>
                  <Checkbox
                    checked={props.tradeValue === 6 ? true : false}
                    onClick={() => props.changeTradeValue(6)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>

                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">
                    $500,000 and above
                  </p>
                  <Checkbox
                    checked={props.tradeValue === 10 ? true : false}
                    onClick={() => props.changeTradeValue(10)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
                <div className="trade-types-entry">
                  <p className="trade-types-entry-content">
                    $1 Million and above
                  </p>
                  <Checkbox
                    checked={props.tradeValue === 20 ? true : false}
                    onClick={() => props.changeTradeValue(20)}
                    size="small"
                    sx={{
                      color: "#8EDAFA",
                      "&.Mui-checked": {
                        color: "#8EDAFA",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="apply-button">
              <Button
                variant="contained"
                sx={buttonStyle}
                onClick={props.toggleApply}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* MOBILE VERISON */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="md:hidden"
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div style={{ lineHeight: "0px" }}>
              <div className="flex flex-row justify-between">
                <p className="heading-main-text">FILTERS</p>
                <p className="close-button" onClick={props.handleOpen}>
                  Close
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-6">
              <div className="">
                <p className="text-md text-[#8edafa] font-bold">Trade Types</p>
                <div className="">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">All Trades</p>
                    <Checkbox
                      checked={!props.sold && !props.bought}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                      onClick={props.toggleAll}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">Sold</p>
                    <Checkbox
                      checked={props.sold}
                      onClick={props.toggleSold}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">Bought</p>
                    <Checkbox
                      checked={props.bought}
                      onClick={props.toggleBought}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <p className="text-md text-[#8edafa] font-bold">
                  Exclude BTC/ETH
                </p>

                <div className="trade-types-container">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">Exclude BTC</p>
                    <Checkbox
                      checked={props.excludeBTC === null ? false : true}
                      onClick={props.toggleExcludeBTC}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">Exclude ETH</p>
                    <Checkbox
                      checked={props.excludeETH === null ? false : true}
                      onClick={props.toggleExcludeETH}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-6">
              <div className="trade-types">
                <p className="text-md text-[#8edafa] font-bold">Time</p>
                <div className="trade-types-container">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">1 hour</p>
                    <Checkbox
                      checked={props.filter === 1 ? true : false}
                      onClick={() => props.setFilterValue(1)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">6 hour</p>
                    <Checkbox
                      checked={props.filter === 6 ? true : false}
                      onClick={() => props.setFilterValue(6)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">12 hour</p>
                    <Checkbox
                      checked={props.filter === 12 ? true : false}
                      onClick={() => props.setFilterValue(12)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">24 hour</p>
                    <Checkbox
                      checked={props.filter === 24 ? true : false}
                      onClick={() => props.setFilterValue(24)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="trade-types">
                <p className="text-md text-[#8edafa] font-bold">Trade Value</p>
                <div className="trade-types-container">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">$50,000 &gt;</p>
                    <Checkbox
                      checked={props.tradeValue === 1 ? true : false}
                      onClick={() => props.changeTradeValue(1)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">$100,000 &gt;</p>
                    <Checkbox
                      checked={props.tradeValue === 2 ? true : false}
                      onClick={() => props.changeTradeValue(2)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">$300,000 &gt;</p>
                    <Checkbox
                      checked={props.tradeValue === 6 ? true : false}
                      onClick={() => props.changeTradeValue(6)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">$500,000 &gt;</p>
                    <Checkbox
                      checked={props.tradeValue === 10 ? true : false}
                      onClick={() => props.changeTradeValue(10)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm text-slate-200">$1,000,000 &gt; </p>
                    <Checkbox
                      checked={props.tradeValue === 20 ? true : false}
                      onClick={() => props.changeTradeValue(20)}
                      size="small"
                      sx={{
                        color: "#8EDAFA",
                        "&.Mui-checked": {
                          color: "#8EDAFA",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="apply-button">
              <Button
                variant="contained"
                sx={buttonStyle}
                onClick={props.toggleApply}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FiltersPopup;
