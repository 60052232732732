/* This example requires Tailwind CSS v2.0+ */
export default function LogoCloud() {
  return (
    <div className="mx-auto mb-4">
      <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4 items-center">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-12"
              src="https://i.stack.imgur.com/twqvL.png"
              alt="Tuple"
            />
          </div>

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-12"
              src="https://i.stack.imgur.com/RigAJ.png"
              alt="StaticKit"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img
              className="h-10"
              src="https://i.stack.imgur.com/YyHeb.png"
              alt="Transistor"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img
              className="h-10"
              src="https://i.stack.imgur.com/NaoNC.png"
              alt="Transistor"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
