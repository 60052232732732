const faqs = [
  [
    {
      question: "What is Whale Tracer?",
      answer:
        "WhaleTracer is a free tool that tracks popular cryptocurrency exchanges (DEX + CEX) single big trading activity. ",
    },
    {
      question: "What kind of trades are registered?",
      answer:
        "On CEX exchanges we track $50.000+ trades. On DEX exchanges we track $100.000$+ trades.",
    },

    {
      question: "Where these trades data come from?",
      answer:
        "WhaleTracer collects all trades data from exchanges like Binance, FTX, Coinbase, OKX, Etherscan & more to apply trade value filters and present you with the Whale Trades according to timestamp and trade direction.",
    },
    {
      question: "What is Whale Trending?",
      answer:
        "WhaleTracer gathers whale trades from popular exchanges and calculates the mostly traded cryptos over the period to show you how VCs and Whales are using smart money so you can see the trends as they are happening not on social media.",
    },
  ],
  [
    {
      question: "What is DEX Trade Data?",
      answer:
        "WhaleTracer collects DEX swaps and trades data from popular chains like Etherscan, Polygon & Avalanche and filter them them based on Tnx value and wallet type so we can present DEX trades above $100,000 and show you which trades are done by Whale Accounts and which transactions are done by bots.",
    },
    {
      question: "How to benefit from WhaleTracer?",
      answer:
        "Usually big trades are executed by VC's. They are those who had bought Solana or Avalanche under 1$ but sold over 100$. We help to track those kind of hidden gems that might be bought by VC's. Also this helps to get better idea where the smart money is going right now.",
    },
    {
      question: "Why use paid subscription?",
      answer:
        "With paid subscription you can use all our DEX/CEX filters. For example exclude BTC/ETH to get better idea of hidden gems. Filter by trade values & get a longer time frame that helps evaluate of how whales are currently acting on the current market. In DEX activity you can get TX/Wallet etc info.",
    },

    {
      question: "Is there any age limit to trading on Pocket?",
      answer:
        "For our free plan, the age limit is based on the minimum age to trade in your country of residence. Our VIP plan uses advanced transaction anonymization though, so you can use that plan even if you’re 9 years old. Or a dog.",
    },
  ],
  [
    {
      question: "Shall I invest in the cryptos trending on WhaleTracer?",
      answer:
        "WhaleTracer does not tell you where to invest and how to make use of your portfolio. Any and all data provided here is just for the understanding and information purpose only which is for the experts to get better insights. We do not recommend anyone investing into any currency just based on information provided here or consider any of this information as investment signal. You should always make your own research, review, analyse and verify our content before relying on them. Trading is a highly risky activity that can lead to major losses, please therefore consult your financial advisor before making any decisions.",
    },
    {
      question: "What are DEX Wallets Types?",
      answer:
        "There are different wallet types based on transaction value and frequency per month which distinguish between Whales, Sharks, Dolphin & Bot wallets.",
      subheadings: [
        {
          heading: "WHALE",
          info: "Whale has over $500,000+ trading volume in the last 30 days",
        },
        {
          heading: "SHARK",
          info: "Shark has $100,000-$500,000 of a trading volume in the last 30 days",
        },
        {
          heading: "DOLPHIN",
          info: "Dolphin is casual trader with $10,000-$100,000 of a trading volume in the last 30 days",
        },
        {
          heading: "BOT",
          info: "Trader with 1000+ TXs in last 30 days. Probably bot.",
        },
      ],
    },
  ],
];

export function Faqs() {
  return (
    <section id="faqs" aria-labelledby="faqs-title" className="py-20 sm:py-32">
      <div className="max-w-wt 2xl:max-w-ft mx-auto">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-white"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-300">
            If you have anything else you want to ask,{" "}
            <span>reach out to us</span>.
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-white">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-300 mb-2">
                      {faq.answer}
                    </p>
                    {faq.subheadings?.map((subheading, subIndex) => (
                      <p
                        key={subIndex}
                        className="font-bold text-sm text-gray-300"
                      >
                        {subheading.heading}:
                        <span className="font-medium text-sm text-gray-300">
                          {" "}
                          {subheading.info}
                        </span>
                      </p>
                    ))}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
