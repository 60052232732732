import React, { useState, useEffect } from "react";

import Axios from "axios";
import Footer from "../../components/Footer/Footer";
import Logo from "../../assets/smallLogo.png";
import Fade from "react-reveal/Fade";
import ExchangeCard from "../../components/ExchangeCard/ExchangeCard";

const Exchanges = () => {
  const [exchanges, setExchanges] = useState(null);
  const [bybitData, setBybitData, setFtx] = useState(null);
  const [binanceData, setBinance] = useState(null);
  const [coinbaseData, setCoinbase] = useState(null);
  const [loading, setLoading] = useState(true);
  ///trending/bought-sell/:exchange
  useEffect(() => {
    Axios.get(`https://api.whaletracer.com/exchanges/trending`).then(
      (response) => {
        setExchanges(response.data);
      }
    );
    Axios.get(
      `https://api.whaletracer.com/exchanges/trending/bought-sell/BYBIT`
    ).then((response) => {
      setBybitData(response.data);
    });
    Axios.get(
      `https://api.whaletracer.com/exchanges/trending/bought-sell/Binance`
    ).then((response) => {
      setBinance(response.data);
    });
    Axios.get(
      `https://api.whaletracer.com/exchanges/trending/bought-sell/Coinbase`
    ).then((response) => {
      setCoinbase(response.data);
    });
    setLoading(false);
  }, []);

  if (
    loading ||
    exchanges === null ||
    bybitData === null ||
    binanceData === null ||
    coinbaseData === null
  ) {
    return (
      <img src={Logo} className="w-36 mx-auto my-48 2xl:my-64" alt="loading" />
    );
  }

  return (
    <Fade bottom>
      <div className="text-white max-w-wt 2xl:max-w-ft mx-auto my-12 font-sans">
        <div className="mx-auto xl:w-[1100px] 2xl:w-[1250px]">
          <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white">
            EXCHANGE RANKING BY WHALES ACTIVITY
          </h1>
          <p className="mb-4 md:mb-4 md:mt-0 text-md md:text-xl text-slate-300 text-center md:text-left">
            {" "}
            Exchanges are ranked by the most whale trades above value $50,000 in
            the last week.
          </p>
        </div>
        <div className="grid grid-cols-lg lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 2xl:gap-x-4 mt-4 gap-2  mx-auto">
          <ExchangeCard
            total={binanceData[0].total_trades}
            bought={binanceData[0].bought}
            sold={binanceData[0].sold}
            logo="https://cdn.cryptoprijzen.com/wp-content/uploads/binance-review.png"
            text="Binance is a cryptocurrency exchange which is the largest exchange in the world in terms of daily trading volume of cryptocurrencies."
            rank="2"
            exchange="https://accounts.binance.com/en/register?ref=16771072"
          />
          <ExchangeCard
            total={bybitData[0].total_trades}
            bought={bybitData[0].bought}
            sold={bybitData[0].sold}
            logo="https://cryptowisdom.com.au/wp-content/uploads/2022/01/Bybit-colored-logo.png"
            text="Bybit is a cryptocurrency exchange established in March 2018 to offer a professional platform where crypto traders can find an ultra-fast matching engine, excellent customer service and multilingual community support."
            rank="2"
            exchange="https://www.bybit.com/en-US/"
          />
          <ExchangeCard
            total={coinbaseData[0].total_trades}
            bought={coinbaseData[0].bought}
            sold={coinbaseData[0].sold}
            logo="https://upload.wikimedia.org/wikipedia/commons/c/c7/Coinbase_Logo_2013.png"
            text="Coinbase is a secure online platform for buying, selling, transferring, and storing cryptocurrency."
            rank="3"
            exchange="https://www.coinbase.com/join/kulech_h"
          />
        </div>
      </div>

      <Footer />
    </Fade>
  );
};

export default Exchanges;
