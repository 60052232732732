import React, { useEffect, useState } from "react";
import Stats from "./components/stats/Stats";
import Table from "./components/table/Table";
import TableSub from "./components/table/TableSub";
import Axios from "axios";
import Logo from "../../assets/smallLogo.png";
import Footer from "../../components/Footer/Footer";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [totalTrades, setTotalTrades] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(true);

  const [deleted, setDeleted] = useState(false);

  const toggleDeleted = () => {
    setDeleted((deleted) => !deleted);
  };

  const toggleDisplay = (val) => {
    setShowUsers(val);
  };

  useEffect(() => {
    setLoading(true);
    Axios.get("https://api.whaletracer.com/user/getUsers").then((res) => {
      setUsers(res.data.data);
      setUserCount(res.data.count);
    });
    Axios.get("https://api.whaletracer.com/user/getSubscribers").then((res) => {
      setSubscribers(res.data.data);
      setSubscribersCount(res.data.count);
    });
    Axios.get("https://api.whaletracer.com/cex-trades/total-trades-cex").then(
      (response) => {
        setTotalTrades(response.data.count);
      }
    );
    setLoading(false);
  }, [deleted]);

  if (loading) {
    return (
      <img src={Logo} className="w-36 mx-auto my-48 2xl:my-64" alt="loading" />
    );
  }

  return (
    <>
      <div className="mt-12 max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] mb-12 font-sans">
        <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white mb-8">
          Admin Dashboard
        </h1>
        <Stats
          users={userCount}
          cex={totalTrades}
          subscribers={subscribersCount}
          toggle={toggleDisplay}
        />
        <h1 className="font-black text-xl md:text-2xl text-center md:text-left text-white mt-12 mb-2">
          All {showUsers ? "Users" : "Subscribers"}
        </h1>
        {showUsers && <Table data={users} toggleDelete={toggleDeleted} />}
        {!showUsers && (
          <TableSub data={subscribers} toggleDelete={toggleDeleted} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Admin;
