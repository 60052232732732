/* This example requires Tailwind CSS v2.0+ */

import Email from "../../images/Email.png";
import Trade from "../../images/Trades.png";
import User from "../../images/User.png";

export default function Stats(props) {
  const stats = [
    {
      id: 1,
      name: "Total Users",
      stat: props.users,
      icon: User,
      changeType: "increase",
      toggle: true,
    },
    {
      id: 2,
      name: "Total Subscribers",
      stat: props.subscribers,
      icon: Email,
      changeType: "increase",
      toggle: false,
    },

    {
      id: 3,
      name: "Whale Trades Today",
      stat: props.cex,
      icon: Trade,
      changeType: "decrease",
      toggle: "trade",
    },
  ];
  return (
    <div>
      <p className="text-white text-lg font-bold mb-2">Stats</p>
      <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-[#292644] px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <img src={item.icon} className="h-8 w-8 text-white" alt="img" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-slate-300">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-white">{item.stat}</p>

              <div className="absolute inset-x-0 bottom-0 bg-[#1B2137] px-4 py-4 sm:px-6">
                <div className="text-sm">
                  {item.toggle === "trade" ? (
                    <a
                      href="/cex-activity"
                      className="font-medium text-[#B0EEFD] cursor-pointer"
                    >
                      {" "}
                      View all
                    </a>
                  ) : (
                    <span
                      className="font-medium text-[#B0EEFD] cursor-pointer"
                      onClick={() => props.toggle(item.toggle)}
                    >
                      {" "}
                      View all
                    </span>
                  )}
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
