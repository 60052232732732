import React, { useState, useEffect, useRef } from "react";
import { TabTitle, timeToGoSec } from "../../services/GeneralFunctions";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Logo from "../../assets/smallLogo.png";
import Footer from "../../components/Footer/Footer";
import { ChevronDownIcon } from "@heroicons/react/solid";
import useUpgrade from "../../hooks/useUpgrade";
import { FilterIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import DexCard from "../../components/DexCard/DexCard";
import DexFilter from "../../components/DexFilter/DexFilter";
import DexCategoryPopup from "../../components/DexCategoryPopup/DexCategoryPopup";
import { initGA, PageView } from "../../components/Tracking/Tracking";
import FAQ from "../Alerts/FAQ";

import useAuth from "../../hooks/useAuth";

const activeExchange = "w-12 p-2 rounded-md bg-[#8edafa] md:hidden";
const inactiveExchange =
  "w-12 p-2 rounded-md bg-[#8edafa] opacity-50 md:hidden";

const Dex = () => {
  TabTitle("WhaleTracer - DEX Trades");

  const [filter, setFilter] = useState("1");
  const [trades, setTrades] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [cardsQty, setCardsQty] = useState(30);

  const [filterDisplay, setFilterDisplay] = useState(false);
  const [category, setCategory] = useState(null);
  const [txnValue, setTxnValue] = useState(1);
  const [applyFilter, setApplyFilter] = useState(false);

  let containerRef = useRef();

  const [categoryDisplay, setCategoryDisplay] = useState(false);

  const { auth } = useAuth();
  const { setOpen } = useUpgrade();

  const toggleApply = () => {
    setApplyFilter(!applyFilter);
    setFilterDisplay(!filterDisplay);
  };

  const toggleCategory = (cat) => {
    setCategory(cat);
  };

  const setTXNValue = (val) => {
    setTxnValue(val);
  };

  const toggleFilterDisplay = () => {
    setFilterDisplay(!filterDisplay);
  };
  const toggleCategoryDisplay = () => {
    setCategoryDisplay(!categoryDisplay);
  };

  const switchPopUp = () => {
    setFilterDisplay(!filterDisplay);
    setCategoryDisplay(!categoryDisplay);
  };

  const [tableView, setTableView] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    initGA("G-BSD4BYCSEQ");
    PageView();
    axios
      .get(
        `https://api.whaletracer.com/dex-trades/100/${filter}/${txnValue}/${category}`
      )
      .then((res) => {
        setTrades(res.data);
      });
    setDataLoading(false);
  }, [filter, applyFilter]);

  const priceLimit = (val) => {
    if (filter === "1") {
      return "$100,000";
    } else {
      return "$50,000";
    }
  };

  const loadTrades = () => setCardsQty((prevQuantity) => (prevQuantity += 30));

  // const twitterHref = (txnValue, tokenOut, tokenIn, chain, outSym, inSym) => {
  //   return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (DEX)
  //   %0ATxn Value $${millify(txnValue)} swaped on ${chain}
  //   %0AToken Out: $${tokenOut} $${outSym}
  //   %0AToken In: $${tokenIn} $${inSym}
  //   %0ACheck Out More: whaletracer.com
  //   %0A %23${outSym} %23${inSym}  %23${chain.toUpperCase()} %23WHALETRACER`;
  // };

  const activeButtonStyle =
    "inline-flex  items-center px-4 mr-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]";
  const inactiveButtonStyle =
    "inline-flex  items-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD]";
  const disabledButton =
    "inline-flex  items-center px-4 mr-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD] opacity-50";

  if (dataLoading || trades.length === 0) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-lg">
          Loading trades data...
        </p>
      </div>
    );
  }

  return (
    <Fade bottom>
      <DexFilter
        toggleFilterDisplay={toggleFilterDisplay}
        filterDisplay={filterDisplay}
        toggleCategory={toggleCategory}
        setTXNValue={setTXNValue}
        category={category}
        txnValue={txnValue}
        toggleApply={toggleApply}
        switchPopUp={switchPopUp}
      />
      <DexCategoryPopup
        toggleCategoryDisplay={toggleCategoryDisplay}
        categoryDisplay={categoryDisplay}
        switchPopUp={switchPopUp}
      />
      <div className="mt-12 font-sans">
        <div className="text-white max-w-wt 2xl:max-w-ft mx-auto">
          <div className="">
            <h1 className="font-black text-3xl md:text-4xl text-center md:text-left text-white">
              DEX ACTIVITY TRACKING
            </h1>
            <p className="mb-4 md:mb-4 md:mt-0 text-sm md:text-md text-slate-300 text-center md:text-left max-w-3xl">
              {" "}
              We are tracking token activity from popular Decentralised
              Exchanges with value greater than {priceLimit(filter)}
            </p>

            <div className="flex flex-row justify-between">
              <div className="mx-auto md:mx-0 flex flex-row gap-x-4 md:gap-x-0">
                <div className="hidden md:block">
                  <button
                    type="button"
                    className={
                      filter === "1" ? activeButtonStyle : inactiveButtonStyle
                    }
                    onClick={() => {
                      setFilter("1");
                    }}
                  >
                    Ethereum
                  </button>
                </div>
                <img
                  src="https://etherscan.io/images/brandassets/etherscan-logo-circle.png"
                  alt="binance"
                  className={filter === "1" ? activeExchange : inactiveExchange}
                  onClick={() => setFilter("1")}
                />

                <div className="hidden md:block">
                  <button
                    type="button"
                    className={
                      filter === "137" ? activeButtonStyle : inactiveButtonStyle
                    }
                    onClick={() => {
                      setFilter("137");
                    }}
                  >
                    Polygon
                  </button>
                </div>
                <img
                  src="https://seeklogo.com/images/P/polygon-matic-logo-86F4D6D773-seeklogo.com.png"
                  alt="binance"
                  className={
                    filter === "137" ? activeExchange : inactiveExchange
                  }
                  onClick={() => setFilter("137")}
                />

                <div className="hidden md:block">
                  <button
                    type="button"
                    className={
                      filter === "43114"
                        ? activeButtonStyle
                        : inactiveButtonStyle
                    }
                    onClick={() => {
                      setFilter("43114");
                    }}
                  >
                    Avalanche
                  </button>
                </div>
                <img
                  src="https://cryptologos.cc/logos/avalanche-avax-logo.png"
                  alt="binance"
                  className={
                    filter === "43114" ? activeExchange : inactiveExchange
                  }
                  onClick={() => setFilter("43114")}
                />
                <img
                  src="https://i.stack.imgur.com/8PDWz.png"
                  alt="binance"
                  onClick={
                    auth ? () => setFilterDisplay(true) : () => setOpen(true)
                  }
                  className={
                    auth
                      ? "w-12 p-2 rounded-md bg-[#273457] md:hidden"
                      : "w-12 p-2 rounded-md bg-[#273457] md:hidden opacity-70"
                  }
                />
              </div>

              <div className="hidden md:flex md:gap-x-2">
                <button
                  type="button"
                  onClick={
                    auth ? () => setFilterDisplay(true) : () => setOpen(true)
                  }
                  className={auth ? activeButtonStyle : disabledButton}
                >
                  <FilterIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Filters
                </button>
              </div>
            </div>
          </div>
          {tableView ? (
            <></>
          ) : (
            <div>
              {auth && (
                <div
                  className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
                  id="tradeCards"
                >
                  {trades.slice(0, cardsQty).map((trade) => (
                    <DexCard
                      id={trade.id}
                      hash={trade.txn_hash}
                      out={trade.token_out}
                      out_name={trade.symbol_out}
                      in_name={trade.symbol_in}
                      in={trade.token_in}
                      timeToGo={timeToGoSec}
                      time={trade.trade_time}
                      value={trade.txn_value}
                      wallet={trade.wallet}
                      chain={trade.chain_name}
                      category={trade.category}
                    />
                  ))}
                </div>
              )}
              {!auth && (
                <div
                  ref={containerRef}
                  className="relative grid h-[70rem] max-h-[150vh] overflow-hidden"
                >
                  <div
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
                    id="tradeCards"
                  >
                    {trades.slice(0, 12).map((trade) => (
                      <DexCard
                        id={trade.id}
                        hash={trade.txn_hash}
                        out={trade.token_out}
                        out_name={trade.symbol_out}
                        in_name={trade.symbol_in}
                        in={trade.token_in}
                        timeToGo={timeToGoSec}
                        time={trade.trade_time}
                        value={trade.txn_value}
                        wallet={trade.wallet}
                        chain={trade.chain_name}
                        category={trade.category}
                      />
                    ))}
                  </div>
                  <div className="pointer-events-none absolute inset-x-0 bottom-0 h-60 bg-gradient-to-t from-[#141724]" />
                </div>
              )}
            </div>
          )}
          {auth && (
            <div className="text-center mt-20">
              {cardsQty >= trades.length ? (
                <p className="text-lg text-slate-300 dark:text-slate-300 text-center md:text-center ">
                  Sorry, there are no more trades to show. Keep checking for
                  whale acitivity.{" "}
                </p>
              ) : (
                <button
                  type="button"
                  className="inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                  onClick={loadTrades}
                >
                  View More
                  <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          )}
          {!auth && (
            <div className="text-center mt-20 ">
              {" "}
              <Link to="/register">
                <button className="bg-[#6DCDF5] mt-2 px-4 py-2 rounded text-xs font-bold text-black">
                  Become a Member
                </button>
              </Link>
              <p className="text-md mt-2 text-slate-300 dark:text-slate-300 text-center md:text-center ">
                Unlock all filters with{" "}
                <span className="font-black">100% FREE</span> member account{" "}
              </p>{" "}
            </div>
          )}
          <FAQ />
        </div>
      </div>
      <Footer />
    </Fade>
  );
};

export default Dex;
