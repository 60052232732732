import React from "react";

import { TabTitle } from "../../services/GeneralFunctions";

import "./Cryptocurrencies.css";

const Cryptocurrencies = () => {
  TabTitle("WhaleTracer - Currencies");

  // const coinsRef = firebase
  //   .firestore()
  //   .collection("coins")
  //   .orderBy("market_cap_rank", "asc")
  //   .where("market_cap_rank", "!=", null)
  //   .limit(100);

  // useEffect(() => {
  //   coinsRef.get().then((collections) => {
  //     const tradesData = collections.docs.map((trade) => trade.data());
  //     const lastDoc = collections.docs[collections.docs.length - 1];
  //     setCoins(tradesData);
  //     setLastCoins(lastDoc);
  //     setLoading(false);
  //   });
  // }, []);

  // const fetchMore = () => {
  //   coinsRef
  //     .startAfter(lastCoins)
  //     .get()
  //     .then((collections) => {
  //       const coinsData = collections.docs.map((trade) => trade.data());
  //       const lastDoc = collections.docs[collections.docs.length - 1];
  //       const firstDoc = collections.docs[collections.docs.length - 100];
  //       setCoins(coinsData);
  //       setLastCoins(lastDoc);
  //       setFirstCoin(firstDoc);
  //       setPage(page + 1);
  //     });
  // };

  // const fetchPrevious = () => {
  //   coinsRef
  //     .endBefore(firstCoin)
  //     .limitToLast(100)
  //     .get()
  //     .then((collections) => {
  //       const coinsData = collections.docs.map((trade) => trade.data());
  //       const lastDoc = collections.docs[collections.docs.length - 1];
  //       const firstDoc = collections.docs[collections.docs.length - 100];
  //       console.log("PRESSED");
  //       setCoins(coinsData);
  //       setLastCoins(lastDoc);
  //       setFirstCoin(firstDoc);
  //       setPage(page - 1);
  //     });
  // };

  // const rowPerPage = 100;
  // const [pageNumber, setPageNumber] = useState(0);
  // const pageCount = coins.length / rowPerPage;
  // const pagesVisited = pageNumber * rowPerPage;
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };

  // var searchedData = coins.filter(
  //   (coin) =>
  //     coin.name.toLowerCase().includes(search.toLowerCase()) ||
  //     coin.symbol
  //       .toLowerCase()
  //       .includes(search.toLowerCase().replace(/\s/g, ""))
  // );

  // let totalMarket = 0;
  // coins?.map((coin) => (totalMarket += coin.market_cap));

  // if (loading) {
  //   return <h1 className="loading"> Loading... </h1>;
  // }

  return (
    <div className="home">
      <div className="heading">
        <div>
          <h1>Cryptocurrencies Prices by Market Cap</h1>
          <p>Cyrptocurrencies will be available shortly!</p>
        </div>
        {/* <input
          placeholder="Search Coins by name or symbol..."
          className="search-table"
          onChange={(e) => setSearch(e.target.value)}
        /> */}
      </div>

      {/* <div style={{ width: "100%", overflow: "auto", marginTop: "20px" }}>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Symbol</th>
              <th>Price</th>
              <th>24h price change</th>
              <th>Market Cap</th>
              <th>More Info</th>
            </tr>
          </thead>
          <tbody>
            {searchedData.map((coin) => (
              <tr>
                <td>{coin.market_cap_rank}</td>
                <td style={{ display: "flex", marginTop: "5px" }}>
                  <img
                    src={coin.image}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "13px",
                      marginRight: "5px",
                    }}
                    alt="btc"
                  />
                  <p className="coin-name">{coin.name}</p>
                </td>
                <td>{coin.symbol.toUpperCase()}</td>
                <td style={{ fontWeight: "500" }}>${coin.current_price}</td>

                <td
                  style={{
                    color:
                      coin.price_change_percentage_24h < 0
                        ? "#D9534F"
                        : "#95CD41",
                  }}
                >
                  {Math.round(coin.price_change_percentage_24h * 10000) / 10000}
                  %
                </td>

                <td>${millify(coin.market_cap)}</td>
                <td>
                  <div className="more-info">More Info</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <div className="next-btn" onClick={page > 1 ? fetchPrevious : null}>
            Previous
          </div>
          <div className="next-btn" onClick={fetchMore}>
            Next
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Cryptocurrencies;
