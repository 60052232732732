import React from "react";
import "./DexCard.css";
import millify from "millify";
import { Link } from "react-router-dom";

import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";

const DexCard = (props) => {
  const chainIcon = (val) => {
    if (val === "uniswap_v3" || val === "uniswap") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/UniSwap-Icon.png";
    }
    if (val === "sushiswap") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/SushiSwap-Icon.png";
    }
    if (val === "ddex") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/DDEX-Icon.png";
    } else {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/WT-DEX-Icon.png";
    }
  };
  const categoryStyle = (type) => {
    if (type === "Whale") {
      return { color: "#41BE85", fontWeight: "900" };
    }
    if (type === "medium") {
      return { color: "#B0EEFD", fontWeight: "900" };
    }
    if (type === "noob") {
      return { color: "white", fontWeight: "900" };
    }
    if (type === "casual") {
      return { color: "#B0EEFD", fontWeight: "900" };
    }
    if (type === "bot") {
      return { color: "#EB4068", fontWeight: "900" };
    }
    if (type === null) {
      return { color: "white", fontWeight: "900" };
    }
  };

  const categoryName = (type) => {
    if (type === "Whale") {
      return "Whale";
    }
    if (type === "medium") {
      return "Shark";
    }
    if (type === "noob") {
      return "Shrimp";
    }
    if (type === "casual") {
      return "Dolphin";
    }
    if (type === "bot") {
      return "Bot";
    }
    if (type === null) {
      return { color: "white", fontWeight: "900" };
    }
  };

  const dexDetail = `/dex-activity/dex-trade/${props.id}/${props.wallet}`;

  return (
    <div className="bg-[#292644] p-4 rounded-xl border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-104 w-92 mx-auto md:mx-0 text-white">
      <div className="flex justify-between leading-4">
        <div className="">
          <Link
            to={dexDetail}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div
              className="flex items-center justify-between"
              onClick={() =>
                Event(
                  "DEX Detail",
                  "DEX detail page was clicked",
                  "DEX detail page link"
                )
              }
            >
              <div className="flex items-center">
                <img
                  src={chainIcon(props.chain)}
                  alt="ex-logo"
                  className="w-8 object-contain"
                />
                <p className="ml-2 font-bold"> {props.chain}</p>
              </div>
            </div>
          </Link>
          <div className="">
            <div className="text-sm mt-4">
              <p>
                Sold:{" "}
                <span className="font-bold">
                  {" "}
                  {Math.round(props.in * 1000) / 1000} {props.in_name}
                </span>
              </p>
              <p>
                Bought:{" "}
                <span className="font-bold">
                  {" "}
                  {Math.round(props.out * 1000) / 1000} {props.out_name}
                </span>
              </p>
              <p>
                Wallet Type:{" "}
                <span
                  className="font-bold"
                  style={categoryStyle(props.category)}
                >
                  {" "}
                  {categoryName(props.category)}
                </span>
              </p>
              <p className="">
                Txn Value:{" "}
                <span className="font-bold"> ${millify(props.value)}</span>
              </p>
              <p>
                Txn Hash:{" "}
                <span className="font-bold">
                  {" "}
                  {props.hash.slice(0, 5) +
                    "..." +
                    props.hash.slice(props.hash.length - 5, props.hash.length)}
                </span>
              </p>
              <p>
                Wallet:{" "}
                <span className="font-bold">
                  {" "}
                  {props.wallet.slice(0, 5) +
                    "..." +
                    props.wallet.slice(
                      props.wallet.length - 5,
                      props.wallet.length
                    )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col ga-y-4 mt-4">
          <p className="text-sm text-slate-200 text-center mx-auto mb-2">
            {props.timeToGo(props.time)}
          </p>
          <Button variant="contained" startIcon={<TwitterIcon />}>
            Share
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DexCard;
