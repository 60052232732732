import { createContext, useState } from "react";

const UpgradeContext = createContext({});

export const UpgradeProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <UpgradeContext.Provider value={{ open, setOpen }}>
      {children}
    </UpgradeContext.Provider>
  );
};

export default UpgradeContext;
