import React from "react";
import HomeChart from "../HomeChart/HomeChart";

const ExchangeCard = (props) => {
  const chartData = {
    labels: ["Bought", "Sold"],
    datasets: [
      {
        label: "bought and Sold",
        data: [props.bought, props.sold],
        backgroundColor: ["#41BE85", "#EB4068"],
        borderColor: ["#41BE85", "#EB4068"],
        hoverBackgroundColor: ["#41BE85", "#EB4068"],
        hoverBorderColor: ["#41BE85", "#EB4068"],
        hoverOffset: 1,
      },
    ],
  };

  return (
    <div className="bg-[#292644] p-4 rounded-xl border-8 border-[#403e66] shadow-2xl md:w-90 2xl:w-92 w-95  text-white">
      <a
        href={props.exchange}
        target="_blank"
        className="no-underline text-[#8edafa] font-bold"
        rel="noreferrer"
      >
        <img
          src={props.logo}
          alt="ftx-logo"
          className="w-48 object-contain mx-auto"
        />
      </a>
      <p className="text-slate-400 text-xs mt-4 text-center">{props.text}</p>
      <div className="mt-4">
        <p>
          Total trades: <span className=" font-bold">{props.total}</span>
        </p>
        <p>
          Bought:{" "}
          <span className="text-[#41BE85] font-bold">{props.bought}</span>
        </p>
        <p>
          Sold: <span className="text-[#EB4068] font-bold">{props.sold}</span>
        </p>
        <p>
          Bought/Sold:{" "}
          <span className="text-[#41BE85] font-bold">
            {Math.round((props.bought / props.total) * 100)}%
          </span>
          /
          <span className="text-[#EB4068] font-bold">
            {Math.round((props.sold / props.total) * 100)}%
          </span>
        </p>
      </div>
      <div className="mt-4 w-48 h-48 md:w-72 md:h-72 mx-auto">
        <HomeChart chartData={chartData} />
      </div>
    </div>
  );
};

export default ExchangeCard;
