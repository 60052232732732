import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { timeToGoSec } from "../../services/GeneralFunctions";
import Logo from "../../assets/smallLogo.png";
import millify from "millify";
import DexTableView from "../../components/DexTradesTable/DexTradesView";
import Snackbar from "@mui/material/Snackbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Footer from "../../components/Footer/Footer";
import AddFavourite from "./AddFavourite";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import BarChart from "../../components/BarChart/BarChart";
import axios from "axios";
import { useParams } from "react-router-dom";

const launchButton = {
  color: "white",
  fontWeight: "900",
  height: "5px",
};
const twitterHref = (txnValue, tokenOut, tokenIn, chain, outSym, inSym) => {
  return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (DEX) 
    %0ATxn Value $${millify(txnValue)} swaped on ${chain}
    %0AToken Out: $${tokenOut} $${outSym}
    %0AToken In: $${tokenIn} $${inSym}
    %0ACheck Out More: whaletracer.com
    %0A %23${outSym} %23${inSym}  %23${chain.toUpperCase()} %23WHALETRACER`;
};

const chainIcon = (val) => {
  if (val === "uniswap_v3" || val === "uniswap") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/UniswapIcon.png";
  }
  if (val === "sushiswap") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/SushiSwap-Icon.png";
  }
  if (val === "ddex") {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/DDEX-Icon.png";
  } else {
    return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/WT-DEX-Icon.png";
  }
};

const DexTradeDetails = () => {
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [trades, setTrades] = useState([]);
  const [totalTrades, setTotalTrades] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [favs, setFavs] = useState([]);

  const [showAddFav, setShowAddFav] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [favAdded, setFavAdded] = useState(false);

  const showModal = (wallet) => {
    setShowAddFav(!showAddFav);
    setWalletAddress(wallet);
  };

  const { auth } = useAuth();

  const user_id = localStorage.getItem("user_id");

  const addFavourtie = async (wallet, title) => {
    setAdding(true);
    await axios
      .post(
        `https://api.whaletracer.com/user/addFavouriteWallet/${user_id}/${wallet}/${title}`
      )
      .then((res) => {
        console.log(res);
      });
    setAdding(false);
    setFavAdded(true);
    setShowAddFav(false);
  };

  const deleteFavourite = async (wallet) => {
    setDeleting(true);
    await axios
      .delete(
        `https://api.whaletracer.com/user/removeFavouriteWallet/${user_id}/${wallet}`
      )
      .then((res) => {
        console.log(res);
      });
    setDeleting(false);
  };

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
    setTimeout(function () {
      setState({ ...state, open: false });
    }, 3000);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const params = useParams();

  var id = JSON.stringify(params.id);
  id = id.replace(/"/g, "");

  var wallet = JSON.stringify(params.wallet);
  wallet = wallet.replace(/"/g, "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://api.dev.dex.guru/v1/chain/1/wallets/${wallet}/transactions?api-key=DBO9wMFUWS6DBCq-hkRpOIVOs9wsZi2Gnotd-xmnjYk`
      )
      .then((res) => {
        setTotalTrades(res.data.total);
        setTrades(res.data.data);
      });
    axios
      .get(`https://api.whaletracer.com/dex-details/${id}`)
      .then((response) => {
        setSelectedTrade(response.data[0]);
      });
    axios
      .get(`https://api.whaletracer.com/user/getFavouriteWallets/${user_id}`)
      .then((res) => {
        console.log(res);
        setFavs(res.data.data);
      });
    setDataLoading(false);
  }, [id, wallet, favAdded, user_id]);

  const chartData = {
    labels: [
      timeToGoSec(trades[0]?.timestamp),
      timeToGoSec(trades[1]?.timestamp),
      timeToGoSec(trades[2]?.timestamp),
      timeToGoSec(trades[3]?.timestamp),
    ],
    datasets: [
      {
        type: "line",
        label: "Sold",
        data: [
          Math.round(trades[0]?.tokens_out[0].amount_out * 100) / 100,
          Math.round(trades[1]?.tokens_out[0].amount_out * 100) / 100,
          Math.round(trades[2]?.tokens_out[0].amount_out * 100) / 100,
          Math.round(trades[3]?.tokens_out[0].amount_out * 100) / 100,
        ],
        fill: false,
        borderColor: "rgb(235, 64, 104)",
        backgroundColor: "rgba(235, 64, 104)",
      },
      {
        type: "line",
        label: "Bought",
        data: [
          Math.round(trades[0]?.tokens_in[0].amount_in * 100) / 100,
          Math.round(trades[1]?.tokens_in[0].amount_in * 100) / 100,
          Math.round(trades[2]?.tokens_in[0].amount_in * 100) / 100,
          Math.round(trades[3]?.tokens_in[0].amount_in * 100) / 100,
        ],
        fill: false,

        borderColor: "rgb(83, 228, 148)",
        backgroundColor: "rgba(83, 228, 148)",
      },
    ],
  };

  if (dataLoading || trades.length === 0 || selectedTrade === null) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-sm">
          Loading trades data...
        </p>
      </div>
    );
  }

  const categoryName = (cat) => {
    if (cat === "Whale") {
      return "Whale";
    }
    if (cat === "medium") {
      return "Shark";
    }
    if (cat === "noob") {
      return "Shrimp";
    }
    if (cat === "casual") {
      return "Dolphin";
    }
    if (cat === "bot") {
      return "Bot";
    }
    if (cat === null) {
      return "Unspecified";
    }
  };

  return (
    <div className="mt-12 font-sans">
      <AddFavourite
        wallet={walletAddress}
        open={showAddFav}
        close={showModal}
        add={addFavourtie}
        adding={adding}
      />
      <div className="text-white max-w-wt 2xl:max-w-ft mb-8 mx-auto text-xs">
        <h1 className="text-5xl text-white font-black">DEX Trade Details</h1>
        <p className="text-sm font-medium mt-2">
          See recent trades of this DEX chain.
        </p>
        <div className="flex flex-col md:flex-row mt-4">
          <div className="">
            <div className="text-white flex flex-row items-center bg-[#3c3a5890] rounded-lg py-3 w-92 sm:w-97 md:w-92">
              <img
                src={chainIcon(selectedTrade.chain_name)}
                alt="coin-icon"
                className="w-16 object-contain ml-4"
              />
              <h1 className="text-lg font-bold ml-2">
                {" "}
                {selectedTrade.chain_name.toUpperCase()}{" "}
              </h1>
            </div>
            <div className="text-white bg-[#3c3a5890] rounded-lg py-2 mt-2 w-92 sm:w-97 md:w-92">
              <div className="w-97 p-4 mx-auto">
                <h2 className="text-2xl font-extrabold mb-4">
                  {" "}
                  TRANSACTION DETAILS
                </h2>
                <div className="">
                  <div className="flex flex-row justify-between mb-2 text-sm">
                    <p> Txn Hash</p>
                    <div className="item-content">
                      <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleClose}
                        message="Copied to clipboard"
                        key={vertical + horizontal}
                        sx={{ height: "3%" }}
                        severity="success"
                      />
                      <CopyToClipboard text={selectedTrade.txn_hash}>
                        <Button
                          variant="text"
                          startIcon={<ContentCopyIcon />}
                          style={launchButton}
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          {selectedTrade.txn_hash.slice(0, 5)}...
                          {selectedTrade.txn_hash.slice(25, 30)}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between text-sm mb-2">
                    <p> Timestamp</p>
                    <p className="font-bold">
                      {" "}
                      {timeToGoSec(selectedTrade.trade_time)}
                    </p>
                  </div>

                  <div className="flex flex-row justify-between text-sm">
                    <p> Sold</p>
                    <p className="font-bold  text-red-500">
                      ${Math.round(selectedTrade.token_in * 1000) / 1000}{" "}
                      {selectedTrade.symbol_in}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between text-sm">
                    <p> Bought</p>
                    <p className="font-bold text-green-500">
                      {" "}
                      ${Math.round(selectedTrade.token_out * 1000) / 1000}{" "}
                      {selectedTrade.symbol_out}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between text-sm">
                    <p> TXN Value</p>
                    <p className="font-bold">
                      {" "}
                      ${Math.round(selectedTrade.txn_value * 1000) / 1000}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between text-sm mb-2">
                    <p> Wallet Type</p>

                    <p className="font-bold">
                      {" "}
                      {selectedTrade.category
                        ? categoryName(selectedTrade.category)
                        : "Unspecified"}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between text-sm mb-2">
                    <p> Wallet Address</p>

                    <div className="item-content">
                      <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleClose}
                        message="Copied to clipboard"
                        key={vertical + horizontal}
                        sx={{ height: "3%" }}
                        severity="success"
                      />
                      <CopyToClipboard text={selectedTrade.wallet}>
                        <Button
                          variant="text"
                          startIcon={<ContentCopyIcon />}
                          style={launchButton}
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          {selectedTrade.wallet
                            ? selectedTrade.wallet.slice(0, 5) +
                              "..." +
                              selectedTrade.wallet.slice(25, 30)
                            : "Unspecified"}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  {favs?.includes(selectedTrade.wallet) ? (
                    <button
                      type="button"
                      className="w-full mt-4 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600/80 focus:outline-none"
                      onClick={() => deleteFavourite(selectedTrade.wallet)}
                    >
                      {deleting ? "Remvoing Wallet..." : "Remove Favourite"}
                    </button>
                  ) : auth ? (
                    <button
                      type="button"
                      className="w-full mt-4 rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                      onClick={() => showModal(selectedTrade.wallet)}
                    >
                      {adding
                        ? "Adding Wallet to Favourite..."
                        : "Add Wallet to Favourites"}
                    </button>
                  ) : (
                    <Link to="/login">
                      <button
                        type="button"
                        className="w-full mt-4 rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                      >
                        {adding
                          ? "Adding Wallet to Favourite..."
                          : "Add Wallet to Favourites"}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-3/4 ml-4 lg:w-1/2 md:w-1/3">
            <BarChart chartData={chartData} />
          </div>
        </div>
        <div className="mt-8 w-full">
          <div className="text-white flex flex-row items-center bg-[#3c3a5890] rounded-lg py-3 mt-8">
            <h1 className="font-black text-3xl ml-4">
              {" "}
              Recent DEX Transactions From This Wallet
            </h1>
          </div>
          <div className="flex flex-col  text-white mt-2">
            {totalTrades === 0 ? (
              <p> No trades for this wallet</p>
            ) : (
              trades.map((trade) => (
                <DexTableView
                  chain={trade.amm}
                  time={trade.timestamp}
                  token_out={
                    Math.round(trade.tokens_out[0].amount_out * 100) / 100
                  }
                  token_in={
                    Math.round(trade.tokens_in[0].amount_in * 100) / 100
                  }
                  trade_id={trade.to}
                  symbol_out={trade.tokens_out[0].symbol}
                  symbol_in={trade.tokens_in[0].symbol}
                  value={Math.round(trade.amount_usd * 100) / 100}
                  hash={trade.transaction_address}
                  wallet={trade.wallet_address}
                  category={trade.wallet_category}
                  timeToGo={timeToGoSec}
                  href={twitterHref}
                  detailedView={true}
                  view="small"
                />
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DexTradeDetails;
