import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Logo from "../../../assets/smallLogo.png";
import TradesCard from "../../../components/TradesCard/TradesCard";
import Footer from "../../../components/Footer/Footer";
import { timeToGo } from "../../../services/GeneralFunctions";
import { Link } from "react-router-dom";

const WatchList = (props) => {
  const [trades, setTrades] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [favs, setFavs] = useState([]);
  const [favsLoaded, setFavsLoaded] = useState(false);

  const twitterHref = (
    baseAsset,
    quoteAsset,
    tradeValue,
    qty,
    exchange,
    side
  ) => {
    return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (CEX) %0A %0A${qty} $${baseAsset} has been ${
      side ? "sold" : "purchased"
    } for $${tradeValue} using $${quoteAsset} on ${exchange}. %0A %0ACheck Out Whale Trades At: WhaleTracer.com %0A %0A %23${baseAsset} %23${quoteAsset}  %23${exchange.toUpperCase()} %23WHALETRACER`;
  };
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    axios
      .get(`https://api.whaletracer.com/trades/null/1/null/null/24`)
      .then((response) => {
        setTrades(response.data);
      });
    axios
      .get(`https://api.whaletracer.com/user/getFavourites/${user_id}`)
      .then((res) => {
        setFavs(res.data.data);
        setFavsLoaded(res.data.success);
      });
    setDataLoading(false);
  }, []);

  if (dataLoading) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-lg">
          Loading trades data...
        </p>
      </div>
    );
  }

  const favourites = trades.filter((trade) =>
    favs.includes(trade.base_asset_trade)
  );
  return (
    <Fade bottom>
      <>
        <div className="text-white max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] font-sans mb-12">
          <div className="mt-8 mb-8 flex flex-col sm:flex-row justify-between items-center">
            <div>
              <h1 className="font-black text-lg md:text-2xl text-center md:text-left text-white">
                TRADES FROM YOUR FAVOURITE COINS
              </h1>
              <p className="mb-4 md:mb-4 md:mt-0 text-md md:text-xl text-slate-300 text-center md:text-left">
                {" "}
                Find the recent trades of your favourite coins!
              </p>
            </div>
            <div>
              <Link to="/dashboard/editwatchlist">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-[#B0EEFD] px-6 cursor-pointer py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                >
                  Edit Watchlist
                </button>
              </Link>
            </div>
          </div>
          <div className="md:block"></div>
          {!favsLoaded && favourites.length === 0 ? (
            <div className="">
              <h1 className="tracking-tight text-white">
                Loading favourites...
              </h1>
            </div>
          ) : (
            <div
              className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8 text-white"
              id="tradeCards"
            >
              {favsLoaded && favourites.length === 0 ? (
                <div className="">
                  <h1 className="text-2xl font-bold tracking-tight text-white">
                    No favourites added
                  </h1>
                </div>
              ) : (
                favourites.map((trade) => (
                  <TradesCard
                    symbol={trade.base_asset_trade}
                    pair={trade.pair}
                    image={trade.base_asset_icon}
                    price={trade.price_in_quote_asset}
                    quantity={trade.qty}
                    quoteAsset={trade.quote_asset_trade}
                    baseAsset={trade.base_asset_trade}
                    time={timeToGo(trade.trade_time)}
                    isBuyer={trade.is_buyer_maker}
                    cost={trade.trade_value}
                    exchange={trade.exchange_name}
                    exchangeLogo={trade.exchange_icon_url}
                    interval={24}
                    id={trade.id}
                    href={twitterHref}
                  />
                ))
              )}
            </div>
          )}
        </div>
        <Footer />
      </>
    </Fade>
  );
};

export default WatchList;
