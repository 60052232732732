import { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import millify from "millify";
import useAuth from "../../hooks/useAuth";
import useUpgrade from "../../hooks/useUpgrade";

const detailLink = (symbol) => {
  return `/coin-details/${symbol}`;
};

export default function CexTrending() {
  const [filter, setFilter] = useState(1);
  const [topTrades, setTopTrades] = useState([]);

  const { auth } = useAuth();
  const { setOpen } = useUpgrade();

  useEffect(() => {
    Axios.get(
      `https://api.whaletracer.com/cex-trades/toptennew/${filter}`
    ).then((response) => {
      setTopTrades(response.data);
    });
  }, [filter]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-[#292644] p-4 border-8 border-[#403e66] rounded-lg">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-white text-center">
            CRYPTO TRENDING ON CEX
          </h1>
          <p className="mt-2 text-sm text-gray-300 text-center">
            A list of all the CEX coins that are trending right now.
          </p>
        </div>
      </div>
      <div className="text-center mt-4">
        {auth ? (
          <span className="relative z-0 inline-flex shadow-sm rounded-md ">
            <button
              type="button"
              className={
                filter === 1
                  ? "-ml-px relative inline-flex items-center rounded-l-md px-4 py-2 bg-[#8EDAFA] text-sm font-medium text-black "
                  : "-ml-px relative inline-flex items-center rounded-l-md px-4 py-2 border border-[#8EDAFA] text-sm font-medium text-white"
              }
              onClick={() => setFilter(1)}
            >
              24H
            </button>
            <button
              type="button"
              className={
                filter === 7
                  ? "relative inline-flex items-center px-4 py-2 bg-[#8EDAFA] text-sm font-medium text-black "
                  : "relative inline-flex items-center px-4 py-2   border border-[#8EDAFA] text-sm font-medium text-white"
              }
              onClick={() => setFilter(7)}
            >
              7D
            </button>
            <button
              type="button"
              className={
                filter === 30
                  ? "-ml-px relative inline-flex items-center rounded-r-md px-4 py-2 bg-[#8EDAFA] text-sm font-medium text-black "
                  : "-ml-px relative inline-flex items-center rounded-r-md px-4 py-2 border border-[#8EDAFA] text-white text-sm font-medium"
              }
              onClick={() => setFilter(30)}
            >
              30D
            </button>
          </span>
        ) : (
          <span className="relative z-0 inline-flex shadow-sm rounded-md ">
            <button
              type="button"
              className={
                filter === 1
                  ? "-ml-px relative inline-flex items-center rounded-l-md px-4 py-2 bg-[#8EDAFA] text-sm font-medium text-black "
                  : "-ml-px relative inline-flex items-center rounded-l-md px-4 py-2 border border-[#8EDAFA] text-sm font-medium text-white"
              }
              onClick={() => setFilter(1)}
            >
              24H
            </button>
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2   border border-[#8EDAFA] text-sm font-medium text-white  opacity-20"
              onClick={() => setOpen(true)}
            >
              7D
            </button>
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 rounded-r-md  border border-[#8EDAFA] text-sm font-medium text-white  opacity-20 "
              onClick={() => setOpen(true)}
            >
              30D
            </button>
          </span>
        )}
      </div>
      <div className="px-4 sm:px-6 lg:px-8 hidden md:block">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className=" text-white">
                    <tr className="bg-[#292644] text-white">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold  sm:pl-6"
                      >
                        Symbol
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Bought
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Sold
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Bought Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Sold Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Trades %
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        No of Trades
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-0.3 divide-[#B0EEFD]/60 bg-[#292644] p-12">
                    {topTrades.slice(0, 10).map((trade) => (
                      <tr key={trade.symbol}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <Link
                            to={detailLink(
                              trade.base_asset_trade.toUpperCase()
                            )}
                            className="no-underline text-white"
                          >
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full hover:scale-125 hover:ease-out hover:duration-300 "
                                  src={trade.base_asset_icon}
                                  alt=""
                                />
                              </div>
                              <div className="ml-2">
                                <div className="text-[#B0EEFD] text-lg hover:text-[#41BE85] font-black hover:scale-125 hover:ease-out hover:duration-300 hover:underline">
                                  {trade.base_asset_trade}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#41BE85] font-extrabold">
                          {trade.bought}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          {trade.sold}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#41BE85] font-extrabold">
                          ${millify(Math.round(trade.bought_trade_value))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          ${millify(Math.round(trade.sold_trade_value))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          <span className="text-[#41BE85] font-extrabold">
                            {(Math.round(
                              (trade.bought / trade.total_trades) * 100
                            ) *
                              1000) /
                              1000}
                            %
                          </span>
                          /
                          <span className="text-[#EB4068] font-extrabold">
                            {(Math.round(
                              (trade.sold / trade.total_trades) * 100
                            ) *
                              1000) /
                              1000}
                            %
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          {trade.total_trades}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className=" text-white">
                    <tr className="bg-[#292644] text-white">
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold  sm:pl-6"
                      >
                        Symbol
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Bought
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Sold
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        No of Trades
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-0.3 divide-[#B0EEFD]/60 bg-[#292644] p-12">
                    {topTrades.slice(0, 10).map((trade) => (
                      <tr key={trade.symbol}>
                        <td className="py-4 text-sm">
                          <Link
                            to={detailLink(
                              trade.base_asset_trade.toUpperCase()
                            )}
                            className="no-underline text-white"
                          >
                            <div className="flex items-center">
                              <div className="h-8 w-8 flex-shrink-0">
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={trade.base_asset_icon}
                                  alt=""
                                />
                              </div>
                              <div className="ml-2">
                                <div className="text-[#B0EEFD] font-bold text-lg">
                                  {trade.base_asset_trade}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#41BE85] font-extrabold">
                          {trade.bought}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-[#EB4068] font-extrabold">
                          {trade.sold}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white font-extrabold">
                          {trade.total_trades}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
