import Axios from "axios";
import { timeToGo } from "../../../../services/GeneralFunctions";

export default function TableSub(props) {
  const deleteUser = async (email) => {
    try {
      await Axios.delete(
        `https://api.whaletracer.com/user/deleteSubscriber/${email}`
      ).then((res) => {
        console.log(res);
        props.toggleDelete();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-2">
      <div className="inline-block min-w-full">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="divide-y divide-gray-700 lg:w-[1100px] 2xl:w-[1250px]">
            <thead className="bg-[#1B2137]">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-right text-sm font-semibold text-white pr-16"
                >
                  Preference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-right text-sm font-semibold text-white pr-24"
                >
                  Subscribe Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-right text-sm font-semibold text-white pr-16"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-[#292644]">
              {props.data.map((person) => (
                <tr key={person.email_address}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-300 sm:pl-6">
                    {person.email_address}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300 text-right pr-12">
                    {person.type === "daily" && (
                      <span class="inline-flex items-center rounded-md  bg-green-700 px-3 py-0.5 text-sm font-medium text-white">
                        Daily Alert
                      </span>
                    )}
                    {person.type === "weekly" && (
                      <span class="inline-flex items-center rounded-md  bg-blue-700 px-3 py-0.5 text-sm font-medium text-white">
                        Weekly Alert
                      </span>
                    )}
                    {person.type === "monthly" && (
                      <span class="inline-flex items-center rounded-md  bg-indigo-700 px-3 py-0.5 text-sm font-medium text-white">
                        Monthly Alert
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-300 sm:pl-24">
                    {timeToGo(person.subscribe_date)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300 text-right pr-12">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm"
                      onClick={() => deleteUser(person.email_address)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
