import React, { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import Axios from "axios";
import ChangeName from "./ChangeName";
import ChangePassword from "./ChangePassword";
import CancelSubscription from "./CancelSub";
import Logo from "../../assets/smallLogo.png";
import { Event } from "../../components/Tracking/Tracking";

import Footer from "../../components/Footer/Footer";

const Profile = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);

  const [processingDaily, setProcessingDaily] = useState(false);
  const [processingWeekly, setProcessingWeekly] = useState(false);
  const [processingMonthly, setProcessingMonthly] = useState(false);

  const [email, setEmail] = useState("");
  const [subscribeError, setSubscribeError] = useState(false);
  const [subscribing, setSubscribing] = useState(false);

  const [userPlan, setUserPlan] = useState([]);
  const [userExists, setUserExists] = useState(false);

  const [showName, setShowName] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [toggleChange, setToggleChange] = useState(false);

  const showNameToggle = () => {
    setShowName((showName) => !showName);
  };
  const showPasswordToggle = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const showCancelToggle = () => {
    setShowCancel((showCancel) => !showCancel);
  };

  const activeFilterStyle =
    "border-2 border-[#6DCDF5] bg-[#24213F] rounded-lg mt-4 py-4 px-2  items-center cursor-pointer flex justify-between";
  const inActiveFilterStyle =
    "bg-[#24213F] rounded-lg mt-4 py-4 px-2  items-center cursor-pointer flex justify-between";

  const sub_id = localStorage.getItem("sub_id");

  const stripe_id = localStorage.getItem("stripe_id");

  const userEmail = localStorage.getItem("email");
  useEffect(() => {
    const getUserPlan = async () => {
      await Axios.get(
        `https://api.whaletracer.com/user/getUserPlan/${userEmail}`
      ).then((res) => {
        if (res.data.userExists) {
          setUserExists(true);
          setUserPlan(res.data.data);
        } else {
          setUserExists(false);
        }
      });
    };
    getUserPlan();
    setLoading(false);
  }, [toggleChange]);

  const changeDaily = async () => {
    setProcessingDaily(true);
    await Axios.put(
      `https://api.whaletracer.com/email/daily/${user.email}`
    ).then((res) => {
      console.log(res);
      setProcessingDaily(false);
      setToggleChange((toggleChange) => !toggleChange);
    });
    setProcessingDaily(false);
  };
  const changeWeekly = async () => {
    setProcessingWeekly(true);
    await Axios.put(
      `https://api.whaletracer.com/email/weekly/${user.email}`
    ).then((res) => {
      console.log(res);
      setProcessingWeekly(false);
      setToggleChange((toggleChange) => !toggleChange);
    });
    setProcessingWeekly(false);
  };
  const changeMonthly = async () => {
    setProcessingMonthly(true);
    await Axios.put(
      `https://api.whaletracer.com/email/monthly/${user.email}`
    ).then((res) => {
      console.log(res);
      setProcessingMonthly(false);
      setToggleChange((toggleChange) => !toggleChange);
    });
    setProcessingMonthly(false);
  };

  const subscribeBody = {
    email: email,
  };

  const subscribeUser = () => {
    setSubscribeError(false);
    setSubscribing(true);
    Event("Subscribe Button", "Subscribe Button was clicked", "Subscribe link");
    Axios.post("https://api.whaletracer.com/email/subscribe", subscribeBody)
      .then((res) => {
        setSubscribing(false);
        setToggleChange((toggleChange) => !toggleChange);
      })
      .catch((err) => {
        setSubscribeError(true);
        setSubscribing(false);
      });
    setEmail("");
  };

  if (loading) {
    return (
      <img src={Logo} className="w-36 mx-auto my-48 2xl:my-64" alt="loading" />
    );
  }

  return (
    <>
      <ChangeName
        showChangeName={showName}
        toggle={showNameToggle}
        id={stripe_id}
      />
      <ChangePassword
        showChangePassword={showPassword}
        toggle={showPasswordToggle}
      />
      <CancelSubscription
        showCancel={showCancel}
        toggle={showCancelToggle}
        id={sub_id}
      />
      <div className="mb-16 flex flex-col mt-8 font-sans">
        <main className="flex flex-col justify-center">
          <div className="mx-auto flex flex-col  items-center content-center">
            <img
              className="object-contain w-16 md:w-16"
              src="https://i.stack.imgur.com/Bxv94.png"
              alt="Workflow"
            />
            <p className="text-white font-bold text-md">{user.fullname}</p>
            <span className="text-slate-200 text-xs">{user.email}</span>
          </div>
          <div className="border-t-0.5 border-[#6DCDF5] w-92  sm:w-150 mt-8 mx-auto">
            <div className="bg-[#24213F] rounded-lg mt-4 py-4 px-2 flex items-center flex-col sm:flex-row  sm:justify-between justify-center ">
              <div className="flex flex-col sm:flex-row items-center">
                <img
                  src="https://i.stack.imgur.com/CMoil.png"
                  alt="premium"
                  className="w-28 object-contain rounded drop-shadow-lg"
                />
                <div className="">
                  <p className="text-md font-bold text-white font-white">
                    Member
                  </p>
                </div>
              </div>
              <div className="sm:mr-2 text-center">
                <p className="text-md font-bold text-white font-white">
                  $0 / Month
                </p>
                <span className="text-xs text-slate-300 ">No Expiration</span>
              </div>
            </div>

            <div className="border-b border-gray-200 mt-4"></div>
            {userExists ? (
              <div>
                <h1 className="text-white font-bold text-xl mt-2">
                  Email Preference
                </h1>
                <div className="text-white font-bold font-lg">
                  <div
                    className={
                      userPlan.type === "daily"
                        ? activeFilterStyle
                        : inActiveFilterStyle
                    }
                  >
                    <div className="px-4">
                      <h1>Daily Whale Alert</h1>
                      <p className="text-sm text-slate-300">
                        You will receive a daily email with whale updates
                      </p>
                    </div>

                    <button
                      className={
                        userPlan.type === "daily"
                          ? "bg-[#6DCDF5] text-black px-4 py-2 rounded text-xs"
                          : "border border-[#6DCDF5] text-white px-4 py-2 rounded text-xs"
                      }
                      onClick={userPlan.type === "daily" ? null : changeDaily}
                    >
                      {userPlan.type === "daily"
                        ? "Selected"
                        : processingDaily
                        ? "Saving..."
                        : "Select"}
                    </button>
                  </div>
                  <div
                    className={
                      userPlan.type === "weekly"
                        ? activeFilterStyle
                        : inActiveFilterStyle
                    }
                  >
                    <div className="px-4">
                      <h1>Weekly Whale Alert</h1>
                      <p className="text-sm text-slate-300">
                        You will receive a weekly email with whale updates
                      </p>
                    </div>

                    <button
                      className={
                        userPlan.type === "weekly"
                          ? "bg-[#6DCDF5] text-black px-4 py-2 rounded text-xs"
                          : "border border-[#6DCDF5] text-white px-4 py-2 rounded text-xs"
                      }
                      onClick={userPlan.type === "weekly" ? null : changeWeekly}
                    >
                      {userPlan.type === "weekly"
                        ? "Selected"
                        : processingWeekly
                        ? "Saving..."
                        : "Select"}
                    </button>
                  </div>
                  <div
                    className={
                      userPlan.type === "monthly"
                        ? activeFilterStyle
                        : inActiveFilterStyle
                    }
                  >
                    <div className="px-4">
                      <h1>Monthly Whale Alert</h1>
                      <p className="text-sm text-slate-300">
                        You will receive a monthly email with whale updates
                      </p>
                    </div>

                    <button
                      className={
                        userPlan.type === "monthly"
                          ? "bg-[#6DCDF5] text-black px-4 py-2 rounded text-xs"
                          : "border border-[#6DCDF5] text-white px-4 py-2 rounded text-xs"
                      }
                      onClick={
                        userPlan.type === "monthly" ? null : changeMonthly
                      }
                    >
                      {userPlan.type === "monthly"
                        ? "Selected"
                        : processingMonthly
                        ? "Saving..."
                        : "Select"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="mx-auto max-w-8xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                  <h2 className="inline text-3xl font-bold tracking-tight text-slate-200 sm:block sm:text-4xl">
                    Want to receive alerts on Whale Activity?
                  </h2>
                  <p className="inline text-3xl font-bold tracking-tight text-[#6DCDF5] sm:block sm:text-4xl">
                    Sign up for WhaleTracer Email Alerts
                  </p>
                  <div className="mt-8 sm:flex">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 sm:max-w-xs"
                      placeholder="Enter your email"
                    />
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        onClick={subscribeUser}
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#6DCDF5] px-5 py-3 text-base font-medium text-black "
                      >
                        {subscribing ? "Subscribing..." : "Subscribe"}
                      </button>
                    </div>
                  </div>
                  {subscribeError && (
                    <p className="text-red-400 mt-2 font-medium">
                      Subscription failed! Please try again
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="flex flex-col md:flex-row justify-center gap-x-4 mx-auto text-center">
              <button
                type="button"
                className={
                  "bg-[#6DCDF5] text-black px-4 py-2 w-72 rounded text-xs mt-4 mx-auto"
                }
                onClick={showNameToggle}
              >
                Change Name
              </button>
              <button
                type="button"
                className={
                  "bg-[#6DCDF5] text-black px-4 py-2 w-72 rounded text-xs mt-4 mx-auto"
                }
                onClick={showPasswordToggle}
              >
                Change Password
              </button>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
