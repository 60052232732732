import React from "react";
import "./Filters.css";

import useUpgrade from "../../hooks/useUpgrade";
import TableView from "../../assets/table-view.png";
import { FilterIcon } from "@heroicons/react/solid";

import Coinbase from "../../assets/coinbase.png";

import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

const activeButtonStyle =
  "inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-[#B0EEFD]";
const inactiveButtonStyle =
  "inline-flex  items-center px-4 py-2  border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD]";
const disabledButton =
  "inline-flex  items-center px-4  py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white border border-[#B0EEFD] opacity-50";

const activeExchange = "w-12 p-2 rounded-md bg-[#273457] cursor-pointer	";
const inactiveExchange =
  "w-12 p-2 rounded-md bg-[#273457] opacity-50 cursor-pointer";

const Filters = ({
  filter,
  setFilter,
  dataDisplay,
  toggleDisplay,
  exchange,
  exchangeFilter,
  toggleFilter,
}) => {
  const { setOpen } = useUpgrade();
  const { user } = useUser();
  const { auth } = useAuth();
  return (
    <div>
      <div className="hidden md:block">
        {" "}
        <div className="md:flex flex-row justify-between">
          <div className="flex gap-x-1">
            <div className="flex gap-x-2">
              <button
                type="button"
                className={
                  filter === 6 ? activeButtonStyle : inactiveButtonStyle
                }
                onClick={() => setFilter(6)}
              >
                6H
              </button>
              {auth ? (
                <>
                  {" "}
                  <button
                    type="button"
                    className={
                      filter === 12 ? activeButtonStyle : inactiveButtonStyle
                    }
                    onClick={() => setFilter(12)}
                  >
                    12H
                  </button>
                  <button
                    type="button"
                    className={
                      filter === 24 ? activeButtonStyle : inactiveButtonStyle
                    }
                    onClick={() => setFilter(24)}
                  >
                    24H
                  </button>
                  <button
                    type="button"
                    className={
                      filter === 168 ? activeButtonStyle : inactiveButtonStyle
                    }
                    onClick={() => setFilter(168)}
                  >
                    7D
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    type="button"
                    className={disabledButton}
                    onClick={() => setOpen(true)}
                  >
                    12H
                  </button>
                  <button
                    type="button"
                    className={disabledButton}
                    onClick={() => setOpen(true)}
                  >
                    24H
                  </button>
                  <button
                    type="button"
                    className={disabledButton}
                    onClick={() => setOpen(true)}
                  >
                    7D
                  </button>
                </>
              )}
            </div>

            <div className="border-[1px] border-slate-600 h-12 mx-2"></div>
            <div className="flex gap-x-2">
              <button
                type="button"
                className={
                  exchangeFilter === ""
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => exchange("")}
              >
                All
              </button>
              {auth ? (
                <>
                  <img
                    src="https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png"
                    alt="binance"
                    className={
                      exchangeFilter === "Binance"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => exchange("Binance")}
                  />

                  <img
                    src={Coinbase}
                    alt="binance"
                    className={
                      exchangeFilter === "Coinbase"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => exchange("Coinbase")}
                  />
                  <img
                    src="https://assets.coingecko.com/markets/images/96/small/WeChat_Image_20220117220452.png?1642428377"
                    alt="binance"
                    className={
                      exchangeFilter === "OKX"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => exchange("OKX")}
                  />
                  <img
                    src="https://i.stack.imgur.com/gJP85.png"
                    alt="binance"
                    className={
                      exchangeFilter === "BYBIT"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => exchange("BYBIT")}
                  />
                </>
              ) : (
                <>
                  <img
                    src="https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png"
                    alt="binance"
                    className={inactiveExchange}
                    onClick={() => setOpen(true)}
                  />

                  <img
                    src={Coinbase}
                    alt="binance"
                    className={inactiveExchange}
                    onClick={() => setOpen(true)}
                  />
                  <img
                    src="https://assets.coingecko.com/markets/images/96/small/WeChat_Image_20220117220452.png?1642428377"
                    alt="binance"
                    className={
                      exchangeFilter === "OKX"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => setOpen(true)}
                  />
                  <img
                    src="https://i.stack.imgur.com/gJP85.png"
                    alt="binance"
                    className={
                      exchangeFilter === "BYBIT"
                        ? activeExchange
                        : inactiveExchange
                    }
                    onClick={() => setOpen(true)}
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-x-2">
            <button
              type="button"
              onClick={auth ? toggleFilter : () => setOpen(true)}
              className={auth ? activeButtonStyle : disabledButton}
            >
              <FilterIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Filters
            </button>
            {dataDisplay ? (
              <img
                src={TableView}
                alt="binance"
                onClick={toggleDisplay}
                className="w-12 p-2 rounded-md bg-[#273457] cursor-pointer"
              />
            ) : (
              <img
                src="https://i.stack.imgur.com/K8kXN.png"
                alt="binance"
                onClick={toggleDisplay}
                className="w-12 p-2 rounded-md bg-[#273457] cursor-pointer"
              />
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        {" "}
        <div className="flex flex-row gap-x-4 justify-center">
          <button
            type="button"
            className={
              exchangeFilter === "" ? activeButtonStyle : inactiveButtonStyle
            }
            onClick={() => exchange("")}
          >
            All
          </button>

          {auth &&
          (user?.membership === "pro" ||
            localStorage.getItem("membership") === "pro") ? (
            <>
              <img
                src="https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png"
                alt="binance"
                className={
                  exchangeFilter === "Binance"
                    ? activeExchange
                    : inactiveExchange
                }
                onClick={() => exchange("Binance")}
              />

              <img
                src="https://seeklogo.com/images/C/coinbase-coin-logo-C86F46D7B8-seeklogo.com.png"
                alt="binance"
                className={
                  exchangeFilter === "Coinbase"
                    ? activeExchange
                    : inactiveExchange
                }
                onClick={() => exchange("Coinbase")}
              />
              <img
                src="https://i.stack.imgur.com/gJP85.png"
                alt="binance"
                className={
                  exchangeFilter === "BYBIT" ? activeExchange : inactiveExchange
                }
                onClick={() => exchange("BYBIT")}
              />
            </>
          ) : (
            <>
              <img
                src="https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png"
                alt="binance"
                className={inactiveExchange}
                onClick={() => setOpen(true)}
              />

              <img
                src="https://logosarchive.com/wp-content/uploads/2021/12/Coinbase-icon-symbol-1.svg"
                alt="binance"
                className={inactiveExchange}
                onClick={() => setOpen(true)}
              />
            </>
          )}
          <img
            src="https://i.stack.imgur.com/8PDWz.png"
            alt="binance"
            onClick={auth ? toggleFilter : () => setOpen(true)}
            className={auth ? activeExchange : inactiveExchange}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
