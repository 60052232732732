/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Auth from "@aws-amplify/auth";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@+#$%]).{8,24}$/;

export default function ChangeName(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState(null);
  const [validCPwd, setValidCPwd] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const results = PWD_REGEX.test(password);
    setValidPwd(results);
  }, [password]);
  useEffect(() => {
    const results = password === confirmPwd;
    setValidCPwd(results);
  }, [confirmPwd, password]);

  const closePopUp = () => {
    props.toggle();
    setOldPassword("");
    setPassword("");
    setConfirmPwd("");
  };

  const changePassword = async () => {
    setProcessing(true);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, password);
      })
      .then((data) => props.toggle())
      .catch((err) => setErrorMessage(err.message));
  };

  return (
    <Transition.Root show={props.showChangePassword} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closePopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-[#403E66] rounded-lg px-8 pt-6 pb-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <p
                  className="text-xs text-slate-300 text-right cursor-pointer"
                  onClick={closePopUp}
                >
                  Close
                </p>
                <div>
                  <h2 className="mt-6 text-center text-xl font-bold text-white">
                    Change your Password
                  </h2>
                </div>
                {errorMessage && (
                  <p className="text-sm text-red-400 text-center mb-2">
                    {errorMessage}
                  </p>
                )}

                <div className="sm:mx-auto sm:w-full ">
                  <div className="bg-[#403E66] py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-white"
                        >
                          Old Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            value={oldPassword}
                            name="password"
                            type="password"
                            autoComplete="password"
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                            placeholder="Enter Your Old Password..."
                            className="appearance-none block w-60 sm:w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-white"
                        >
                          New Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            value={password}
                            name="password"
                            type="password"
                            autoComplete="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter Your New Password..."
                            className="appearance-none block w-60 sm:w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        {!validPwd && password !== "" && (
                          <p className="text-sm text-red-400 mb-2">
                            Please enter a valid password
                          </p>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-white"
                        >
                          Confirm Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            value={confirmPwd}
                            type="password"
                            autoComplete="password"
                            onChange={(e) => setConfirmPwd(e.target.value)}
                            required
                            placeholder="Confirm Your Password..."
                            className="appearance-none block w-60 sm:w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        {!validCPwd && confirmPwd !== "" && password !== "" && (
                          <p className="text-sm text-red-400 mb-2">
                            Passwords do not match
                          </p>
                        )}
                      </div>
                      <div>
                        <button
                          className={
                            processing
                              ? "group relative w-full opacity-70 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                              : "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]"
                          }
                          onClick={
                            validPwd && validCPwd ? changePassword : null
                          }
                        >
                          {processing ? "Changing the password..." : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
