import { Container } from "./Container";

const features = [
  {
    name: "Real-Time Trades Data",
    description:
      "Get real-time whale trades data from most popular CEX where trade value is above $50,000 & DEX where Tnx value is above $100,000.",
    icon: "https://i.stack.imgur.com/kJ7Qb.png",
  },
  {
    name: "Verified Data From Exchanges",
    description:
      "We collect our data from exchanges directly and present you the filtered useful data so you have the refined information to make smart decision.",
    icon: "https://i.stack.imgur.com/cTH8Y.png",
  },
  {
    name: "Whale Trending Data",
    description:
      "Based on the verified whale trades we calculate both bough and sold trades for each coin and present you with most trending currencies in the crypto space.",
    icon: "https://i.stack.imgur.com/EhJp6.png",
  },
  {
    name: "Daily Email Alerts",
    description:
      "We share daily stats with you via email alerts so you can have daily update on the go, where ever you are you are always updated with the latest.",
    icon: "https://i.stack.imgur.com/oxqsw.png",
  },
  {
    name: "Advance Filter Trades Data",
    description:
      "We offer our users the ability to further refine and filter the whale trades data to their specific requirements and find the valuable information they need.",
    icon: "https://i.stack.imgur.com/p8epD.png",
  },
  {
    name: "DEX Whale Swaps",
    description:
      "We collect massive DEX swaps and trades data every minute to filter out whale swaps over popular chains and provide our users with insight to differentiate between Whale & Bot wallets.",
    icon: "https://i.stack.imgur.com/puIme.png",
  },
];

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for building a portfolio"
      className="py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-4xl font-black tracking-tight text-white">
            BENEFITS OF USING WHALETRACER{" "}
          </h2>
          <p className="mt-4 text-lg text-slate-300">
            The key to success is having the right information before anyone
            else, with Whale Tracers amazing insights now you can define your
            success.
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <li
              key={feature.name}
              className="rounded-2xl border border-[#52B3D0] p-8"
            >
              <img
                src={feature.icon}
                className="object-contain w-12"
                alt="icons"
              />
              <h3 className="mt-6 text-lg font-bold text-white">
                {feature.name}
              </h3>
              <p className="mt-2 text-slate-300">{feature.description}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
