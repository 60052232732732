import React, { useState, useEffect } from "react";
import { FilterIcon } from "@heroicons/react/solid";
import axios from "axios";
import useUser from "../../hooks/useUser";

export default function Table({ coins, filter }) {
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [favs, setFavs] = useState([]);
  const [favsLoaded, setFavsLoaded] = useState(false);
  const [favAdded, setFavAdded] = useState(false);

  const [selectedSymbol, setSelectedSymbol] = useState("");
  const { user } = useUser();

  const user_id = localStorage.getItem("user_id");

  const addFavourite = async (symbol) => {
    setSelectedSymbol(symbol);
    setAdding(true);
    await axios
      .post(
        `https://api.whaletracer.com/user/addFavourite/${user.user_id}/${symbol}`
      )
      .then((res) => {
        console.log(res);
      });
    setFavAdded(!favAdded);
    setAdding(false);
  };

  const deleteFavourite = async (symbol) => {
    setSelectedSymbol(symbol);
    setDeleting(true);
    await axios
      .delete(
        `https://api.whaletracer.com/user/removeFavourite/${user.user_id}/${symbol}`
      )
      .then((res) => {
        console.log(res);
      });
    setFavAdded(!favAdded);
    setDeleting(false);
  };

  useEffect(() => {
    axios
      .get(`https://api.whaletracer.com/user/getFavourites/${user_id}`)
      .then((res) => {
        setFavs(res.data.data);
        setFavsLoaded(res.data.success);
      });

    setDataLoading(false);
  }, [favAdded]);

  if (dataLoading && favsLoaded) {
    return <h1>Data is loading...</h1>;
  }

  return (
    <div className="">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-700 ">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-[#B0EEFD] sm:pl-6"
                    >
                      CURRENCY
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pr-3 text-left text-sm font-semibold text-[#B0EEFD]"
                    >
                      SYMBOL
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-[#B0EEFD]  cursor-pointer"
                      onClick={() => filter("current_price")}
                    >
                      <span className="flex items-center">
                        PRICE (USD){" "}
                        <FilterIcon className="w-6 ml-1 object-contain" />
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-[#B0EEFD] cursor-pointer"
                      onClick={() => filter("volume")}
                    >
                      <span className="flex items-center">
                        VOLUME
                        <FilterIcon className="w-6 ml-1 object-contain" />
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-[#B0EEFD] cursor-pointer"
                      onClick={() => filter("current_price")}
                    >
                      <span className="flex items-center">
                        RANK
                        <FilterIcon className="w-6 ml-1 object-contain" />
                      </span>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-gray-800">
                  {coins.map((coin) => (
                    <tr key={coin.rank}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={coin.image_url}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-bold text-md text-white">
                              {coin.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-sm text-gray-500">
                        <div className="text-white font-bold">
                          {coin.symbol}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-white font-bold">
                          ${coin.current_price}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-white font-bold">
                          ${coin.volume}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex font-bold text-white">
                          {coin.rank}
                        </span>
                      </td>
                      <td className="text-center">
                        {favs?.includes(coin.symbol) ? (
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none"
                            onClick={() => deleteFavourite(coin.symbol)}
                          >
                            {deleting && selectedSymbol === coin.symbol
                              ? "Unfollowing..."
                              : "Unfollow"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                            onClick={() => addFavourite(coin.symbol)}
                          >
                            {adding && selectedSymbol === coin.symbol
                              ? "Following... "
                              : "Follow"}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
