import React from "react";

const DexFilters = ({ filter, type }) => {
  const activeStyle =
    "inline-flex items-center rounded border border-transparent bg-[#B0EEFD] px-2.5 py-1.5 text-xs font-medium text-black shadow-sm focus:outline-none";
  const inactiveStyle =
    "inline-flex items-center rounded border border-[#B0EEFD] px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-[#B0EEFD] hover:text-black focus:outline-none";

  return (
    <div className="flex gap-x-2 items-center ">
      <button
        type="button"
        className={type === "" ? activeStyle : inactiveStyle}
        onClick={() => filter("")}
      >
        All
      </button>
      <button
        type="button"
        className={type === "Whale" ? activeStyle : inactiveStyle}
        onClick={() => filter("Whale")}
      >
        Whale
      </button>
      <button
        type="button"
        className={type === "Shark" ? activeStyle : inactiveStyle}
        onClick={() => filter("Shark")}
      >
        Shark
      </button>
      <button
        type="button"
        className={type === "Dolphin" ? activeStyle : inactiveStyle}
        onClick={() => filter("Dolphin")}
      >
        Dolphin
      </button>
      <button
        type="button"
        className={type === "bot" ? activeStyle : inactiveStyle}
        onClick={() => filter("bot")}
      >
        Bot
      </button>
    </div>
  );
};

export default DexFilters;
