import React from "react";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TwitterIcon from "@mui/icons-material/Twitter";

const TableView = (props) => {
  const launchButton = {
    color: "#8edafa",
    fontWeight: "900",
    height: "5px",
  };
  const boughtText = {
    color: "#ED5872",
    fontWeight: "900",
    height: "5px",
  };
  const soldText = {
    color: "#41BE85",
    fontWeight: "900",
    height: "5px",
  };

  const exchangeColor = (exchange) => {
    if (exchange === "Binance") {
      return {
        color: "#F0B90B",
        fontWeight: "600",
        height: "5px",
      };
    }
    if (exchange === "Kucoin") {
      return {
        color: "#23AF91",
        fontWeight: "600",
        height: "5px",
      };
    }
    if (exchange === "FTX") {
      return {
        color: "#00A7C3",
        fontWeight: "600",
        height: "5px",
      };
    }
  };

  const exchangeIcon = (
    <Icon>
      <img alt="edit" src={props.exchangeLogo} className="w-8 object-contain" />
    </Icon>
  );

  const affiliateLink = `http://www.binance.com/en/trade/${props.baseAsset}_${props.quoteAsset}?ref=16771072&type=spot/`;
  const coinDetail = `/coin-details/${props.symbol}`;

  return (
    <Link to={coinDetail} style={{ textDecoration: "none", color: "white" }}>
      <div className="flex items-center bg-[#292644] p-2 rounded-md border-4 mb-2 border-[#403e66] shadow-2xl mx-auto">
        <div className="flex items-center mr-8">
          <img src={props.image} alt="btc" className="w-8 object-contain" />
          <p className="text-sm font-bold ml-1">{props.symbol}</p>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300 ml-2">Pair</span>
          <div className="">
            <a
              href="%"
              target="_blank"
              style={{
                textDecoration: "none",
                fontWeight: "900",
              }}
              rel="noreferrer"
            >
              <Button
                variant="text"
                endIcon={<LaunchIcon />}
                style={launchButton}
              >
                {props.pair}
              </Button>
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300 ml-1">Exchange</span>
          <div className="">
            <a
              href={affiliateLink}
              target="_blank"
              style={{
                textDecoration: "none",
                fontWeight: "900",
              }}
              rel="noreferrer"
            >
              <Button
                variant="text"
                startIcon={exchangeIcon}
                style={exchangeColor(props.exchange)}
              >
                {props.exchange}
              </Button>
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300 ml-1">
            {props.isBuyer ? "Sold" : "Bought"}
          </span>
          <div className="">
            <a
              href="%"
              target="_blank"
              style={{
                textDecoration: "none",
                fontWeight: "900",
              }}
              rel="noreferrer"
            >
              <Button
                variant="text"
                startIcon={
                  props.isBuyer ? (
                    <TrendingDownIcon style={{ color: "#ED5872" }} />
                  ) : (
                    <TrendingUpIcon style={{ color: "#41BE85" }} />
                  )
                }
                style={props.isBuyer ? boughtText : soldText}
              >
                {props.quantity}
              </Button>
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300">Current Price</span>
          <div className="">
            <span className="text-sm font-bold">
              {props.price} {props.quoteAsset}
            </span>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300">Trade Value</span>
          <div className="text-sm font-bold">
            <span className="">${Math.round(props.cost * 1000) / 1000}</span>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-xs text-slate-300">Time</span>
          <div className="text-sm font-bold">
            <span className="">{props.time}</span>
          </div>
        </div>
        <div>
          <Button variant="contained" startIcon={<TwitterIcon />}>
            Share
          </Button>
        </div>
      </div>
    </Link>
  );
};

export default TableView;
