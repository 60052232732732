import React, { useState, useEffect } from "react";
import axios from "axios";
import DexFilters from "../DexFilters";
import FavWalletCard from "../FavWalletCard";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";

const Wallet = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [walletsLoaded, setWalletsLoaded] = useState(false);
  const [walletType, setWalletType] = useState("");

  const filterType = (val) => {
    setWalletType(val);
  };

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `https://api.whaletracer.com/user/getFavouriteWallets/${user_id}`
        );

        setWallets(res.data.data);
        setWalletsLoaded(res.data.success);
      } catch (err) {
        console.log("Error fetching data", err); // handle errors here if needed
      }
    }

    fetchData();
    setDataLoading(false);
  }, []);

  const filteredWallets = wallets.filter((wallet) =>
    wallet.type.includes(walletType)
  );

  if (dataLoading) {
    return (
      <p className="text-center text-white mt-2 font-bold text-sm">
        Loading trades data...
      </p>
    );
  }
  return (
    <>
      <div className="text-white max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] font-sans mb-12 mt-12">
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="min-w-0 flex-1 mb-2 sm:mb-0">
            <h2 className="text-2xl font-bold leading-7 text-[#B0EEFD] sm:truncate sm:text-3xl sm:tracking-tight">
              DEX WALLETS
            </h2>
            <span className="text-xs">The wallets added for tracking.</span>
          </div>
          <DexFilters filter={filterType} type={walletType} />
        </div>
        <div className="border-t border-[#B0EEFD] mt-2"></div>
        <div className="mt-4">
          {walletsLoaded && wallets.length === 0 ? (
            <p>
              You do not have any wallets in your favourties. Add wallets to
              favourites{" "}
              <Link to="/dex-activity" className="text-[#B0EEFD] font-bold">
                here
              </Link>
              .
            </p>
          ) : (
            <div
              className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
              id="tradeCards"
            >
              {filteredWallets.map((wallet) => (
                <FavWalletCard
                  id={wallet.id}
                  userid={wallet.userid}
                  wallet={wallet.wallet}
                  title={wallet.title}
                  type={wallet.type}
                  time={wallet.time}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wallet;
