import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import Fade from "react-reveal/Fade";

import { Link } from "react-router-dom";

import { Container } from "./Container";
import { Logomark } from "./Logo";
import { SecondaryFeatures } from "./Secondary";
import { CallToAction } from "./CTA";
import { Faqs } from "./Faq";

import Footer from "../../components/Footer/Footer";

import usePrice from "../../hooks/usePrice";
import { PageView, initGA } from "../../components/Tracking/Tracking";

const plans = [
  {
    name: "MEMBER",
    featured: false,
    price: { Monthly: "Free", Annually: "$499.99" },
    description:
      "Open the most advanced features to level up your analytical DYOR. Find the hidden gems with easy filters.",
    button: {
      label: "Register",
      href: "/register",
    },
    features: [
      "CEX Currency Detail Page & Historic Data",
      "CEX Advance Filters (Time, Exchange, Value)",
      "DEX Trade detail Info with Hash & Wallet Address",
      "DEX Advance Filters (Tnx Value, Wallet Type)",
      "CEX Historic Trending 7 & 30 Days",
    ],
    logomarkClassName: "fill-cyan-500",
  },
  {
    name: "DEVELOPER",
    featured: false,
    price: { Monthly: "-", Annually: "-" },
    description: "You're geek like us? Use our API to create something cool!",
    button: {
      label: "Tak To Our Sales Team",
      href: "/contact",
    },
    features: [
      "CEX Whale Trades Data From Top 4 Exchanges",
      "DEX SWAPs Data From Three Popular Chains",
      "Daily, Weekly, Monthly CEX Trending Data",
      "Exchange Ranking By Whale Trades",
      "CEX Biggest Buy & Sell Of The Day",
    ],
    logomarkClassName: "fill-cyan-500",
  },
];

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  featured = false,
  activePeriod,
  logomarkClassName,
}) {
  return (
    <section
      className={clsx(
        "flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5",
        featured
          ? "order-first bg-gray-900 lg:order-none"
          : "bg-[#292644] rounded-md border-8 border-[#403e66]"
      )}
    >
      <h3
        className={clsx(
          "flex items-center text-lg font-extrabold capitalize",
          featured ? "text-white" : "text-white"
        )}
      >
        <Logomark className={clsx("h-6 w-6 flex-none", logomarkClassName)} />
        <span className="ml-4">{name}</span>
      </h3>
      <p
        className={clsx(
          "relative mt-5 flex text-3xl tracking-tight",
          featured ? "text-white" : "text-white"
        )}
      >
        {price.Monthly === price.Annually ? (
          <span className="transition duration-300 font-bold">
            {price.Monthly}
          </span>
        ) : (
          <>
            <span
              aria-hidden={activePeriod === "Annually"}
              className={clsx(
                "transition duration-300 font-bold",
                activePeriod === "Annually" &&
                  "pointer-events-none translate-x-6 select-none opacity-0 font-bold"
              )}
            >
              {price.Monthly}
            </span>
            <span
              aria-hidden={activePeriod === "Monthly"}
              className={clsx(
                "absolute left-0 top-0 transition duration-300 font-bold",
                activePeriod === "Monthly" &&
                  "pointer-events-none -translate-x-6 select-none opacity-0 font-bold"
              )}
            >
              {price.Annually}{" "}
              <span className="text-sm  text-cyan-500">( Save 17% )</span>
            </span>
          </>
        )}
      </p>
      <p
        className={clsx(
          "mt-3 text-sm",
          featured ? "text-gray-300" : "text-slate-300"
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        <ul className={clsx("-my-2 text-sm")}>
          {features.map((feature) => (
            <li key={feature} className="flex py-2 items-center">
              <CheckIcon
                className={clsx(
                  "h-6 w-6 flex-none",
                  featured ? "text-white" : "text-cyan-500"
                )}
              />
              <span className="ml-4 text-white text-xs">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <Link to={button.href}>
        <button
          className="bg-[#6DCDF5] w-full mt-2 px-4 py-2 rounded text-xs font-bold text-black"
          aria-label={`Get started with the ${name} plan for ${price}`}
        >
          {button.label}
        </button>
      </Link>
    </section>
  );
}

const Pricing = () => {
  let [activePeriod, setActivePeriod] = useState("Monthly");
  const scroll = useRef(null);
  const executeScroll = () => scroll.current.scrollIntoView();
  const { setPrice } = usePrice();

  useEffect(() => {
    initGA("UA-237366808-1");
    PageView();
    executeScroll();
    if (activePeriod === "Annually") {
      setPrice({
        price: 49900,
        extraCharges: 1580,
        price_id: "price_1LZugPGyZ2llQ1ZpE1SxJKJU",
      });

      localStorage.setItem("price", 49999);
      localStorage.setItem("extra", 1447);
      localStorage.setItem("price_id", "price_1LbqQjKt9hT0i8gYBeV56RRL");
    } else {
      setPrice({
        price: 4900,
        extraCharges: 158,
        price_id: "price_1LZuzxGyZ2llQ1ZpOd6RJIFh",
      });
      localStorage.setItem("price", 4999);
      localStorage.setItem("extra", 176);
      localStorage.setItem("price_id", "price_1LbqOzKt9hT0i8gYgPCpnAdF");
    }
  }, [activePeriod]);

  return (
    <div ref={scroll}>
      <Fade bottom>
        <>
          <section
            id="pricing"
            aria-labelledby="pricing-title"
            className="py-2 sm:py-2 max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] md:mt-24 mt-14 font-sans"
          >
            <Container>
              <div className="mx-auto max-w-2xl text-center">
                <h2
                  id="pricing-title"
                  className="text-4xl font-black tracking-tight text-white"
                >
                  FOLLOW THE WHALES, FOLLOW THE SMART MONEY.{" "}
                </h2>
                <p className="mt-4 text-lg text-gray-300">
                  Uncover the Whale Activity like Fisherman's NAVIGATOR does.
                  See what VC's aka Whales are planning next and find the next
                  Solana or Avalanche that's bought under a 1$ and sold over
                  100$.
                </p>
              </div>

              {/* <div className="mt-12 flex justify-center">
                <div className="relative">
                  <RadioGroup
                    value={activePeriod}
                    onChange={setActivePeriod}
                    className="grid grid-cols-2"
                  >
                    {["Monthly", "Annually"].map((period) => (
                      <RadioGroup.Option
                        key={period}
                        value={period}
                        className={clsx(
                          "cursor-pointer border-0.5 border-gray-300 py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm text-white outline-2 outline-offset-2 transition-colors hover:border-gray-400",
                          period === "Monthly"
                            ? "rounded-l-lg"
                            : "-ml-px rounded-r-lg"
                        )}
                      >
                        {period}
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                  <div
                    aria-hidden="true"
                    className={clsx(
                      "pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg bg-[#B0EEFD] transition-all duration-300",
                      activePeriod === "Monthly"
                        ? "[clip-path:inset(0_50%_0_0)]"
                        : "[clip-path:inset(0_0_0_calc(50%-1px))]"
                    )}
                  >
                    {["Monthly", "Annually"].map((period) => (
                      <div
                        key={period}
                        className={clsx(
                          "py-2 text-center text-sm font-semibold text-black [&:not(:focus-visible)]:focus:outline-none",
                          period === "Annually" && "-ml-px"
                        )}
                      >
                        {period}
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
              {/* <div className="text-center mx-auto mt-4">
                <p className="text-sm text-cyan-500">
                  <span className="underline">Save 17% </span>on our Annual plan
                </p>
              </div> */}
              <div className="mx-auto mt-12 grid grid-cols-1 items-start justify-center gap-x-8 gap-y-10 sm:mt-12 lg:max-w-none lg:grid-cols-2">
                {plans.map((plan) => (
                  <Plan key={plan.name} {...plan} activePeriod={activePeriod} />
                ))}
              </div>
            </Container>
            <SecondaryFeatures />
          </section>
          <CallToAction />
          <Faqs />
        </>
      </Fade>
      <Footer />
    </div>
  );
};

export default Pricing;
