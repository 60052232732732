import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

export default function AddFavourite({ wallet, open, close, add, adding }) {
  const [walletTitle, setWalletTitle] = useState("");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-700 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white mb-2"
                    >
                      Add the Wallet to your Favourites
                    </Dialog.Title>
                    <div className="text-white mx-auto mt-4">
                      <span className="inline-flex items-center rounded-md bg-[#B0EEFD] px-2.5 py-0.5 text-sm font-medium text-gray-800">
                        {wallet.slice(0, 15) + "..." + wallet.slice(35, 42)}
                      </span>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-left text-white"
                      >
                        Wallet Title
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          name="name"
                          type="name"
                          autoComplete="name"
                          required
                          placeholder="Enter a title for this wallet..."
                          onChange={(e) => setWalletTitle(e.target.value)}
                          className="appearance-none block w-60 sm:w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="w-full rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                    onClick={() => add(wallet, walletTitle)}
                  >
                    {adding
                      ? "Adding Wallet to Favourite..."
                      : "Add Wallet to Favourites"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
