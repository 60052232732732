import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

export default function Unauthorized() {
  return (
    <>
      <div className="min-h-full pt-0 sm:pt-0 pb-12 flex flex-col font-sans">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-2 lg:px-4">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                className="object-contain w-36 md:w-36"
                src="https://cdn-icons-png.flaticon.com/512/8216/8216221.png"
                alt="Workflow"
              />
            </a>
          </div>
          <div className="py-8">
            <div className="text-center">
              <p className="font-bold text-5xl text-[#8EDAFA]">
                Unlock with Premium
              </p>

              <p className="mt-2 text-base text-gray-300">
                Become a premium user today.
              </p>
              <div className="mt-6">
                <Link
                  to="/pricing"
                  className="text-base font-medium text-[#8EDAFA] hover:text-indigo-500"
                >
                  <button
                    type="button"
                    className="inline-flex  items-center px-6 py-2 text-lg border border-transparent shadow-sm  font-medium rounded-md text-black bg-[#B0EEFD]"
                  >
                    {" "}
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
