import { Fragment } from "react";

import { Link } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { useState } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import { Menu, Transition } from "@headlessui/react";

const useAnalytics = () => {
  const link = useLocation();
  const activePage = link.pathname;
  return activePage;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Container({ className, ...props }) {
  return <div className={clsx("mx-auto px-4 lg:px-0", className)} {...props} />;
}

function NavLinks() {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  const navigation = [
    { name: "Home", href: "/", current: useAnalytics() === "/" },
    {
      name: "Whale Tracker (CEX)",
      href: "/cex-activity",
      current: useAnalytics() === "/cex-activity",
    },
    {
      name: "Whale Tracker (DEX)",
      href: "/dex-activity",
      current: useAnalytics() === "/dex-activity",
    },
    {
      name: "Trending",
      href: "/trending",
      current: useAnalytics() === "/trending",
    },
    {
      name: "Ranking",
      href: "/ranking",
      current: useAnalytics() === "/ranking",
    },
    {
      name: "Become a Member",
      href: "/pricing",
      current: useAnalytics() === "/pricing",
    },
    {
      name: "How it Works",
      href: "/how-it-works",
      current: useAnalytics() === "/how-it-works",
    },
  ];

  return navigation.map((element, index) => (
    <Link
      key={element.name}
      to={element.href}
      className="relative -my-2 -mx-3 rounded-lg px-2 py-2   delay-150 "
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded-lg bg-gray-900/80"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </AnimatePresence>
      <span
        className={
          element.current
            ? "relative  text-[#B0EEFD] font-black text-xs 2xl:text-sm"
            : "relative text-white text-xs 2xl:text-sm"
        }
      >
        {element.name}
      </span>
    </Link>
  ));
}

function UserNavLinks() {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  const navigation = [
    { name: "Home", href: "/", current: useAnalytics() === "/" },
    {
      name: "Whale Tracker (CEX)",
      href: "/cex-activity",
      current: useAnalytics() === "/cex-activity",
    },
    {
      name: "Whale Tracker (DEX)",
      href: "/dex-activity",
      current: useAnalytics() === "/dex-activity",
    },
    {
      name: "Trending",
      href: "/trending",
      current: useAnalytics() === "/trending",
    },
    {
      name: "Ranking",
      href: "/ranking",
      current: useAnalytics() === "/ranking",
    },

    {
      name: "How it Works",
      href: "/how-it-works",
      current: useAnalytics() === "/how-it-works",
    },
  ];

  return navigation.map((element, index) => (
    <Link
      key={element.name}
      to={element.href}
      className="relative -my-2 -mx-3 rounded-lg px-2 py-2   delay-150 "
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded-lg bg-gray-900/80"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </AnimatePresence>
      <span
        className={
          element.current
            ? "relative  text-[#B0EEFD] font-black text-xs 2xl:text-sm"
            : "relative text-white text-xs 2xl:text-sm"
        }
      >
        {element.name}
      </span>
    </Link>
  ));
}

function MobileNavLink({ children, ...props }) {
  return (
    <Popover.Button
      as={Link}
      className="block text-base leading-7 tracking-tight text-white"
      {...props}
    >
      {children}
    </Popover.Button>
  );
}

const Navigation = (props) => {
  const { auth } = useAuth();
  const { user } = useUser();

  const checkAuth =
    auth &&
    (user?.membership === "pro" ||
      localStorage.getItem("membership") === "pro");
  return (
    <header className="bg-[#182038]">
      <nav className="max-w-wt 2xl:max-w-ft mx-auto">
        <Container className="relative z-50  flex justify-between py-4 sm:py-2 drop-shadow-2xl">
          <div className="relative z-10 sm:z-0 flex items-center gap-16 px-0">
            <Link to="/" aria-label="Home">
              <img
                className="object-contain w-36 md:w-56 z-50"
                src="https://i.stack.imgur.com/iGBaP.png"
                alt="Workflow"
              />
            </Link>
            <div className="hidden lg:flex lg:gap-10">
              {auth ? <UserNavLinks /> : <NavLinks />}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 inline-flex items-center rounded-lg "
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <XIcon
                          className="block h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-600/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-[#182038] px-6 pb-6  pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4 text-white">
                            <MobileNavLink to="/">Home</MobileNavLink>
                            <MobileNavLink to="/cex-activity">
                              CEX Activity
                            </MobileNavLink>
                            <MobileNavLink to="/dex-activity">
                              DEX Activity
                            </MobileNavLink>
                            <MobileNavLink to="/trending">
                              Trending
                            </MobileNavLink>
                            <MobileNavLink to="/ranking">Ranking</MobileNavLink>
                            {!checkAuth && (
                              <MobileNavLink to="/pricing">
                                Become a Member
                              </MobileNavLink>
                            )}

                            <MobileNavLink to="/how-it-works">
                              How it Works
                            </MobileNavLink>
                          </div>
                          <div className="mt-8 flex flex-col gap-4">
                            {auth ? (
                              <>
                                {" "}
                                <MobileNavLink to="/dashboard">
                                  <p className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    {user?.fullname}
                                  </p>
                                </MobileNavLink>
                                <button
                                  type="button"
                                  className="  p-1 rounded-md text-gray-400 hover:text-white border px-2 border-[#B0EEFD]"
                                  onClick={props.handleLogout}
                                >
                                  Sign out
                                </button>
                              </>
                            ) : (
                              <>
                                <MobileNavLink to="/login">Login</MobileNavLink>
                                <MobileNavLink to="/register">
                                  <button
                                    type="button"
                                    className="bg-[#B0EEFD] px-3 py-1 rounded text-black "
                                  >
                                    Register
                                  </button>
                                </MobileNavLink>
                              </>
                            )}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            {auth ? (
              <div className="hidden lg:block ">
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="flex text-sm rounded-full">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-10 object-contain"
                        src="https://i.stack.imgur.com/Bxv94.png"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="bg-gray-800 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 ">
                      {checkAuth ? (
                        <>
                          <Menu.Item>
                            <Link to="/admin">
                              <p className="block px-4 py-2 text-sm text-white hover:bg-gray-800 hover:text-[#B0EEFD]">
                                Admin Dashboard
                              </p>
                            </Link>
                          </Menu.Item>
                        </>
                      ) : null}
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? "bg-gray-100 cursor-pointer" : "",
                              "block px-4 py-2 text-sm text-white hover:bg-gray-800 hover:text-[#B0EEFD]"
                            )}
                            onClick={props.handleLogout}
                          >
                            Sign out
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <>
                <Link to="/login">
                  <button
                    type="button"
                    className="hidden lg:block p-1 rounded-md text-[#B0EEFD] border px-2 border-[#B0EEFD]"
                  >
                    Login
                  </button>
                </Link>
                <Link to="/register">
                  <button
                    type="button"
                    className="bg-[#B0EEFD] px-3 py-1 rounded text-black hover:text-black hidden lg:block "
                    onClick={props.handleRegisterClick}
                  >
                    Register
                  </button>
                </Link>
              </>
            )}
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Navigation;
