import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Amplify from "@aws-amplify/auth";
import config from "./config.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider.js";
import { DisclaimerProvider } from "./context/Disclaimer.js";
import { SubscriptionProvider } from "./context/Subscription.js";
import { UserProvider } from "./context/UserProvider.js";
import { UpgradeProvider } from "./context/UpgradeProvider.js";
import { PricingProvider } from "./context/PricingProvider";
import { CardProvider } from "./context/CardProvider";

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

ReactDOM.render(
  <BrowserRouter basename="/">
    <AuthProvider>
      <UserProvider>
        <CardProvider>
          <UpgradeProvider>
            <SubscriptionProvider>
              <DisclaimerProvider>
                <PricingProvider>
                  <Routes>
                    <Route path="/*" element={<App />} />
                  </Routes>
                </PricingProvider>
              </DisclaimerProvider>
            </SubscriptionProvider>
          </UpgradeProvider>
        </CardProvider>
      </UserProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
