import React, { useState, useEffect } from "react";
import Axios from "axios";

import Footer from "../../components/Footer/Footer";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const emailBody = {
    email: email,
    subject: subject,
    name: name,
    details: details,
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setSubject("");
    setDetails("");
  };

  const onNameChange = (e) => setName(e.target.value);
  const onEmailChange = (e) => setEmail(e.target.value);
  const onSubjectChange = (e) => setSubject(e.target.value);
  const onDetailsChange = (e) => setDetails(e.target.value);

  const sendEmail = async (e) => {
    e.preventDefault();
    await Axios.post(
      "https://api.whaletracer.com/support/contact-email",
      emailBody
    ).then((res) => {
      setSubscribeSuccess(true);
      clearForm();
    });
  };

  return (
    <div className="font-sans">
      <main className="overflow-hidden max-w-wt 2xl:max-w-ft mx-auto mb-12">
        <div className="bg-warm-gray-50">
          <div className="py-16 lg:py-16">
            <div className="relative z-10 max-w-7xl mx-auto">
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight">
                TALK TO OUR TEAM
              </h1>
              <p className="mt-2 text-lg text-white max-w-3xl">
                While we’re good with smoke signals, there are simpler ways for
                us to get in touch.
              </p>
            </div>
          </div>
        </div>
        <section className="relative" aria-labelledby="contact-heading">
          <Snackbar
            open={subscribeSuccess}
            autoHideDuration={2000}
            onClose={() => setSubscribeSuccess(false)}
          >
            <Alert
              onClose={() => setSubscribeSuccess(false)}
              severity="success"
              sx={{ width: "100%" }}
            >
              Your query has been submitted!
            </Alert>
          </Snackbar>
          <div className="absolute w-full h-1/2" aria-hidden="true" />
          <div className="max-w-7xl mx-auto">
            <div className="relative  shadow-xl">
              <h2 id="contact-heading" className="sr-only">
                Contact us
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-3">
                {/* Contact form */}
                <div className="py-2 lg:col-span-2">
                  <h3 className="text-xl font-medium text-slate-200">
                    Send us a message
                  </h3>
                  <form
                    onSubmit={sendEmail}
                    className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  >
                    <div>
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-slate-200"
                      >
                        Full name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          value={name}
                          onChange={onNameChange}
                          id="first-name"
                          autoComplete="given-name"
                          className="py-3 px-4 block w-full shadow-sm bg-transparent border-0.5 border-[#B0EEFD] text-slate-200 rounded-md"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-slate-200"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          value={email}
                          type="email"
                          autoComplete="email"
                          onChange={onEmailChange}
                          className="py-3 px-4 block w-full shadow-sm text-slate-200 bg-transparent border-0.5 border-[#B0EEFD] rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="subject"
                        className="block text-sm font-medium text-slate-200"
                      >
                        Subject
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="subject"
                          value={subject}
                          id="subject"
                          onChange={onSubjectChange}
                          className="py-3 px-4 block w-full shadow-sm text-slate-200 bg-transparent  border-0.5 border-[#B0EEFD] rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-slate-200"
                        >
                          Message
                        </label>
                        <span
                          id="message-max"
                          className="text-sm text-slate-200"
                        >
                          Max. 500 characters
                        </span>
                      </div>
                      <div className="mt-1">
                        <textarea
                          id="message"
                          name="message"
                          value={details}
                          rows={4}
                          onChange={onDetailsChange}
                          className="py-3 px-4 block w-full shadow-sm text-slate-200 bg-transparent  border-0.5 border-[#B0EEFD] rounded-md"
                          aria-describedby="message-max"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                      <button
                        type="submit"
                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 rounded-md shadow-sm text-base font-medium text-black bg-[#B0EEFD] sm:w-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
