import React, { useState, useEffect } from "react";
import { SearchIcon, FunnelIcon } from "@heroicons/react/solid";
import axios from "axios";
import Table from "../Table";
import Footer from "../../../components/Footer/Footer";

const EditWatchList = () => {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("current_price");
  const [volume, setVolume] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.whaletracer.com/coins/current_price`)
      .then((response) => {
        setCoins(response.data);
      });
    axios.get(`https://api.whaletracer.com/coins/volume`).then((response) => {
      setVolume(response.data);
    });
  }, []);

  const changeFilter = (val) => {
    setFilter(val);
  };

  return (
    <>
      <div className="text-white max-w-wt 2xl:max-w-ft mx-auto lg:w-[1100px] 2xl:w-[1250px] font-sans mb-12 mt-12 ">
        <div className="md:flex md:items-center md:justify-between items-center">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-[#B0EEFD] sm:truncate sm:text-3xl sm:tracking-tight">
              WHALE TRACER COINS LIST
            </h2>
            <span className="text-xs">
              Select the coin you would like to add to your favourite list.
            </span>
          </div>
          {/* <div className="flex  justify-center mt-4 lg:ml-6 lg:justify-end">
            <div className="w-full max-w-lg lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full rounded-md border border-transparent bg-gray-700 py-2 pl-10  text-gray-300 placeholder-gray-400 focus:outline-none focus:text-white focus:bg-gray-700 sm:text-sm"
                  placeholder="Search for Currency"
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div> */}
        </div>
        {filter === "current_price" ? (
          <Table coins={coins} filter={changeFilter} />
        ) : (
          <Table coins={volume} filter={changeFilter} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default EditWatchList;
