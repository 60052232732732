import Alerts from "../pages/Alerts/Alerts";
import Cryptocurrencies from "../pages/Cryptocurrencies/Cryptocurrencies";
import Trending from "../pages/Trending/Trending";
import Dex from "../pages/Dex/Dex";
import Nft from "../pages/Nft/Nft";
import Domain from "../pages/Domain/Domain";
import Defi from "../pages/Defi/Defi";
import TrendingDetails from "../pages/TrendingDetails/TrendingDetails";
import TrendingDex from "../pages/TrendingDetails/TrendingDex";
import Exchanges from "../pages/Exchanges/Exchanges";
import CoinDetail from "../pages/CoinDetail/CoinDetail";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Privacy from "../pages/Privacy/Privacy";
import Terms from "../pages/Terms/Terms";
import Subscription from "../pages/Subscription/Subscription";
import DexTradeDetails from "../pages/DexTradeDetails/DexTradeDetails";
import Pricing from "../pages/Pricing/Pricing";
import PageNotFound from "../pages/404/404";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
// import Payment from "../pages/Payment/Payment";
import ContactUs from "../pages/Contact/ContactUs";
import HowItWorks from "../pages/HowItWorks/HowItWorks";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Register from "../pages/Register/Register";
import Profile from "../pages/Profile/Profile";
import Unsubscribe from "../pages/Unsubscribe/Unsibscribe";
import Admin from "../pages/Admin/Admin";
import Favourites from "../pages/Favourites/Favourites";
import WalletDetails from "../pages/WalletDetails/WalletDetails";
import WatchList from "../pages/Dashboard/WatchList/WatchList";
import Wallet from "../pages/Dashboard/Wallet/Wallet";
import EditWatchList from "../pages/Dashboard/EditWatchList/EditWatchList";
import { Edit } from "@mui/icons-material";
//PUBLIC PAGES

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/privacy-policy", component: Privacy },
  { path: "/terms-of-use", component: Terms },
  { path: "/cex-activity", component: Alerts },
  { path: "/currencies", component: Cryptocurrencies },
  { path: "/trending", component: Trending },
  { path: "/favourites", component: Favourites },
  { path: "/ranking", component: Exchanges },
  { path: "/trending/cex", component: TrendingDetails },
  { path: "/trending/dex", component: TrendingDex },
  { path: "/dex-activity", component: Dex },
  { path: "/nft", component: Nft },
  { path: "/domains", component: Domain },
  { path: "/defi", component: Defi },
  { path: "/subscribe", component: Subscription },
  { path: "/contact", component: ContactUs },
  { path: "/pricing", component: Pricing },
  { path: "/how-it-works", component: HowItWorks },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/pagenotfound", component: PageNotFound },
  { path: "/unauthorized", component: Unauthorized },
  { path: "/profile", component: Profile },
  { path: "/unsubscribe", component: Unsubscribe },
  { path: "*", component: PageNotFound },
  { path: "/coin-details/:symbol", component: CoinDetail },
  { path: "/dex-activity/dex-trade/:id/:wallet", component: DexTradeDetails },
  { path: "/dashboard/watchlist", component: WatchList },
  { path: "/dashboard/wallets", component: Wallet },
  { path: "/dashboard/editwatchlist", component: EditWatchList },

  { path: "/wallet/:id", component: WalletDetails },
];

const proRoutes = [{ path: "/admin", component: Admin }];

export { publicRoutes, proRoutes };
