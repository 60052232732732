import React, { useState, useEffect } from "react";
import "./Subscription.css";

import DoneIcon from "@mui/icons-material/Done";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Subscription = () => {
  const [yearly, setYearly] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setSubsDuration = () => {
    setYearly(!yearly);
  };

  const buttonStyle = {
    backgroundColor: "#B0EEFD",
    borderRadius: "10px",
    color: "black",
    width: "300px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#B0EEFD",
    },
  };

  return (
    <div className="text-white mt-8 w-97 mx-auto font-sans">
      <div className="subs-header">
        <h1 className="text-2xl font-bold leading-8 text-white md:text-5xl md:font-extrabold">
          BECOME A PRO USER
        </h1>
        <p className="mb-8 mt-2 text-md text-slate-200 max-w-3xl dark:text-slate-400 ">
          Join our <span style={{ fontWeight: "900" }}> Pro Membership </span>{" "}
          and get access to our amazing features & detailed informations on CEX
          & DEX trades. Stay updated with the latest of Crypto Space.
        </p>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="">
          <h1 className="text-md font-bold text-white md:text-3xl md:font-extrabold">
            PRO MEMBER FEATURES
          </h1>
          <div className="">
            <div className="flex items-center">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                CEX All Latest & Historic Trade Data
              </p>
            </div>
            <div className="flex items-center">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                DEX All Latest & Historic Trade Data
              </p>
            </div>
            <div className="flex items-center">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                Trending Top 10 - Most Traded Crypto over CEX & DEX
              </p>
            </div>
            <div className="flex items-center">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                Data Filters for Exchanges, Chains, Trade Value, Trade Time &
                Crypto Currencies
              </p>
            </div>
            <div className="flex items-center">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                Biggest Buy & Sell of 24H over all popular CEX.
              </p>
            </div>
          </div>
        </div>
        <div className="md:ml-8 md:mt-0 mt-4">
          <h1 className="text-md font-bold text-white md:text-3xl md:font-extrabold">
            FREE MEMBER FEATURES
          </h1>
          <div className="subs-detail-points">
            <div className="subs-detail-point">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">CEX 12 Latest Trades</p>
            </div>
            <div className="subs-detail-point">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">DEX 12 Latest Trades</p>
            </div>
            <div className="subs-detail-point">
              <DoneIcon />
              <p className="text-sm ml-2 md:text-lg">
                Trending Top 3 - Most Traded Crypto over CEX & DEX
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-sm ml-2 md:text-xl">
          Billing cycle: <span className="font-bold">Monthly</span>{" "}
          <Switch onClick={setSubsDuration} />
          <span style={{ fontWeight: "800" }}>Yearly</span> (save 10%)
        </p>
        <p className="text-4xl font-bold md:text-6xl">
          ${yearly ? "540.00" : "50.00"}{" "}
          <span style={{ fontSize: "18px", fontWeight: "300" }}>
            /{yearly ? "Year" : "Month"}
          </span>
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="contained" sx={buttonStyle}>
            Get Started
          </Button>
          <p style={{ marginLeft: "20px" }}>
            Or try{" "}
            <Link to="/home" style={{ textDecoration: "none", color: "white" }}>
              <span style={{ fontWeight: "800", textDecoration: "underline" }}>
                Free Version
              </span>
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subscription;
