import React from "react";
import PropTypes from "prop-types";
import "./Frame.css";

const YoutubeEmbed = ({ embedId, title, subtext }) => (
  <div className="mt-8 text-center sm:text-left">
    <iframe
      className="frame-dim"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
    <p className="text-white text-xl text-extrabold mt-4 md:text-lg">
      {" "}
      {subtext}
    </p>
    <h3 className="text-slate-400 text-lg md:text-sm"> {title} </h3>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
