import { timeToGo } from "../../../../services/GeneralFunctions";
import Axios from "axios";

export default function Table(props) {
  const deleteUser = async (email) => {
    try {
      await Axios.delete(
        `https://api.whaletracer.com/user/deleteUser/${email}`
      ).then((res) => {
        console.log(res);
        props.toggleDelete();
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="mt-2">
      <div className="inline-block min-w-full">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="divide-y divide-gray-700 lg:w-[1100px] 2xl:w-[1250px]">
            <thead className="bg-[rgb(27,33,55)]">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Last Active
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Registered
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="px-3 ml-2 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-[#292644]">
              {props.data.map((person) => (
                <tr key={person.email}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-300 sm:pl-6">
                    {person.fullname}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300">
                    {person.email}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300">
                    {timeToGo(person.last_active)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300">
                    {timeToGo(person.register_date)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-300">
                    {person.membership === "pro" ? (
                      <span className="inline-flex items-center rounded-sm bg-indigo-500 px-3 py-0.5 text-sm font-medium text-white">
                        Admin
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-sm bg-[#B0EEFD] px-3 py-0.5 text-sm font-medium text-black">
                        Member
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-slate-300 text-right pr-12">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm"
                      onClick={() => deleteUser(person.email)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
