import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/smallLogo.png";

import BarChart from "../../components/BarChart/BarChart";

import TradesCard from "../../components/TradesCard/TradesCard";
import { timeToGo, secondsTimeFilter } from "../../services/GeneralFunctions";

import millify from "millify";

const CoinDetail = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [trades, setTrades] = useState([]);
  const [coin, setCoin] = useState([]);
  const [totalStats, setTotalStats] = useState([]);
  const [monthlyTrades, setMonthlyTrades] = useState([]);
  const [weeklyTrades, setWeeklyTrades] = useState([]);
  const [threeDayTrades, setThreeDayTrades] = useState([]);
  const [filter, setFilter] = useState(7);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [favs, setFavs] = useState([]);
  const [favAdded, setFavAdded] = useState(false);

  const params = useParams();

  const { auth } = useAuth();

  var symbol = JSON.stringify(params.symbol);
  symbol = symbol.replace(/"/g, "").toLowerCase();

  const twitterHref = (
    baseAsset,
    quoteAsset,
    tradeValue,
    qty,
    exchange,
    side
  ) => {
    return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (CEX) %0A %0A${qty} $${baseAsset} has been ${
      side ? "sold" : "purchased"
    } for $${tradeValue} using $${quoteAsset} on ${exchange}. %0A %0ACheck Out Whale Trades At: WhaleTracer.com %0A %0A %23${baseAsset} %23${quoteAsset}  %23${exchange.toUpperCase()} %23WHALETRACER`;
  };

  const user_id = localStorage.getItem("user_id");

  const addFavourtie = async (symbol) => {
    setAdding(true);
    await axios
      .post(
        `https://api.whaletracer.com/user/addFavourite/${user_id}/${symbol}`
      )
      .then((res) => {
        console.log(res);
      });
    setFavAdded(!favAdded);
    setAdding(false);
  };

  const deleteFavourite = async (symbol) => {
    setDeleting(true);
    await axios
      .delete(
        `https://api.whaletracer.com/user/removeFavourite/${user_id}/${symbol}`
      )
      .then((res) => {
        console.log(res);
      });
    setFavAdded(!favAdded);
    setDeleting(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("https://api.whaletracer.com/trades/null/1/null/null/24")
      .then((response) => {
        setTrades(response.data);
      });
    axios
      .get(`https://api.whaletracer.com/coins/details/${symbol}`)
      .then((response) => {
        setCoin(response.data[0]);
      });

    axios
      .get(
        `https://api.whaletracer.com/cex-trades/total-weekly-stats/${symbol.toUpperCase()}`
      )
      .then((response) => {
        setTotalStats(response.data);
      });
    axios
      .get(
        `https://api.whaletracer.com/cex-trades/past-trades/${symbol.toUpperCase()}/30`
      )
      .then((response) => {
        setMonthlyTrades(response.data);
      });
    axios
      .get(
        `https://api.whaletracer.com/cex-trades/past-trades/${symbol.toUpperCase()}/7`
      )
      .then((response) => {
        setWeeklyTrades(response.data);
      });
    axios
      .get(
        `https://api.whaletracer.com/cex-trades/past-trades/${symbol.toUpperCase()}/3`
      )
      .then((response) => {
        setThreeDayTrades(response.data);
      });
    axios
      .get(
        `https://api.whaletracer.com/cex-trades/past-trades/${symbol.toUpperCase()}/30`
      )
      .then((response) => {
        setMonthlyTrades(response.data);
      });

    axios
      .get(`https://api.whaletracer.com/user/getFavourites/${user_id}`)
      .then((res) => {
        setFavs(res.data.data);
      });
    setDataLoading(false);
  }, [symbol, favAdded, user_id]);

  const timeConverter = (date) => {
    const a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var month = months[a.getMonth()];
    var day = a.getDate();
    var time = day + " " + month;
    return time;
  };

  let filteredTrades = trades.filter(
    (trade) => trade.trade_time > secondsTimeFilter("all")
  );

  filteredTrades = filteredTrades.filter((trade) =>
    trade.exchange_name.includes("")
  );

  filteredTrades = filteredTrades.filter(
    (trade) => trade.base_asset_trade === `${symbol.toUpperCase()}`
  );

  if (
    dataLoading ||
    trades.length === 0 ||
    coin.length === 0 ||
    monthlyTrades.length === 0 ||
    weeklyTrades.length === 0 ||
    threeDayTrades.length === 0 ||
    totalStats.length === 0
  ) {
    return (
      <div className="my-48 2xl:my-64">
        <img src={Logo} className="w-36 mx-auto " alt="loading" />
        <p className="text-center text-white mt-2 font-bold text-lg">
          Loading trades data...
        </p>
      </div>
    );
  }

  const monthlyChartData = {
    labels: [
      timeConverter(monthlyTrades[29].date),
      timeConverter(monthlyTrades[28].date),
      timeConverter(monthlyTrades[27].date),
      timeConverter(monthlyTrades[26].date),
      timeConverter(monthlyTrades[25].date),
      timeConverter(monthlyTrades[24].date),
      timeConverter(monthlyTrades[23].date),
      timeConverter(monthlyTrades[22].date),
      timeConverter(monthlyTrades[21].date),
      timeConverter(monthlyTrades[20].date),
      timeConverter(monthlyTrades[19].date),
      timeConverter(monthlyTrades[18].date),
      timeConverter(monthlyTrades[17].date),
      timeConverter(monthlyTrades[16].date),
      timeConverter(monthlyTrades[15].date),
      timeConverter(monthlyTrades[14].date),
      timeConverter(monthlyTrades[13].date),
      timeConverter(monthlyTrades[12].date),
      timeConverter(monthlyTrades[11].date),
      timeConverter(monthlyTrades[10].date),
      timeConverter(monthlyTrades[9].date),
      timeConverter(monthlyTrades[8].date),
      timeConverter(monthlyTrades[7].date),
      timeConverter(monthlyTrades[6].date),
      timeConverter(monthlyTrades[5].date),
      timeConverter(monthlyTrades[4].date),
      timeConverter(monthlyTrades[3].date),
      timeConverter(monthlyTrades[2].date),
      timeConverter(monthlyTrades[1].date),
      timeConverter(monthlyTrades[0].date),
    ],
    datasets: [
      {
        type: "line",
        label: "Sold",
        data: [
          monthlyTrades[29].sold,
          monthlyTrades[28].sold,
          monthlyTrades[27].sold,
          monthlyTrades[26].sold,
          monthlyTrades[25].sold,
          monthlyTrades[24].sold,
          monthlyTrades[23].sold,
          monthlyTrades[22].sold,
          monthlyTrades[21].sold,
          monthlyTrades[20].sold,
          monthlyTrades[19].sold,
          monthlyTrades[18].sold,
          monthlyTrades[17].sold,
          monthlyTrades[16].sold,
          monthlyTrades[15].sold,
          monthlyTrades[14].sold,
          monthlyTrades[13].sold,
          monthlyTrades[12].sold,
          monthlyTrades[11].sold,
          monthlyTrades[10].sold,
          monthlyTrades[9].sold,
          monthlyTrades[8].sold,
          monthlyTrades[7].sold,
          monthlyTrades[6].sold,
          monthlyTrades[5].sold,
          monthlyTrades[4].sold,
          monthlyTrades[3].sold,
          monthlyTrades[2].sold,
          monthlyTrades[1].sold,
          monthlyTrades[0].sold,
        ],
        fill: false,
        borderColor: "rgb(235, 64, 104)",
        backgroundColor: "rgba(235, 64, 104)",
      },
      {
        type: "line",
        label: "Bought",
        data: [
          monthlyTrades[29].bought,
          monthlyTrades[28].bought,
          monthlyTrades[27].bought,
          monthlyTrades[26].bought,
          monthlyTrades[25].bought,
          monthlyTrades[24].bought,
          monthlyTrades[23].bought,
          monthlyTrades[22].bought,
          monthlyTrades[21].bought,
          monthlyTrades[20].bought,
          monthlyTrades[19].bought,
          monthlyTrades[18].bought,
          monthlyTrades[17].bought,
          monthlyTrades[16].bought,
          monthlyTrades[15].bought,
          monthlyTrades[14].bought,
          monthlyTrades[13].bought,
          monthlyTrades[12].bought,
          monthlyTrades[11].bought,
          monthlyTrades[10].bought,
          monthlyTrades[9].bought,
          monthlyTrades[8].bought,
          monthlyTrades[7].bought,
          monthlyTrades[6].bought,
          monthlyTrades[5].bought,
          monthlyTrades[4].bought,
          monthlyTrades[3].bought,
          monthlyTrades[2].bought,
          monthlyTrades[1].bought,
          monthlyTrades[0].bought,
        ],
        fill: false,

        borderColor: "rgb(83, 228, 148)",
        backgroundColor: "rgba(83, 228, 148)",
      },
      {
        type: "bar",
        label: "Total Trades",
        data: [
          monthlyTrades[29].total,
          monthlyTrades[28].total,
          monthlyTrades[27].total,
          monthlyTrades[26].total,
          monthlyTrades[25].total,
          monthlyTrades[24].total,
          monthlyTrades[23].total,
          monthlyTrades[22].total,
          monthlyTrades[21].total,
          monthlyTrades[20].total,
          monthlyTrades[19].total,
          monthlyTrades[18].total,
          monthlyTrades[17].total,
          monthlyTrades[16].total,
          monthlyTrades[15].total,
          monthlyTrades[14].total,
          monthlyTrades[13].total,
          monthlyTrades[12].total,
          monthlyTrades[11].total,
          monthlyTrades[10].total,
          monthlyTrades[9].total,
          monthlyTrades[8].total,
          monthlyTrades[7].total,
          monthlyTrades[6].total,
          monthlyTrades[5].total,
          monthlyTrades[4].total,
          monthlyTrades[3].total,
          monthlyTrades[2].total,
          monthlyTrades[1].total,
          monthlyTrades[0].total,
        ],
        borderColor: "rgb(142, 218, 250)",
        backgroundColor: "rgba(142, 218, 250, 0.6)",
      },
    ],
  };

  const weeklyChartData = {
    labels: [
      timeConverter(weeklyTrades[6].date),
      timeConverter(weeklyTrades[5].date),
      timeConverter(weeklyTrades[4].date),
      timeConverter(weeklyTrades[3].date),
      timeConverter(weeklyTrades[2].date),
      timeConverter(weeklyTrades[1].date),
      timeConverter(weeklyTrades[0].date),
    ],
    datasets: [
      {
        type: "line",
        label: "Sold",
        data: [
          weeklyTrades[6].sold,
          weeklyTrades[5].sold,
          weeklyTrades[4].sold,
          weeklyTrades[3].sold,
          weeklyTrades[2].sold,
          weeklyTrades[1].sold,
          weeklyTrades[0].sold,
        ],
        fill: false,
        borderColor: "rgb(235, 64, 104)",
        backgroundColor: "rgba(235, 64, 104)",
      },
      {
        type: "line",
        label: "Bought",
        data: [
          weeklyTrades[6].bought,
          weeklyTrades[5].bought,
          weeklyTrades[4].bought,
          weeklyTrades[3].bought,
          weeklyTrades[2].bought,
          weeklyTrades[1].bought,
          weeklyTrades[0].bought,
        ],
        fill: false,

        borderColor: "rgb(83, 228, 148)",
        backgroundColor: "rgba(83, 228, 148)",
      },
      {
        type: "bar",
        label: "Total Trades",
        data: [
          weeklyTrades[6].total,
          weeklyTrades[5].total,
          weeklyTrades[4].total,
          weeklyTrades[3].total,
          weeklyTrades[2].total,
          weeklyTrades[1].total,
          weeklyTrades[0].total,
        ],
        borderColor: "rgb(142, 218, 250)",
        backgroundColor: "rgba(142, 218, 250, 0.6)",
      },
    ],
  };

  const threeDayChartData = {
    labels: [
      timeConverter(threeDayTrades[2].date),
      timeConverter(threeDayTrades[1].date),
      timeConverter(threeDayTrades[0].date),
    ],
    datasets: [
      {
        type: "line",
        label: "Sold",
        data: [
          threeDayTrades[2].sold,
          threeDayTrades[1].sold,
          threeDayTrades[0].sold,
        ],
        fill: false,
        borderColor: "rgb(235, 64, 104)",
        backgroundColor: "rgba(235, 64, 104)",
      },
      {
        type: "line",
        label: "Bought",
        data: [
          threeDayTrades[2].bought,
          threeDayTrades[1].bought,
          threeDayTrades[0].bought,
        ],
        fill: false,

        borderColor: "rgb(83, 228, 148)",
        backgroundColor: "rgba(83, 228, 148)",
      },
      {
        type: "bar",
        label: "Total Trades",
        data: [
          threeDayTrades[2].total,
          threeDayTrades[1].total,
          threeDayTrades[0].total,
        ],
        borderColor: "rgb(142, 218, 250)",
        backgroundColor: "rgba(142, 218, 250, 0.6)",
      },
    ],
  };

  return (
    <Fade bottom>
      <div>
        <div className="mt-8 text-white max-w-wt 2xl:max-w-ft	 mx-auto mb-12 font-sans">
          <div className="flex flex-col lg:flex-row md:justify-between lg:items-center">
            <div className="md:mt-4">
              <div className="text-white flex flex-row items-center justify-between bg-[#3c3a5890] rounded-lg py-2 mx-auto w-92 md:w-97 lg:w-105 xl:150">
                <div className="flex items-center">
                  <img
                    src={coin.image_url}
                    alt="coin-icon"
                    className="w-12 object-contain ml-4"
                  />
                  <h1 className="coin-name"> {coin.symbol.toUpperCase()} </h1>
                  <p className="coin-symbol"> {coin.symbol.toUpperCase()} </p>
                </div>
                {favs?.includes(coin.symbol.toUpperCase()) ? (
                  <button
                    type="button"
                    className="inline-flex mr-2 items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none"
                    onClick={() => deleteFavourite(coin.symbol.toUpperCase())}
                  >
                    {deleting ? "Unfollowing..." : "Unfollow"}
                  </button>
                ) : auth ? (
                  <button
                    type="button"
                    className="inline-flex mr-2 items-center rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                    onClick={() => addFavourtie(coin.symbol.toUpperCase())}
                  >
                    {adding ? "Following..." : "Follow"}
                  </button>
                ) : (
                  <Link to="/login">
                    <button
                      type="button"
                      className="inline-flex mr-2 items-center rounded-md border border-transparent bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-[#B0EEFD]/80 focus:outline-none"
                    >
                      {adding ? "Following..." : "Follow"}
                    </button>
                  </Link>
                )}
              </div>
              <div className="text-white bg-[#3c3a5890] rounded-lg mt-2 w-92 md:w-97 mx-auto  lg:w-105 xl:150">
                <div className="w-97 p-4 mx-auto">
                  <h2 className="text-4xl font-black mb-4">
                    {" "}
                    {coin.symbol.toUpperCase()} STATISTICS{" "}
                  </h2>
                  <div className="">
                    <div className="flex flex-row justify-between md:mb-2">
                      <p> Total Trades</p>
                      <p className="font-bold">{totalStats.total}</p>
                    </div>
                    <hr className="mb-4 hidden md:block" />
                    <div className="flex flex-row justify-between  md:mb-2">
                      <p> Bought</p>
                      <p className="font-bold">{totalStats.bought}</p>
                    </div>
                    <hr className="mb-4 hidden md:block" />
                    <div className="flex flex-row justify-between  md:mb-2">
                      <p> Sold</p>
                      <p className="font-bold">{totalStats.sold}</p>
                    </div>
                    <hr className="mb-4 hidden md:block" />
                    <div className="flex flex-row justify-between  md:mb-2">
                      <p> Sold/Bought %</p>
                      <div>
                        <span className="text-[#41BE85] font-extrabold">
                          {(Math.round(
                            (totalStats.bought / totalStats.total) * 100
                          ) *
                            1000) /
                            1000}
                          %
                        </span>
                        /
                        <span className="text-[#EB4068] font-extrabold">
                          {(Math.round(
                            (totalStats.sold / totalStats.total) * 100
                          ) *
                            1000) /
                            1000}
                          %
                        </span>
                      </div>
                    </div>
                    <hr className="mb-4 hidden md:block" />

                    <div className="flex flex-row justify-between">
                      <p> Trading Volume</p>
                      <p className="font-bold">${millify(coin.volume)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto lg:ml-8  md:w-[550px] lg:w-[650px] md:mt-12 md:my-auto mt-4 sm:mt-0">
              <div className="mx-auto text-center mb-4">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className={
                      filter === 30
                        ? "items-center rounded-l-md border border-[#B0EEFD] bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-700  focus:z-10 "
                        : "items-center rounded-l-md border border-[#B0EEFD]  px-4 py-2 text-sm font-medium text-white  focus:z-10 "
                    }
                    onClick={() => setFilter(30)}
                  >
                    Monthly
                  </button>

                  <button
                    type="button"
                    onClick={() => setFilter(7)}
                    className={
                      filter === 7
                        ? "items-center  border border-[#B0EEFD] bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-700  focus:z-10 "
                        : "items-center  border border-[#B0EEFD]  px-4 py-2 text-sm font-medium text-white  focus:z-10 "
                    }
                  >
                    Weekly
                  </button>
                  <button
                    type="button"
                    onClick={() => setFilter(3)}
                    className={
                      filter === 3
                        ? "items-center rounded-r-md border border-[#B0EEFD] bg-[#B0EEFD] px-4 py-2 text-sm font-medium text-gray-700  focus:z-10 "
                        : "items-center rounded-r-md border border-[#B0EEFD]  px-4 py-2 text-sm font-medium text-white  focus:z-10 "
                    }
                  >
                    Three Day
                  </button>
                </span>
              </div>
              {filter === 30 && <BarChart chartData={monthlyChartData} />}
              {filter === 7 && <BarChart chartData={weeklyChartData} />}
              {filter === 3 && <BarChart chartData={threeDayChartData} />}
            </div>
          </div>

          <div>
            <div className="mt-8 ">
              <h1 className="font-black text-3xl md:text-4xl text-center lg:text-left text-white">
                {symbol.toUpperCase()} UNUSUAL TRADES ACTIVITY{" "}
              </h1>
              <p className="mb-4 md:mb-4 md:mt-0 text-md md:text-xl text-slate-300 text-center lg:text-left max-w-3xl">
                {" "}
                We are tracking 50,000$+ single trades from the Centrelised
                exchanges.
              </p>
            </div>

            <div
              className="grid lg:grid-cols-3  2xl:grid-cols-xl mt-8 gap-4"
              id="tradeCards"
            >
              {filteredTrades.map((trade) => (
                <TradesCard
                  symbol={trade.base_asset_trade}
                  pair={trade.pair}
                  image={trade.base_asset_icon}
                  price={trade.price_in_quote_asset}
                  quantity={trade.qty}
                  quoteAsset={trade.quote_asset_trade}
                  baseAsset={trade.base_asset_trade}
                  time={timeToGo(trade.trade_time)}
                  isBuyer={trade.is_buyer_maker}
                  cost={trade.trade_value}
                  exchange={trade.exchange_name}
                  exchangeLogo={trade.exchange_icon_url}
                  id={trade.id}
                  href={twitterHref}
                />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fade>
  );
};

export default CoinDetail;
