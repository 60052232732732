import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Fade from "react-reveal/Fade";

import Auth from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@+#$%]).{8,24}$/;

const CODE_REGEX = /[0-9]/g;

const activeButton =
  "mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#B0EEFD]";
const inActiveButton =
  "mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black/70 bg-[#B0EEFD] pointer-events-none opacity-70";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [forgotStage, setForgotStage] = useState(true);
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validCPwd, setValidCPwd] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const result = PWD_REGEX.test(password);
    setValidPwd(result);
  }, [password]);
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    const result = CODE_REGEX.test(code) && code.length === 6;
    setValidCode(result);
  }, [code]);

  useEffect(() => {
    const result = confirmPassword === password && confirmPassword.length > 0;
    setValidCPwd(result);
  }, [confirmPassword, password]);

  const forgotHandler = async () => {
    try {
      setProcessing(true);
      await Auth.forgotPassword(email);
      setForgotStage(false);
      setProcessing(false);
    } catch (error) {
      setErrorMessage(error.message);
      setProcessing(false);
    }
  };

  const updatePassword = async () => {
    setErrorMessage(null);
    try {
      setProcessing(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      navigate("/login");
      setProcessing(false);
    } catch (error) {
      setErrorMessage(error.message);
      setProcessing(false);
    }
  };

  return (
    <Fade bottom>
      <>
        <div className="my-auto min-h-[90vh] mt-12 font-sans">
          <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
            <h2 className="text-center text-3xl tracking-tight font-bold text-white">
              Reset your Password
            </h2>
          </div>
          {forgotStage ? (
            <div className="mt-8 sm:mx-auto mx-auto w-92 sm:w-full sm:max-w-md">
              <div className="bg-[#3C3A58] py-8 px-4 shadow sm:rounded-lg rounded-lg sm:px-10">
                {errorMessage && (
                  <p className="text-sm text-red-400 text-center mb-2">
                    {errorMessage}
                  </p>
                )}

                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        autoComplete="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validEmail && email !== "" && (
                      <p className="text-sm text-red-400 mt-2">
                        Add a valid Email
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      onClick={validEmail ? forgotHandler : null}
                      className={validEmail ? activeButton : inActiveButton}
                    >
                      {processing ? "Sending the Email..." : "Submit"}
                    </button>
                  </div>
                </div>
                <Link to="/login">
                  <p className="mt-4 text-[#6DCDF5] font-bold text-center cursor-pointer">
                    Back to Login
                  </p>
                </Link>
              </div>
            </div>
          ) : (
            <div className="mt-8 sm:mx-auto mx-auto w-92 sm:w-full sm:max-w-md">
              <div className="bg-[#3C3A58] py-8 px-4 shadow rounded-lg  sm:rounded-lg sm:px-10">
                {errorMessage && (
                  <p className="text-sm text-red-400 text-center mb-2">
                    {errorMessage}
                  </p>
                )}
                <p className="text-white text-center mb-4">
                  A code is sent to your email, please enter the code and your
                  new password to continue
                </p>
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Code
                    </label>
                    <div className="mt-1">
                      <input
                        id="code"
                        name="code"
                        type="text"
                        autoComplete="code"
                        value={code}
                        required
                        onChange={(e) => setCode(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validCode && code.length > 0 && (
                      <p className="text-sm text-red-400 mt-2">
                        The verification code should be 6 numbers only
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      New Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="passowrd"
                        type="password"
                        value={password}
                        autoComplete="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validPwd && password !== "" && (
                      <p className="text-sm text-red-400 mt-2">
                        Add a valid Password
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#6DCDF5]"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="passowrd"
                        value={confirmPassword}
                        type="password"
                        autoComplete="password"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="appearance-none block w-full bg-transparent px-3 py-2 border-0.5 border-[#6DCDF5] rounded-md shadow-sm sm:text-sm text-white"
                      />
                    </div>
                    {!validCPwd && confirmPassword !== "" && (
                      <p className="text-sm text-red-400 mt-2">
                        Passwords do not match
                      </p>
                    )}
                  </div>
                  <p className="text-xs text-slate-400 dark:text-slate-400 md:text-left">
                    {" "}
                    Password should be at least 8 characters long with at least
                    one lowercase letter, one uppercase letter, a number a
                    special character
                  </p>
                  <div>
                    <button
                      onClick={
                        validCode && validPwd && validCPwd
                          ? updatePassword
                          : null
                      }
                      className={
                        validCode && validPwd && validCPwd
                          ? activeButton
                          : inActiveButton
                      }
                    >
                      {processing ? "Changing your password..." : "Submit"}
                    </button>
                  </div>
                  <Link to="/login">
                    <p className="mt-4 text-[#6DCDF5] font-bold text-center cursor-pointer">
                      Back to Login
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    </Fade>
  );
}
