import { createContext, useState } from "react";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = ({ children }) => {
  const [isShowSubscription, setIsShowSubscription] = useState(false);
  return (
    <SubscriptionContext.Provider
      value={{ isShowSubscription, setIsShowSubscription }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
