import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./TrendingDetails.css";
import { TabTitle } from "../../services/GeneralFunctions";
import Fade from "react-reveal/Fade";
import Logo from "../../assets/smallLogo.png";
import TrendingBar from "../../components/TrendingBars/TrendingBars";
import Footer from "../../components/Footer/Footer";

const TrendingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [topCEXTrades, setTopCEXTrades] = useState([]);
  TabTitle("WhaleTracer - CEX Trending");

  useEffect(() => {
    Axios.get("https://api.whaletracer.com/cex-trades/topten/1").then(
      (response) => {
        setTopCEXTrades(response.data);
      }
    );
    setLoading(false);
  }, []);

  const twitterHref = (symbol, number) => {
    return `https://twitter.com/intent/tweet?text=📈 Whale Trending Alert (CEX) 
    %0A$${symbol} is trending on %23WhaleTracer with ${number} Whale Trades in the last 24 Hours.
    %0ACheck Out More: whaletracer.com
    %0A %23ETH %23WhaleTracer %23Trendingcrypto`;
  };

  if (loading || topCEXTrades.length < 1) {
    return <img src={Logo} className="loading-gif" alt="loading" />;
  }
  return (
    <Fade bottom>
      <div className="trending-home">
        <div className="trending-details-header">
          <div className="trending-details-filter">
            <img src={Logo} alt="logo" className="trending-item-image" />{" "}
            <div className="trending-item-heading">
              <h1>CEX TRENDING ON WhaleTracer</h1>
              <span className="trending-item-subtext">
                Check out the Most Traded Coins above $50,000 over popular
                Crypto Exchanges in last 24 hours.
              </span>
            </div>
          </div>
        </div>
        <div className="trending-item-container">
          <div className="trending-item-body">
            {topCEXTrades.slice(0, 10).map((trade) => (
              <TrendingBar
                image={trade.image_url}
                symbol={trade.base_asset_trade}
                marketCap={trade.market_cap}
                volume={trade.volume}
                detailedView={true}
                price={trade.current_price}
                change={trade.price_change_24h}
                traded={trade.value_occurrence}
                href={twitterHref}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </Fade>
  );
};

export default TrendingDetails;
