import React from "react";
import "./DexTradesView.css";

import Twitter from "../../assets/twitter.png";
import { Link } from "react-router-dom";

const DexTableView = (props) => {
  const chainIcon = (val) => {
    if (val === "uniswap_v3" || val === "uniswap") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/UniswapIcon.png";
    }
    if (val === "sushiswap") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/SushiSwap-Icon.png";
    }
    if (val === "ddex") {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/DDEX-Icon.png";
    } else {
      return "https://whale-tracer-assets.s3.eu-west-2.amazonaws.com/DEX+Logo+Icons/WT-DEX-Icon.png";
    }
  };

  const categoryStyle = (type) => {
    if (type === "Whale") {
      return { color: "#41BE85", fontWeight: "900" };
    }
    if (type === "medium") {
      return { color: "#B0EEFD", fontWeight: "900" };
    }
    if (type === "noob") {
      return { color: "white", fontWeight: "900" };
    }
    if (type === "casual") {
      return { color: "#B0EEFD", fontWeight: "900" };
    }
    if (type === "bot") {
      return { color: "#EB4068", fontWeight: "900" };
    }
    if (type === null) {
      return { color: "white", fontWeight: "900" };
    }
  };

  const categoryName = (cat) => {
    if (cat === "Whale") {
      return "Whale";
    }
    if (cat === "medium") {
      return "Shark";
    }
    if (cat === "noob") {
      return "Shrimp";
    }
    if (cat === "casual") {
      return "Dolphin";
    }
    if (cat === "bot") {
      return "Bot";
    }
    if (cat === null) {
      return "Unspecified";
    }
  };

  const tradeDetail = `/dex-activity/dex-trade/${props.trade_id}/${props.wallet}`;

  return (
    <div>
      {props.view === "small" ? (
        <div className="flex items-center bg-[#292644] p-2 rounded-md border-4 mb-2 border-[#403e66] shadow-2xl mx-auto">
          <div className="flex w-97 mx-auto items-center">
            <div className="flex flex-1">
              <div className="flex items-center">
                <img
                  src={chainIcon(props.chain)}
                  alt="btc"
                  className="base-asset-img"
                />
                <p className="base-asset-name">{props.chain}</p>
              </div>
            </div>

            <div className="lg:flex flex-1 flex-col hidden">
              <span className="item-heading-regular-dex">Bought</span>
              <div className="item-content">
                <span className="">
                  {props.token_out} {props.symbol_out}
                </span>
              </div>
            </div>
            <div className="lg:flex flex-1 flex-col hidden">
              <span className="item-heading-regular">Sold</span>
              <div className="item-content">
                <span className="">
                  {props.token_in} {props.symbol_in}
                </span>
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <span className="item-heading-regular">Txn Value</span>
              <div className="item-content">
                <span className="">${props.value}</span>
              </div>
            </div>

            <div className="lg:flex flex-1 flex-col hidden">
              <span className="item-heading-regular">Time</span>
              <div className="item-content">
                <span>{props.timeToGo(props.time)}</span>
              </div>
            </div>

            {props.detailedView ? (
              <div>
                <a
                  href={props.href(
                    props.value,
                    props.token_out,
                    props.token_in,
                    props.chain,
                    props.symbol_out,
                    props.symbol_in
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Twitter}
                    alt="twitter"
                    className="w-8 object-contain"
                  />
                </a>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex items-center bg-[#292644] p-2 rounded-md border-4 mb-2 border-[#403e66] shadow-2xl mx-auto">
          <div className="flex w-97 mx-auto items-center">
            <div className="flex flex-1">
              <div className="flex items-center">
                <img
                  src={chainIcon(props.chain)}
                  alt="btc"
                  className="base-asset-img"
                />
                <p className="base-asset-name">{props.chain}</p>
              </div>
            </div>

            <div className="md:flex flex-1 flex-col hidden">
              <span className="item-heading-regular-dex">Bought</span>
              <div className="item-content">
                <span className="">
                  {props.token_out} {props.symbol_out}
                </span>
              </div>
            </div>
            <div className="md:flex flex-1 flex-col hidden">
              <span className="item-heading-regular-dex">Sold</span>
              <div className="item-content">
                <span className="">
                  {props.token_in} {props.symbol_in}
                </span>
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <span className="item-heading-regular">Txn Value</span>
              <div className="item-content">
                <span className="">${props.value}</span>
              </div>
            </div>

            <div className="md:flex flex-1 flex-col hidden">
              <span className="item-heading-regular">Time</span>
              <div className="item-content">
                <span>{props.timeToGo(props.time)}</span>
              </div>
            </div>

            {props.detailedView ? (
              <div>
                <a
                  href={props.href(
                    props.value,
                    props.token_out,
                    props.token_in,
                    props.chain,
                    props.symbol_out,
                    props.symbol_in
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Twitter}
                    alt="twitter"
                    className="w-8 object-contain"
                  />
                </a>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default DexTableView;
