import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./TrendingDetails.css";
import { TabTitle, timeToGoSec } from "../../services/GeneralFunctions";
import Fade from "react-reveal/Fade";
import Logo from "../../assets/smallLogo.png";
import DexTableView from "../../components/DexTradesTable/DexTradesView";
import millify from "millify";
import Footer from "../../components/Footer/Footer";

const TrendingDex = () => {
  const [loading, setLoading] = useState(true);
  const [topDEXTrades, setTopDEXTrades] = useState([]);
  TabTitle("WhaleTracer - DEX Trending");

  useEffect(() => {
    Axios.get("https://api.whaletracer.com/cex-trades/top-ten-dex").then(
      (response) => {
        setTopDEXTrades(response.data);
      }
    );
    setLoading(false);
  }, []);
  const twitterHref = (txnValue, tokenOut, tokenIn, chain, outSym, inSym) => {
    return `https://twitter.com/intent/tweet?text=🐳 Whale Trade Alert (DEX) 
    %0ATxn Value $${millify(txnValue)} swaped on ${chain}
    %0AToken Out: $${tokenOut} $${outSym}
    %0AToken In: $${tokenIn} $${inSym}
    %0ACheck Out More: whaletracer.com
    %0A %23${outSym} %23${inSym}  %23${chain.toUpperCase()} %23WHALETRACER`;
  };

  if (loading || topDEXTrades.length < 1) {
    return <img src={Logo} className="loading-gif" alt="loading" />;
  }

  return (
    <Fade bottom>
      <div className="trending-home">
        <div className="trending-details-header">
          <div className="trending-details-filter">
            <img src={Logo} alt="logo" className="trending-item-image" />
            <div className="trending-item-heading">
              <h1>DEX TRENDING ON WhaleTracer</h1>
              <span className="trending-item-subtext">
                Check out the Most Traded Tokens above $100,000 & $50,000 over
                popular Decentralised Exchanges in last 24 hours.
              </span>
            </div>
          </div>
        </div>
        <div className="trending-item-container">
          <div className="trending-item-body">
            {topDEXTrades.map((trade) => (
              <DexTableView
                chain={trade.chain_name}
                time={trade.trade_time}
                token_out={trade.token_out}
                token_in={trade.token_in}
                trade_id={trade.id}
                symbol_out={trade.symbol_out}
                symbol_in={trade.symbol_in}
                value={trade.txn_value}
                hash={trade.txn_hash}
                wallet={trade.wallet}
                category={trade.category}
                timeToGo={timeToGoSec}
                href={twitterHref}
                detailedView={true}
                view="large"
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </Fade>
  );
};

export default TrendingDex;
